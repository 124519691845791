import React, { useEffect, useState } from "react";
import {
  ResourceList,
  Avatar,
  Text,
  Box,
  Spinner,
  InlineStack,
  BlockStack,
  Loading,
  Button,
} from "@shopify/polaris";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useTranslation } from "react-i18next";
import CardWrapper from "../CardWrapper";
import { PageTypes } from "@/types/commonTypes";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import TranslateLink from "@/common/TranslateLink";
import { removeLocalStorage } from "@/utils/localStorageUtils";
import { setSkipActivationCheck } from "@/auth/authSlice";
import { useDispatch } from "react-redux";
import { CheckCircleIcon } from "@shopify/polaris-icons";

interface Organization {
  company: {
    id: string;
    name: string;
    avatar: {
      initials: string;
      colorSchema: {
        background: string;
        text: string;
      };
    };
    website: string;
  };
  roleId: number;
  role: string;
  domains: string[];
}

const JoinOrganizationsCard: React.FC = () => {
  const { t } = useTranslation();
  const { GET, POST } = useApiRequest();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const [loadingHccId, setLoadingHccId] = useState<string | null>(null);

  const {
    data: organizations = [],
    isLoading,
    isFetched,
    isFetching,
  } = useQuery<Organization[]>({
    queryKey: ["join-organizations"],
    queryFn: () => GET("/api/user/me/teams/join-list"),
  });

  useEffect(() => {
    if (isFetched && organizations.length === 0) {
      handleSkip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, organizations]);

  const joinOrganizationMutation = useMutation({
    mutationFn: (hccId: string) =>
      POST<{ message: string }>("/api/user/me/teams/join", { hccId }),
    onSuccess: () => {
      setLoadingHccId(null);
      queryClient.invalidateQueries({ queryKey: ["join-organizations"] });
    },
    onError: () => {
      setLoadingHccId(null);
    },
  });

  const handleJoin = (hccId: string) => {
    setLoadingHccId(hccId);
    joinOrganizationMutation.mutate(hccId);
  };

  const handleSkip = (isDone = false) => {
    removeLocalStorage("__REDIRECT_PATH");
    if (!isDone) {
      dispatch(setSkipActivationCheck("autoJoin"));
    }
    setTimeout(() => {
      queryClient.refetchQueries({ queryKey: ["activation-check"] });
      navigate("/");
    }, 1);
  };

  return (
    <CardWrapper type={PageTypes.UNKNOW} wrapperClass="white-card-wrapper">
      {joinOrganizationMutation.isPending || isFetching ? <Loading /> : ""}
      {isLoading ? (
        <Box minHeight="300" paddingBlockEnd={"1000"}>
          <InlineStack align="center" blockAlign="center">
            <Spinner />
          </InlineStack>
        </Box>
      ) : organizations.length > 0 ? (
        <BlockStack gap={"400"}>
          <Text variant="heading2xl" as="h1">
            {t("joinOrganizationsCard.title")}
          </Text>
          <TranslateLink
            text={t("joinOrganizationsCard.description")}
          ></TranslateLink>
          <div className="resource-list-container">
            <Box
              borderColor="border"
              borderWidth="025"
              borderStyle="solid"
              borderRadius="200"
            >
              <ResourceList
                resourceName={{
                  singular: "organization",
                  plural: "organizations",
                }}
                items={organizations}
                renderItem={(organization) => {
                  const {
                    company: {
                      id: hccId,
                      name: organizationName,
                      avatar,
                      website,
                    },
                  } = organization;

                  const shortcutActions = [
                    {
                      content: t("common.join"),
                      onAction: () => handleJoin(hccId),
                      loading: loadingHccId === hccId,
                      icon: CheckCircleIcon,
                    },
                  ];

                  return (
                    <ResourceList.Item
                      verticalAlignment="center"
                      id={hccId}
                      media={
                        <Box>
                          <Avatar
                            size="lg"
                            name={organizationName}
                            initials={avatar.initials}
                          />
                        </Box>
                      }
                      onClick={() => {}}
                      shortcutActions={shortcutActions}
                      accessibilityLabel={`View details for ${organizationName}`}
                      persistActions
                    >
                      <Text variant="bodyMd" fontWeight="bold" as="h3">
                        {organizationName}
                      </Text>
                      <Text as="p" variant="bodySm" tone="subdued">
                        {website}
                      </Text>
                    </ResourceList.Item>
                  );
                }}
              />
            </Box>
          </div>

          <BlockStack gap={"200"}>
            <Button fullWidth onClick={() => handleSkip()} size="large">
              {t("common.skipForNow")}
            </Button>
            <Text as="p" tone="subdued">
              {t("joinOrganizationsCard.footerNote")}
            </Text>
          </BlockStack>
        </BlockStack>
      ) : (
        ""
      )}
    </CardWrapper>
  );
};

export default JoinOrganizationsCard;
