import CardWrapper from "./CardWrapper";
import Cookies from "js-cookie";
import { PageTypes } from "@/types/commonTypes";
import { ReactNode, useLayoutEffect } from "react";
import { ROOTS, ROUTES } from "@/utils/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import "../styles.scss";
import { getLocalStorage } from "@/utils/localStorageUtils";

function AuthCardWrapper({
  children,
  footer,
  footerClass = "",
}: {
  children: ReactNode;
  footer?: ReactNode;
  footerClass?: string;
}) {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * This is to navigate all /v routes to home page if token is already there
   */
  useLayoutEffect(() => {
    const token = Cookies.get("access_token");
    if (
      token &&
      location.pathname.startsWith(ROOTS.AUTH) &&
      !location.pathname.includes(ROUTES.OTT)
    ) {
      const clinicId = getLocalStorage("__LAST_VISITED_C_ID") as string;
      if (clinicId) {
        navigate(`${ROOTS.CLINIC}/${clinicId}`);
      } else {
        navigate(`${ROOTS.CLINIC}`);
      }
    }
  }, [navigate, location]);

  return (
    <CardWrapper
      type={PageTypes.AUTH}
      footer={footer}
      footerClass={footerClass}
      wrapperClass="auth-card-wrapper"
    >
      {children}
    </CardWrapper>
  );
}

export default AuthCardWrapper;
