import AuthCardWrapper from "./AuthCardWrapper";
import DividerWithText from "../../common/DividerWithText";
import GoogleSignUp from "./RegisterWithGoogle";
import React, { useState } from "react";
import useApiRequest from "../../hooks/useApiRequest";
import useOTT from "../../hooks/useOTT";
import { EmailIcon, LogoGoogleIcon } from "@shopify/polaris-icons";
import { ErrorResponse } from "../../types/commonTypes";
import { ROUTES } from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { usePasswordVerification } from "@/common/PasswordVerificationContext";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../utils";
import { VerifyResponse } from "../types";
import {
  setEmailFor2FA,
  setEmailForForgotPassword,
  setPasswordFor2FA,
  setTobeVerifiedEmail,
} from "../authSlice";

import {
  Button,
  Form,
  FormLayout,
  TextField,
  Box,
  Text,
  BlockStack,
  Link,
  InlineStack,
} from "@shopify/polaris";
import { removeLocalStorage } from "@/utils/localStorageUtils";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const { handleOTT } = useOTT();
  const { POST } = useApiRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [error, setError] = useState<string>("");
  const [step, setStep] = useState<1 | 2>(1);
  const [showGoogleSignUp, setShowGoogleSignUp] = useState<boolean>(false);
  const { setVerifiedTimeOnLogin } = usePasswordVerification();

  const handleEmailChange = (value: string) => {
    setEmail(value);
    if (emailError) setEmailError(null);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    if (passwordError) setPasswordError(null);
    if (error) setError("");
  };

  const loginMutation = useMutation({
    mutationFn: async () => {
      return POST<VerifyResponse>("/api/auth/ott/login", { email, password });
    },
    onSuccess: async (data: VerifyResponse) => {
      setVerifiedTimeOnLogin();
      removeLocalStorage("selectedLanguage");

      if ("ott" in data && data.ott) {
        await handleOTT(data.ott);
      } else if ("next" in data && data.next === "validate_2fa") {
        dispatch(setEmailFor2FA(email));
        dispatch(setPasswordFor2FA(password));
        navigate(ROUTES.TWO_FACTOR);
      } else if ("next" in data && data.next === "validate_email") {
        dispatch(setTobeVerifiedEmail(email));
        dispatch(setEmailFor2FA(email));
        dispatch(setPasswordFor2FA(password));
        navigate(ROUTES.REGISTER_CHECK_EMAIL);
      }
    },
    onError: (error: ErrorResponse) => {
      setError(
        error?.response?.data?.error?.display ||
          t("auth.registration.email.error.emailIsNotCorrect")
      );
    },
  });

  const handleSubmitEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError(t("auth.registration.email.error.emailIsNotCorrect"));
      return;
    }
    setStep(2);
  };

  const handleSubmitPassword = async () => {
    if (!password) {
      setPasswordError(t("auth.registration.email.error.passNoMatch"));
      return;
    }
    loginMutation.mutate();
  };

  const handleForgotPassword = () => {
    if (!validateEmail(email)) {
      setEmailError(t("auth.registration.email.error.emailIsNotCorrect"));
      return;
    }
    dispatch(setEmailForForgotPassword(email));
    navigate(ROUTES.PASSWORD_RESET);
  };

  if (showGoogleSignUp) {
    return (
      <GoogleSignUp
        onSelect={() => {
          setShowGoogleSignUp(false);
        }}
      />
    );
  }

  return (
    <AuthCardWrapper>
      <Box width="800">
        <BlockStack gap="300" inlineAlign="start">
          <Text variant="heading2xl" as="h1">
            {t("auth.login.loginTitle")}
          </Text>
          <Text as="p" variant="bodyMd" tone="subdued">
            {t("auth.login.description", { email })}
          </Text>
        </BlockStack>
        <Box paddingBlock="400" minWidth="800">
          <Form
            onSubmit={step === 1 ? handleSubmitEmail : handleSubmitPassword}
          >
            {step === 1 && (
              <FormLayout>
                <TextField
                  autoFocus
                  label={t("auth.login.email")}
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="email"
                  error={emailError || undefined}
                />
                <Button
                  disabled={!validateEmail(email)}
                  size="large"
                  onClick={handleSubmitEmail}
                  fullWidth
                  icon={EmailIcon}
                  variant="primary"
                >
                  {t("auth.login.continueWithEmailCTA")}
                </Button>
                <InlineStack align="center" blockAlign="center">
                  <DividerWithText text={t("auth.login.or")} />
                </InlineStack>

                <Button
                  size="large"
                  onClick={() => setShowGoogleSignUp(true)}
                  fullWidth
                  icon={LogoGoogleIcon}
                >
                  {t("auth.login.continueWithGoogleCTA")}
                </Button>
              </FormLayout>
            )}
            {step === 2 && (
              <FormLayout>
                <TextField
                  label={t("auth.login.email")}
                  type="email"
                  value={email}
                  disabled
                  autoComplete="email"
                />
                <Link onClick={() => setStep(1)}>
                  {t("auth.passwordResetRequest.changeEmail")}
                </Link>
                <TextField
                  label={t("auth.login.password")}
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                  error={passwordError || undefined}
                  autoFocus
                />
                <Button
                  size="large"
                  onClick={handleSubmitPassword}
                  fullWidth
                  loading={loginMutation.isPending}
                  variant="primary"
                  disabled={!password}
                >
                  {t("auth.login.loginCTA")}
                </Button>
                <Link onClick={handleForgotPassword}>
                  {t("auth.login.forgotPasswordCTA")}
                </Link>
              </FormLayout>
            )}
          </Form>
        </Box>
        <Box paddingBlock="600">
          <InlineStack gap="100">
            <Text as="p" tone="subdued">
              {t("auth.login.newToMedentic")}
            </Text>
            <Link url={ROUTES.REGISTER} removeUnderline>
              {t("auth.login.register")}
            </Link>
          </InlineStack>
        </Box>
      </Box>
    </AuthCardWrapper>
  );
};

export default Login;
