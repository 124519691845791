import PasswordVerificationModal from "./PasswordVerificationModal";
import React, { ReactNode, useEffect, useState } from "react";
import { PasswordVerificationContext } from "./PasswordVerificationContext";

export const PasswordVerificationProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isVerified, setIsVerified] = useState(false);
  const [lastVerifiedTime, setLastVerifiedTime] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [resolveCallback, setResolveCallback] =
    useState<(result: boolean) => void>();

  // Function to check if the password verification is still valid
  const checkVerificationValidity = () => {
    if (lastVerifiedTime && Date.now() - lastVerifiedTime > 5 * 60 * 1000) {
      setIsVerified(false); // Reset verification after 1 minute
    }
  };

  useEffect(() => {
    // Set up interval to check every second if the verification has expired
    const intervalId = setInterval(checkVerificationValidity, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVerifiedTime]);

  const verifyPassword = (): Promise<boolean> => {
    return new Promise((resolve) => {
      if (isVerified) {
        resolve(true);
      } else {
        setResolveCallback(() => resolve);
        setShowModal(true);
      }
    });
  };

  const handleModalClose = (result: boolean) => {
    if (result) {
      setIsVerified(true);
      setLastVerifiedTime(Date.now());
    }
    setShowModal(false);
    if (resolveCallback) {
      resolveCallback(result);
    }
  };

  const setVerifiedTimeOnLogin = () => {
    setIsVerified(true);
    setLastVerifiedTime(Date.now());
  };

  return (
    <PasswordVerificationContext.Provider
      value={{
        isPasswordVerified: isVerified,
        verifyPassword,
        setVerifiedTimeOnLogin,
      }}
    >
      {children}
      {showModal && <PasswordVerificationModal onClose={handleModalClose} />}
    </PasswordVerificationContext.Provider>
  );
};
