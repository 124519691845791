/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Checkbox,
  Select,
  DropZone,
  BlockStack,
  Text,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { MultiComboOption, MultiSelectCombobox } from "../MultiSelectCombobox";
import DatePickerField from "../DatePickerField";
import TranslateLink from "../TranslateLink";
import { PhoneNumberField } from "../PhoneNumberField/PhoneNumberField";
import { CountryProvider } from "@/hooks/useCountries";

/* same props interface */
interface ComboOption {
  label: string;
  value: string;
}

interface PropertyTypeRendererProps {
  id:
    | "textInput"
    | "textArea"
    | "number"
    | "checkbox"
    | "phoneNumber"
    | "email"
    | "date"
    | "combobox"
    | "combeBoxMultiple"
    | "dropZone";
  label?: string;
  value?: string | number | boolean | string[];
  onChange: (newValue: any) => void;
  disabled?: boolean;
  helpText?: string;
  placeholder?: string;
  comboOptions?: ComboOption[];
  error?: string;
  isRequired?: boolean;
}

const _PropertyTypeRenderer: React.FC<PropertyTypeRendererProps> = ({
  isRequired,
  id,
  label,
  value,
  onChange,
  disabled = false,
  helpText,
  placeholder,
  comboOptions = [],
  error,
}) => {
  const { t } = useTranslation();

  // Add local state for text fields
  const [localValue, setLocalValue] = useState(
    value == null ? "" : String(value)
  );

  // Update local value when prop value changes
  useEffect(() => {
    setLocalValue(value == null ? "" : String(value));
  }, [value]);

  // Common label/placeholder
  const stringValue = value == null ? "" : String(value);
  const computedLabel = label ?? t("PropertyTypeRenderer.label", "Label");

  const computedPlaceholder =
    placeholder ?? t("PropertyTypeRenderer.placeholder", "Enter value");

  // DropZone local state
  const [files, setFiles] = useState<File[]>([]);
  const handleDropZoneDrop = useCallback(
    (_dropFiles: File[], acceptedFiles: File[]) => {
      setFiles((prev) => [...prev, ...acceptedFiles]);
      onChange(acceptedFiles);
    },
    [onChange]
  );
  useEffect(() => {
    if (id !== "dropZone" && files.length > 0) {
      setFiles([]);
    }
  }, [id, files]);

  // Checkbox
  const boolValue = typeof value === "boolean" ? value : false;

  // Multi-Select
  const [multiSelected, setMultiSelected] = useState<string[]>([]);
  useEffect(() => {
    if (id !== "combeBoxMultiple") return;
    if (Array.isArray(value)) {
      setMultiSelected(value);
    } else if (typeof value === "string" && value.length > 0) {
      const splitted = value.split(",").map((v) => v.trim());
      setMultiSelected(splitted);
    } else {
      setMultiSelected([]);
    }
  }, [id, value]);

  const handleMultiSelectChange = useCallback(
    (newSelected: string[]) => {
      setMultiSelected(newSelected);
      onChange(newSelected);
    },
    [onChange]
  );

  // Phone number example: parse string as JSON object
  if (id === "phoneNumber") {
    return (
      <CountryProvider>
        <PhoneNumberField
          value={stringValue}
          onChange={(newE164) => onChange(newE164)}
          phoneLabel={computedLabel}
          phoneHelpText={helpText}
          phoneError={error}
          disabled={disabled}
          isRequired={isRequired}
        />
      </CountryProvider>
    );
  }

  // Switch for other field types
  switch (id) {
    case "textInput":
    case "textArea":
    case "email":
    case "number":
      return (
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(localValue);
            }
          }}
        >
          <TextField
            label={computedLabel}
            value={localValue}
            onChange={(newValue) => setLocalValue(newValue)}
            onBlur={() => onChange(localValue)}
            type={id === "number" ? "number" : "text"}
            multiline={id === "textArea" ? 4 : undefined}
            disabled={disabled}
            helpText={helpText}
            placeholder={computedPlaceholder}
            autoComplete="off"
            error={error}
            requiredIndicator={isRequired}
          />
        </div>
      );

    case "checkbox":
      return (
        <Checkbox
          label={computedLabel}
          checked={boolValue}
          onChange={onChange}
          disabled={disabled}
          helpText={error || helpText}
        />
      );

    case "date":
      return (
        <DatePickerField
          value={stringValue}
          onChange={onChange}
          label={
            computedLabel || t("PropertyTypeRenderer.dateLabel", "Select date")
          }
          disabled={disabled}
          isRequired={isRequired}
        />
      );

    case "combobox":
      return (
        <Select
          label={computedLabel}
          options={comboOptions}
          onChange={onChange}
          value={stringValue}
          disabled={disabled}
          helpText={helpText}
          error={error}
        />
      );

    case "combeBoxMultiple":
      return (
        <BlockStack gap="200">
          <Text variant="headingSm" as="h4">
            {computedLabel}
          </Text>
          <MultiSelectCombobox
            options={comboOptions as MultiComboOption[]}
            selected={multiSelected}
            onChange={handleMultiSelectChange}
            label={computedLabel}
            placeholder={computedPlaceholder}
          />
          {error && (
            <Text tone="critical" as="p">
              {error}
            </Text>
          )}
        </BlockStack>
      );

    case "dropZone":
      return (
        <BlockStack gap="200">
          <Text as="h3" variant="bodyMd">
            {computedLabel}
          </Text>
          <DropZone onDrop={handleDropZoneDrop} disabled={disabled}>
            {files.length > 0 ? (
              <DropZone.FileUpload
                actionTitle={t("PropertyTypeRenderer.filesUploaded", {
                  defaultValue: "{{count}} file(s) uploaded",
                  count: files.length,
                })}
              />
            ) : (
              <DropZone.FileUpload
                actionTitle={
                  computedPlaceholder ||
                  t("PropertyTypeRenderer.uploadFiles", "Upload files")
                }
              />
            )}
          </DropZone>
          {error && (
            <Text tone="critical" as="p">
              {error}
            </Text>
          )}
          {helpText && <TranslateLink text={helpText} />}
        </BlockStack>
      );

    default:
      return (
        <div
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onChange(localValue);
            }
          }}
        >
          <TextField
            autoComplete="off"
            label={
              computedLabel ||
              t("PropertyTypeRenderer.unknownTypeLabel", "Unknown Type")
            }
            value={localValue}
            onChange={(newValue) => setLocalValue(newValue)}
            onBlur={() => onChange(localValue)}
            disabled={disabled}
            helpText={helpText}
            error={error}
          />
        </div>
      );
  }
};

/**
 * A shallow comparison of relevant props
 * to avoid re-renders if not needed.
 */
function propsAreEqual(
  prevProps: PropertyTypeRendererProps,
  nextProps: PropertyTypeRendererProps
): boolean {
  // Check a few key props that might change
  return (
    prevProps.value === nextProps.value &&
    prevProps.error === nextProps.error &&
    prevProps.disabled === nextProps.disabled &&
    prevProps.label === nextProps.label &&
    prevProps.helpText === nextProps.helpText &&
    prevProps.placeholder === nextProps.placeholder &&
    prevProps.id === nextProps.id &&
    // if comboOptions never changes in your scenario, you can compare .length
    prevProps.comboOptions === nextProps.comboOptions
  );
}

/**
 * Export a memoized version
 */
export const PropertyTypeRenderer = React.memo(
  _PropertyTypeRenderer,
  propsAreEqual
);
