import { clearTokenData } from "@/auth/utils";
import { ErrorResponse } from "@/types/commonTypes";
import { showToast } from "@/reducers/toastSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  GETRequest,
  POSTRequest,
  PUTRequest,
  PATCHRequest,
  DELETERequest,
  API_URL, // Import DELETERequest
} from "@/api/baseAPI";

const doNotLogoutEndpoints = [
  "/api/user/me/password",
  "/api/user/me/password/verify",
  "/api/auth/ott/login",
];

interface UseApiRequest {
  GET: <T>(endpoint: string, addUTMParams?: boolean) => Promise<T>;
  POST: <T>(
    endpoint: string,
    data: object,
    addUTMParams?: boolean,
    logoutOnFail?: boolean
  ) => Promise<T>;
  PUT: <T>(
    endpoint: string,
    data: object,
    addUTMParams?: boolean
  ) => Promise<T>;
  PATCH: <T>(
    endpoint: string,
    data: object,
    addUTMParams?: boolean
  ) => Promise<T>;
  DELETE: <T>(
    endpoint: string,
    addUTMParams?: boolean,
    data?: object
  ) => Promise<T>;
}

const useApiRequest = (): UseApiRequest => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleError = (error: ErrorResponse, logoutOnFail: boolean = true) => {
    if (error.response) {
      const { status, data, config } = error.response;
      const message = data?.error?.display || "Something went wrong!";
      dispatch(
        showToast({
          message: message,
          type: "error",
        })
      );

      const doNotLogout =
        doNotLogoutEndpoints.find(
          (endPoint: string) => `${API_URL}${endPoint}` === config.url
        ) !== undefined;

      if (status === 401 && logoutOnFail && !doNotLogout) {
        clearTokenData(false);
      }
    } else {
      dispatch(
        showToast({
          message: t("common.networkError"),
          type: "error",
        })
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccess = (response: any) => {
    if (response?.message) {
      const message = response?.message;
      dispatch(
        showToast({
          message: message,
          type: "success",
        })
      );
    }
  };

  const GET = async <T>(endpoint: string, addUTMParams = false): Promise<T> => {
    try {
      return await GETRequest(endpoint, addUTMParams);
    } catch (response) {
      handleError(response as ErrorResponse);
      throw response;
    }
  };

  const POST = async <T>(
    endpoint: string,
    data: object | FormData,
    addUTMParams = false
  ): Promise<T> => {
    try {
      const response = await POSTRequest<T>(endpoint, data, addUTMParams);
      handleSuccess(response);
      return response;
    } catch (response) {
      handleError(response as ErrorResponse);
      throw response;
    }
  };

  const PUT = async <T>(
    endpoint: string,
    data: object,
    addUTMParams = false
  ): Promise<T> => {
    try {
      const response = await PUTRequest<T>(endpoint, data, addUTMParams);
      handleSuccess(response);
      return response;
    } catch (error) {
      handleError(error as ErrorResponse);
      throw error;
    }
  };

  const PATCH = async <T>(
    endpoint: string,
    data: object,
    addUTMParams = false
  ): Promise<T> => {
    try {
      const response = await PATCHRequest<T>(endpoint, data, addUTMParams);
      handleSuccess(response);
      return response;
    } catch (error) {
      handleError(error as ErrorResponse);
      throw error;
    }
  };

  const DELETE = async <T>(
    endpoint: string,
    addUTMParams = false,
    data: object = {}
  ): Promise<T> => {
    try {
      const response = await DELETERequest<T>(endpoint, addUTMParams, data);
      handleSuccess(response);
      return response;
    } catch (error) {
      handleError(error as ErrorResponse);
      throw error;
    }
  };

  return { GET, POST, PUT, PATCH, DELETE };
};

export default useApiRequest;
