import { Page } from "@shopify/polaris";
import SettingsPageWrapper from "./SettingsPageWrapper";
import NearingDevelopmentBanner from "@/common/NearingDevelopmentBanner";

export default function SettingsSubsriptionPlan() {
  return (
    <SettingsPageWrapper>
      <Page>
        <NearingDevelopmentBanner />
      </Page>
    </SettingsPageWrapper>
  );
}
