import { Box, InlineStack, SkeletonDisplayText } from "@shopify/polaris";
import "./skeleton.scss";

function TabsSkeleton({ actions = true }) {
  return (
    <div className="tabs-skeleton">
      <InlineStack align="space-between" wrap={false}>
        <Box padding={"300"}>
          <InlineStack align="space-between" gap="200" wrap={false}>
            <Box minWidth="40px">
              <SkeletonDisplayText size="small" />
            </Box>{" "}
            <Box minWidth="70px">
              <SkeletonDisplayText size="small" />
            </Box>{" "}
            <Box minWidth="70px">
              <SkeletonDisplayText size="small" />
            </Box>
            <Box width="70px">
              <SkeletonDisplayText size="small" />
            </Box>
          </InlineStack>
        </Box>
        <div className="vertical-divider"></div>

        {actions && (
          <InlineStack gap="200" wrap={false}>
            <Box padding={"300"}>
              <InlineStack align="space-between" gap="200" wrap={false}>
                <Box width="50px">
                  <SkeletonDisplayText size="small" />
                </Box>{" "}
                <Box width="30px">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </Box>
          </InlineStack>
        )}
      </InlineStack>
    </div>
  );
}

export default TabsSkeleton;
