import { PWAInstall } from './PWAInstall';
import styled from 'styled-components';

const PWAWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

export function PWAInstallWrapper() {
  return (
    <PWAWrapper>
      <PWAInstall />
    </PWAWrapper>
  );
} 