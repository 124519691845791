import AuthCardWrapper from "./AuthCardWrapper";
import React, { useEffect, useState } from "react";
import TranslateLink from "../../common/TranslateLink";
import useApiRequest from "../../hooks/useApiRequest";
import useOTT from "../../hooks/useOTT";
import { ErrorResponse } from "../../types/commonTypes";
import { RootState } from "../../store";
import { ROUTES } from "../../utils/Constants";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VerifyResponse } from "../types";
import "../styles.scss";
import {
  BlockStack,
  Box,
  InlineStack,
  Link,
  Text,
  TextField,
  Spinner,
  InlineError,
  Loading,
} from "@shopify/polaris";

const CheckAuthApp: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { POST } = useApiRequest();
  const { handleOTT } = useOTT();
  const [authCode, setAuthCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);

  const email = useSelector(
    (state: RootState) => state.auth.email_tobe_verified
  );

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.REGISTER);
    }
  }, [email, navigate]);

  const verifyCode = async (code: string) => {
    return POST<VerifyResponse>("/api/auth/register/email/verify", {
      code,
      email,
    });
  };

  const verifyMutation = useMutation({
    mutationFn: verifyCode,
    onSuccess: async (data: VerifyResponse) => {
      if ("ott" in data && data.ott) {
        await handleOTT(data.ott);
      } else if ("next" in data && data.next === "validate_2fa") {
        navigate(ROUTES.TWO_FACTOR);
      }
    },
    onError: (error: ErrorResponse) => {
      if (error?.response?.data?.error?.inline === "code_ti") {
        setError(
          error?.response?.data?.error?.display || t("auth.common.error")
        );
      }
    },
    onSettled: () => {
      setIsVerifying(false);
    },
  });

  const resendCodeMutation = useMutation({
    mutationFn: async () => {
      return POST<{ message: string }>("/api/auth/email/verify/request", {
        email,
      });
    },
    onError: (error: ErrorResponse) => {
      setError(error?.response?.data?.error?.display || t("auth.common.error"));
    },
  });

  const handleAuthCodeChange = (value: string) => {
    const trimmedValue = value.replace(/\s+/g, "").trim();
    setAuthCode(trimmedValue);
    if (error) setError(null);
    if (trimmedValue.length >= 6) {
      setIsVerifying(true);
      verifyMutation.mutate(trimmedValue);
    }
  };

  const handleResendCode = () => {
    setError(null);
    resendCodeMutation.mutate();
  };

  const handleGoToRegistration = () => {
    navigate(ROUTES.REGISTER + `?mode=form`);
  };

  return (
    <AuthCardWrapper>
      {resendCodeMutation.isPending && <Loading />}
      <BlockStack gap="600">
        <BlockStack gap="400" inlineAlign="start">
          <Text variant="heading2xl" as="h1">
            {t("auth.regEmailCheck.checkYourEmail")}
          </Text>
          <TranslateLink
            text={t("auth.regEmailCheck.description", { email })}
          ></TranslateLink>
        </BlockStack>
        <TextField
          label={t("auth.regEmailCheck.enterCode")}
          autoComplete="off"
          value={authCode}
          onChange={handleAuthCodeChange}
          autoFocus
        />
        {error && <InlineError fieldID="verification_code" message={error} />}
        {isVerifying && (
          <BlockStack align="center">
            <InlineStack align="center">
              <Box paddingBlockStart="400">
                <Spinner />
              </Box>
            </InlineStack>
          </BlockStack>
        )}
        <Box paddingBlockEnd="200">
          <InlineStack gap="100">
            <Link onClick={handleResendCode} removeUnderline>
              {t("auth.regEmailCheck.resendCode")}
            </Link>
            <Text as="p" tone="subdued">
              {t("auth.login.or")}
            </Text>

            <TranslateLink
              text={t("auth.regEmailCheck.changeEmail")}
              onClick={handleGoToRegistration}
            />
            <TranslateLink text={t("auth.regEmailCheck.helpCenter")} />
          </InlineStack>
        </Box>
      </BlockStack>
      <br></br>
    </AuthCardWrapper>
  );
};

export default CheckAuthApp;
