import CardWrapper from "@/auth/components/CardWrapper";
import { BlockStack, Box, InlineStack, Spinner, Text } from "@shopify/polaris";
import { PageTypes } from "@/types/commonTypes";
import { useTranslation } from "react-i18next";

function LoadingPage() {
  const { t } = useTranslation();
  return (
    <CardWrapper
      type={PageTypes.UNKNOW}
      showLocaleSwitcher={false}
      wrapperClass="auth-card-wrapper"
    >
      <Box minHeight="300" paddingBlockEnd={"1000"}>
        <InlineStack align="center" blockAlign="center">
          <BlockStack gap={"400"} align="center" inlineAlign="center">
            <Spinner />
            <Text variant="bodyMd" as="h1">
              {t("common.loadingMessage")}
            </Text>
          </BlockStack>
        </InlineStack>
      </Box>
    </CardWrapper>
  );
}

export default LoadingPage;
