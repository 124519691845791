import AuthCardWrapper from "./AuthCardWrapper";
import React, { useEffect, useState } from "react";
import TranslateLink from "../../common/TranslateLink";
import useApiRequest from "../../hooks/useApiRequest";
import useOTT from "../../hooks/useOTT";
import { ErrorResponse } from "../../types/commonTypes";
import { PasswordResetResponse, VerifyResponse } from "../types";
import { RootState } from "../../store";
import { ROUTES } from "../../utils/Constants";
import { setEmailFor2FA, setPasswordFor2FA } from "../authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormLayout,
  TextField,
  Box,
  InlineError,
  Banner,
  Link,
  Text,
  BlockStack,
} from "@shopify/polaris";

const PasswordReset: React.FC = () => {
  const { t } = useTranslation();
  const { POST } = useApiRequest();
  const { handleOTT } = useOTT();
  const emailForForgotPassword = useSelector(
    (state: RootState) => state.auth.email_for_forgot_password
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | null
  >(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [error, setError] = useState<string>("");
  const [isResetMode, setIsResetMode] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    if (token && email) {
      setToken(token);
      setEmail(email);
      setIsResetMode(true);
    } else {
      setEmail(emailForForgotPassword);
    }
  }, [location.search, emailForForgotPassword]);

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    if (passwordError) setPasswordError(null);
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    if (confirmPasswordError) setConfirmPasswordError(null);
  };

  const resetPasswordRequestMutation = useMutation({
    mutationFn: async () => {
      return POST<PasswordResetResponse>("/api/auth/password/reset/request", {
        email,
      });
    },
    onSuccess: (response: PasswordResetResponse) => {
      setSuccessMessage(response.message);
    },
    onError: (error: ErrorResponse) => {
      setError(
        error?.response?.data?.error?.display || t("auth.common.errorOccurred")
      );
    },
  });

  const resetPasswordMutation = useMutation({
    mutationFn: async () => {
      return POST<VerifyResponse>("/api/auth/password/reset", {
        email,
        password,
        token,
      });
    },
    onSuccess: async (data: VerifyResponse) => {
      if ("next" in data && data.next === "validate_2fa") {
        dispatch(setEmailFor2FA(email));
        dispatch(setPasswordFor2FA(password));
        navigate(ROUTES.TWO_FACTOR);
      } else if ("ott" in data) {
        await handleOTT(data.ott);
      }
    },
    onError: (error: ErrorResponse) => {
      setError(
        error?.response?.data?.error?.display || t("auth.common.errorOccurred")
      );
    },
  });

  const handleRequestSubmit = async () => {
    resetPasswordRequestMutation.mutate();
  };

  const handleResetSubmit = async () => {
    if (!password) {
      setPasswordError(t("auth.passwordReset.error.passwordRequired"));
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError(t("auth.registration.email.error.passNoMatch"));
      return;
    }

    resetPasswordMutation.mutate();
  };

  return (
    <AuthCardWrapper>
      <BlockStack gap="500" inlineAlign="start">
        <Box paddingBlock="200">
          <Text variant="heading2xl" as="h1">
            {isResetMode
              ? t("auth.passwordReset.resetTitle")
              : t("auth.passwordResetRequest.forgotPasswordTitle")}
          </Text>
          <TranslateLink
            text={
              isResetMode
                ? t("auth.passwordReset.description", { email })
                : t("auth.passwordResetRequest.description", { email })
            }
          ></TranslateLink>
        </Box>
      </BlockStack>
      <Box paddingBlock={"400"}>
        <Form onSubmit={isResetMode ? handleResetSubmit : handleRequestSubmit}>
          <FormLayout>
            {isResetMode ? (
              <FormLayout>
                <TextField
                  label={t("auth.passwordReset.newPassword")}
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="new-password"
                  error={passwordError ?? undefined}
                />
                <TextField
                  label={t("auth.passwordReset.confirmPassword")}
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  autoComplete="new-password"
                  error={confirmPasswordError ?? undefined}
                />
              </FormLayout>
            ) : successMessage ? (
              <Banner tone="success">
                <p>{successMessage}</p>
              </Banner>
            ) : (
              <FormLayout>
                <TextField
                  label={""}
                  type="email"
                  value={email}
                  disabled
                  autoComplete="email"
                />
              </FormLayout>
            )}
            {error && <InlineError message={error} fieldID="resetError" />}
            {!successMessage && (
              <Button
                variant="primary"
                size="large"
                onClick={isResetMode ? handleResetSubmit : handleRequestSubmit}
                fullWidth
                loading={
                  isResetMode
                    ? resetPasswordMutation.isPending
                    : resetPasswordRequestMutation.isPending
                }
              >
                {isResetMode
                  ? t("auth.passwordReset.resetPasswordCTA")
                  : t("auth.passwordResetRequest.resetPasswordCTA")}
              </Button>
            )}
            <Link url={ROUTES.LOGIN} removeUnderline>
              {t("auth.passwordResetRequest.returnToLogin")}
            </Link>
          </FormLayout>
        </Form>
      </Box>
    </AuthCardWrapper>
  );
};

export default PasswordReset;
