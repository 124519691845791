import PasswordProtectedButton from "@/common/PasswordProtectedButton";
import React, { useEffect, useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { GeneralResponse } from "@/types/commonTypes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { usePasswordVerification } from "@/common/PasswordVerificationContext";
import { useTranslation } from "react-i18next";
import {
  BlockStack,
  Box,
  Card,
  Modal,
  ResourceItem,
  ResourceList,
  TextField,
  Text,
  InlineStack,
  Loading,
  Badge,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";

interface AlternativeEmail {
  id: string;
  email: string;
  status: string;
}

const ManageAlternativeEmails: React.FC = () => {
  const { t } = useTranslation();
  const { GET, POST, DELETE, PATCH } = useApiRequest();
  const [emails, setEmails] = useState<AlternativeEmail[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [editingEmailId, setEditingEmailId] = useState<string | null>(null);
  const queryClient = useQueryClient();
  const { data: fetchedEmails = [], isFetching: isEmailsFetching } = useQuery<
    AlternativeEmail[]
  >({
    queryKey: ["alternative-emails"],
    queryFn: () => GET("/api/user/me/alternative-emails"),
  });

  const { verifyPassword } = usePasswordVerification();

  useEffect(() => {
    setEmails(fetchedEmails);
  }, [fetchedEmails]);

  const addEmailMutation = useMutation({
    mutationFn: (email: string) =>
      POST("/api/user/me/alternative-emails", { email }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["alternative-emails"] });
      setNewEmail("");
      setIsModalOpen(false);
    },
  });

  const editEmailMutation = useMutation({
    mutationFn: ({ id, email }: { id: string; email: string }) =>
      PATCH<GeneralResponse>(`/api/user/me/alternative-emails/${id}`, {
        email,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["alternative-emails"] });
      setNewEmail("");
      setEditingEmailId(null);
      setIsModalOpen(false);
    },
  });

  const deleteEmailMutation = useMutation({
    mutationFn: (id: string) =>
      DELETE<GeneralResponse>(`/api/user/me/alternative-emails/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["alternative-emails"] });
    },
  });

  const makePrimaryEmailMutation = useMutation({
    mutationFn: ({ id, email }: { id: string; email: string }) =>
      PATCH<GeneralResponse>(`/api/user/me/alternative-emails/${id}/primary`, {
        email,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      queryClient.invalidateQueries({ queryKey: ["alternative-emails"] });
    },
  });

  const resendVerificationMutation = useMutation({
    mutationFn: (id: string) =>
      POST<GeneralResponse>(
        `/api/user/me/alternative-emails/${id}/verify/request`,
        {}
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["alternative-emails"] });
    },
  });

  const handleAddEmail = (isVerified: boolean) => {
    if (isVerified) {
      addEmailMutation.mutate(newEmail);
    }
  };

  const handleEditEmail = (isVerified: boolean) => {
    if (isVerified && editingEmailId) {
      editEmailMutation.mutate({ id: editingEmailId, email: newEmail });
    }
  };

  const openEditModal = (email: string, id: string) => {
    setNewEmail(email);
    setEditingEmailId(id);
    setIsModalOpen(true);
  };

  const handleDeleteEmail = (id: string) => {
    deleteEmailMutation.mutate(id);
  };

  const handleMakePrimaryEmail = (
    isVerified: boolean,
    id: string,
    email: string
  ) => {
    if (isVerified) {
      makePrimaryEmailMutation.mutate({ id, email });
    }
  };

  const handleResendVerification = (isVerified: boolean, id: string) => {
    if (isVerified) {
      resendVerificationMutation.mutate(id);
    }
  };

  return (
    <>
      {(isEmailsFetching ||
        resendVerificationMutation.isPending ||
        makePrimaryEmailMutation.isPending ||
        deleteEmailMutation.isPending) && <Loading />}
      <Card padding="0">
        {isEmailsFetching ? (
          <Box padding={"400"}>
            <BlockStack gap={"400"}>
              <BlockStack gap={"200"}>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </BlockStack>
              <br />
              <BlockStack gap={"200"}>
                <SkeletonBodyText lines={2} />
              </BlockStack>
              <BlockStack gap={"200"}>
                <SkeletonBodyText lines={2} />
              </BlockStack>
            </BlockStack>
          </Box>
        ) : (
          <>
            <Box padding="400">
              <BlockStack gap="300">
                <InlineStack align="space-between">
                  <Text as="h2" variant="headingMd">
                    {t("security.alternativeEmailsTitle")}
                  </Text>
                  <PasswordProtectedButton
                    label={t("security.addAlternativeEmail")}
                    variant="plain"
                    onVerified={(isVerified) => setIsModalOpen(isVerified)}
                  />
                </InlineStack>
                <Text as="p" variant="bodyMd" tone="subdued">
                  {t("security.alternativeEmailsDescription")}
                </Text>
              </BlockStack>
            </Box>

            <ResourceList
              emptyState={
                <Box padding={"600"} paddingBlockEnd={"800"}>
                  <InlineStack align="center">
                    <Text as="p" tone="subdued">
                      {t("security.alternativeEmailsEmpty")}
                    </Text>
                  </InlineStack>
                </Box>
              }
              resourceName={{
                singular: t("security.email"),
                plural: t("security.emails"),
              }}
              items={emails}
              renderItem={(item) => {
                const shortcutActions = [
                  {
                    content: t("common.edit"),
                    onAction: async () => {
                      const isVerified = await verifyPassword();
                      isVerified && openEditModal(item.email, item.id);
                    },
                  },
                  {
                    content: t("common.remove"),
                    onAction: async () => {
                      const isVerified = await verifyPassword();
                      isVerified && handleDeleteEmail(item.id);
                    },
                    disabled: deleteEmailMutation.isPending,
                  },
                ];

                if (item.status === "verified") {
                  shortcutActions.unshift({
                    content: t("common.makePrimary"),
                    disabled: makePrimaryEmailMutation.isPending,
                    onAction: async () => {
                      const isVerified = await verifyPassword();
                      isVerified &&
                        handleMakePrimaryEmail(isVerified, item.id, item.email);
                    },
                  });
                } else {
                  shortcutActions.unshift({
                    content: t("common.resend"),
                    disabled: resendVerificationMutation.isPending,
                    onAction: async () => {
                      const isVerified = await verifyPassword();
                      isVerified &&
                        handleResendVerification(isVerified, item.id);
                    },
                  });
                }

                return (
                  <ResourceItem
                    id={item.id}
                    accessibilityLabel={`View details for ${item.email}`}
                    shortcutActions={shortcutActions}
                    persistActions
                    onClick={() => {}}
                  >
                    <Box paddingBlockStart={"100"}>
                      <InlineStack>
                        <Box width="250px">
                          <Text as="h2" variant="bodyMd">
                            {item.email}
                          </Text>
                        </Box>

                        <Badge
                          tone={
                            item.status === "verified" ? "success" : "warning"
                          }
                        >
                          {t(`security.status.${item.status}`)}
                        </Badge>
                      </InlineStack>
                    </Box>
                  </ResourceItem>
                );
              }}
            />
          </>
        )}
      </Card>

      {isModalOpen && (
        <Modal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title={
            editingEmailId
              ? t("security.editAlternativeEmailTitle")
              : t("security.addAlternativeEmailTitle")
          }
          primaryAction={{
            content: editingEmailId
              ? t("security.editAlternativeEmailCTA")
              : t("security.addAlternativeEmailCTA"),
            onAction: () =>
              editingEmailId ? handleEditEmail(true) : handleAddEmail(true),
            loading: addEmailMutation.isPending || editEmailMutation.isPending,
          }}
          secondaryActions={[
            {
              content: t("common.cancel"),
              onAction: () => setIsModalOpen(false),
            },
          ]}
        >
          <Modal.Section>
            <BlockStack gap="400">
              <TextField
                label={t("security.newAlternativeEmail")}
                value={newEmail}
                onChange={setNewEmail}
                autoComplete="email"
                autoFocus
              />
            </BlockStack>
          </Modal.Section>
        </Modal>
      )}
    </>
  );
};

export default ManageAlternativeEmails;
