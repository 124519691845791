import React, { useMemo } from "react";
import { CLINIC_ROUTES, ROOTS, SETTINGS_ROUTES } from "@/utils/Constants";
import { getClinicNavigationItems } from "@/clinic/utils";
import { Badge, Navigation } from "@shopify/polaris";
import { OrganizationIcon, SettingsFilledIcon } from "@shopify/polaris-icons";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useUserPermissions } from "@/hooks/useUserPermissions";
import usePerks from "@/hooks/usePerks";
import { useIsMobile } from "@/hooks/useIsMobile";
import { Clinic } from "@/types/clinic";
import { useQueryClient } from "@tanstack/react-query";

const ClinicNavigation: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const selectedClinicId = params.clinicId;
  const { userPermissions = [] } = useUserPermissions();
  const { perks = [] } = usePerks();
  const isMobile = useIsMobile();
  const queryClient = useQueryClient();
  const showPerksNew = useMemo(() => {
    return !!perks.find((p) => p.showAsNew);
  }, [perks]);

  const clinics =
    queryClient.getQueryData<Clinic[]>(["healthcare-companies"]) || [];

  const clinicNavigationItems = useMemo(() => {
    if (!selectedClinicId) return [];

    const items = getClinicNavigationItems(
      t,
      selectedClinicId,
      location.pathname,
      showPerksNew ? (
        <Badge tone="info" progress="complete" size="small" />
      ) : undefined,
      isMobile
    );

    // Filter main navigation items
    const filteredItems = items.filter(
      (item) => !item.adminOnly || userPermissions.includes("update_company")
    );

    // Filter sub-navigation items if they exist
    return filteredItems.map((item) => ({
      ...item,
      subNavigationItems: item.subNavigationItems?.filter(
        (subItem) =>
          !subItem.adminOnly || userPermissions.includes("update_company")
      ),
    }));
  }, [
    t,
    selectedClinicId,
    location.pathname,
    showPerksNew,
    userPermissions,
    isMobile,
  ]);

  if (!selectedClinicId) {
    return null;
  }

  return (
    <Navigation location={location.pathname}>
      <Navigation.Section items={clinicNavigationItems} fill={!isMobile} />
      {isMobile && clinics.length > 1 && (
        <Navigation.Section
          fill
          items={[
            {
              label: t("topbar.clinics"),
              icon: OrganizationIcon,

              url: "#",
              subNavigationItems: clinics.map((c) => ({
                label: c.name,
                url: ROOTS.CLINIC + "/" + c.id,
              })),
            },
          ]}
          separator
        />
      )}
      {userPermissions.includes("update_company") && (
        <Navigation.Section
          items={[
            {
              label: t("clinicNavigation.settings"),
              icon: SettingsFilledIcon,
              url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.SETTINGS}/${SETTINGS_ROUTES.DETAILS}`,
              selected:
                location.pathname ===
                `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.SETTINGS}/${SETTINGS_ROUTES.DETAILS}`,
            },
          ]}
        />
      )}
    </Navigation>
  );
};

export default ClinicNavigation;
