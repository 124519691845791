import { clearTokenData } from "../utils";
import { useEffect } from "react";

function Logout() {
  useEffect(() => {
    clearTokenData(false);
  }, []);
  return "";
}

export default Logout;
