import React, { useState } from "react";
import {
  Card,
  IndexTable,
  Button,
  Text,
  BlockStack,
  Loading,
  Box,
  InlineStack,
} from "@shopify/polaris";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useTranslation } from "react-i18next";
import TableSkeleton from "@/common/Skeletons/TableSkeleton";
import { useHealthcareCompanies } from "@/hooks/useHealthcareCompanies";
import RenderDate from "@/common/RenderDate";
import { XIcon } from "@shopify/polaris-icons";

const AccessCard: React.FC = () => {
  const { t } = useTranslation();
  const { DELETE } = useApiRequest();
  const queryClient = useQueryClient();
  const [leavingCompanyId, setLeavingCompanyId] = useState<string | null>(null);

  const {
    companies = [],
    isLoading,
    isFetching,
  } = useHealthcareCompanies(true);

  const leaveMutation = useMutation({
    mutationFn: (hccId: string) => {
      setLeavingCompanyId(hccId);
      return DELETE<{ message: string }>("/api/user/me/teams/leave", false, {
        hccId,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
      setLeavingCompanyId(null);
    },
    onError: () => {
      setLeavingCompanyId(null);
    },
  });

  return (
    <Card padding={"0"}>
      {isFetching && <Loading />}
      <BlockStack gap="400">
        <Box padding={"400"} paddingBlockEnd={"0"}>
          <BlockStack gap="200">
            <Text as="h1" variant="headingMd">
              {t("teamsAccess.access.title")}
            </Text>
            <Text variant="bodyMd" as="p">
              {t("teamsAccess.access.description")}
            </Text>
          </BlockStack>
        </Box>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <IndexTable
            resourceName={{
              singular: t("common.company"),
              plural: t("common.companies"),
            }}
            itemCount={companies.length}
            headings={[
              { title: t("common.company") },
              { title: t("common.role") },
              { title: t("common.joined") },
              { title: "" },
            ]}
            selectable={false}
          >
            {companies.map((company, index) => (
              <IndexTable.Row id={company.id} key={company.id} position={index}>
                <IndexTable.Cell>{company.name}</IndexTable.Cell>
                <IndexTable.Cell>
                  {company.userPermissions.role}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <RenderDate date={company.userPermissions.createdAt} />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <InlineStack align="end">
                    {company.userPermissions.roleId !== 10 && (
                      <Button
                        variant="tertiary"
                        onClick={() => leaveMutation.mutate(company.id)}
                        loading={leavingCompanyId === company.id}
                        size="slim"
                        tone="critical"
                        icon={XIcon}
                      >
                        {t("common.leave")}
                      </Button>
                    )}
                  </InlineStack>
                </IndexTable.Cell>
              </IndexTable.Row>
            ))}
          </IndexTable>
        )}
      </BlockStack>
    </Card>
  );
};

export default AccessCard;
