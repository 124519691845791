import i18n from "@/i18n";
import { Box, Icon, InlineStack, TopBar } from "@shopify/polaris";
import { CaretDownIcon, GlobeIcon } from "@shopify/polaris-icons";
import { setLocalStorage } from "@/utils/localStorageUtils";
import { useCallback, useState } from "react";
import dayjs from "dayjs";

function LocaleSwitcher({ topbarCase }: { topbarCase?: boolean }) {
  const [languageMenuActive, setLanguageMenuActive] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const toggleLanguageMenuActive = useCallback(
    () => setLanguageMenuActive((active) => !active),
    []
  );

  const handleLanguageChange = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
    setSelectedLanguage(newLanguage);
    setLocalStorage("selectedLanguage", newLanguage);
    dayjs.locale(newLanguage);
  };

  const languageMenuActions = [
    {
      items: [
        {
          content: "English",
          onAction: () => handleLanguageChange("en"),
          active: selectedLanguage === "en",
        },
        {
          content: "Български",
          onAction: () => handleLanguageChange("bg"),
          active: selectedLanguage === "bg",
        },
      ],
    },
  ];

  const topbarLanguageActivator = (
    <InlineStack wrap={false}>
      {/* <img
        width="30"
        height={"20"}
        src={selectedLanguage === "en" ? "https://assets.medentic.app/assets/images/flags/uk.svg" : "https://assets.medentic.app/assets/images/flags/bg.svg"}
        alt={selectedLanguage === "en" ? "English" : "Български"}
      /> */}
      <InlineStack align="center" blockAlign="center">
        <Icon source={GlobeIcon} />
        {!topbarCase
          ? selectedLanguage === "en"
            ? "English"
            : "Български"
          : ""}
      </InlineStack>
      <Box>
        <Icon source={CaretDownIcon} />
      </Box>
    </InlineStack>
  );

  return (
    <TopBar.Menu
      activatorContent={topbarLanguageActivator}
      open={languageMenuActive}
      onOpen={toggleLanguageMenuActive}
      onClose={toggleLanguageMenuActive}
      actions={languageMenuActions}
    />
  );
}

export default LocaleSwitcher;
