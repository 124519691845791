import useApiRequest from "./useApiRequest";
import { useQuery } from "@tanstack/react-query";
import { UserPermissions } from "@/types/commonTypes";

export const usePermissions = (enabled: boolean) => {
  const { GET } = useApiRequest();

  const fetchPermissions = async (): Promise<UserPermissions> => {
    const permissionsData: UserPermissions = await GET(
      "/api/user/me/permissions"
    );
    return permissionsData;
  };

  const { data, error, isLoading, isFetching, isError } = useQuery<
    UserPermissions,
    Error
  >({
    queryKey: ["permissions"],
    queryFn: fetchPermissions,
    enabled,
  });

  return { permissions: data, error, isLoading, isFetching, isError };
};
