import React, { useCallback, useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BlockStack,
  Box,
  Button,
  DropZone,
  List,
  Loading,
  SkeletonThumbnail,
} from "@shopify/polaris";
import { CompanyProfilePictureProps } from "../types";

const CompanyProfilePicture: React.FC<CompanyProfilePictureProps> = ({
  logoUrl,
  companyId,
  loading,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { POST, DELETE } = useApiRequest();
  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
  const hasError = rejectedFiles.length > 0;

  const uploadLogo = useMutation({
    mutationFn: (logoFile: File) => {
      const logoData = new FormData();
      logoData.append("file", logoFile);

      return POST(`/api/healthcare-companies/${companyId}/avatar`, logoData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
    },
  });

  const deleteLogo = useMutation({
    mutationFn: () => DELETE(`/api/healthcare-companies/${companyId}/avatar`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
    },
  });

  const handleDrop = useCallback(
    (_droppedFiles: File[], acceptedFiles: File[], rejectedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        uploadLogo.mutate(acceptedFiles[0]);
      }
      setFiles(acceptedFiles);
      setRejectedFiles(rejectedFiles);
    },
    [uploadLogo]
  );

  const handleLogoRemove = () => {
    deleteLogo.mutate();
    setFiles([]);
  };

  const fileUpload = !files.length && !logoUrl && (
    <DropZone.FileUpload
      actionTitle={t("companyProfilePicture.uploadAction")}
    />
  );

  const uploadedFiles =
    files.length > 0 || logoUrl ? (
      <BlockStack>
        <BlockStack align="center" inlineAlign="start">
          <img
            style={{ borderRadius: "4px" }}
            width="100px"
            height="100px"
            alt={logoUrl ?? files[0]?.name}
            src={logoUrl ?? window.URL.createObjectURL(files[0])}
          />
          <Box paddingBlockStart="100">
            <Button onClick={handleLogoRemove} variant="plain" tone="critical">
              {t("companyProfilePicture.removeLogo")}
            </Button>
          </Box>
        </BlockStack>
      </BlockStack>
    ) : null;

  const errorMessage = hasError && (
    <Banner title={t("companyProfilePicture.uploadErrorTitle")} tone="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={file.name + index}>
            {t("companyProfilePicture.uploadErrorItem", {
              fileName: file.name,
            })}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <BlockStack gap="400">
      {errorMessage}
      {(uploadLogo.isPending || deleteLogo.isPending) && <Loading />}
      {loading ? (
        <SkeletonThumbnail />
      ) : (
        <Box width="40%">
          {files.length === 0 && !logoUrl ? (
            <DropZone
              accept="image/png, image/jpeg, image/jpg, image/gif, image/webp, image/avif, image/heic, image/tiff"
              type="image"
              allowMultiple={false}
              onDrop={handleDrop}
            >
              {fileUpload}
            </DropZone>
          ) : (
            uploadedFiles
          )}
        </Box>
      )}
    </BlockStack>
  );
};

export default CompanyProfilePicture;
