import SchedulerComponent from "./SchedulerComponent";
import { BlockStack, Page } from "@shopify/polaris";
import "./styles.scss";
import AppPageWrapper from "../AppPageWrapper";
import { useTranslation } from "react-i18next";
const DevExpressScheduler = () => {
  const [t] = useTranslation();
  return (
    <AppPageWrapper showNotPlannedBanner>
      <Page fullWidth title={t("clinicNavigation.appointments")}>
        <BlockStack>
          <div className={`scheduler-container`}>
            <SchedulerComponent />
          </div>
        </BlockStack>
      </Page>
    </AppPageWrapper>
  );
};

export default DevExpressScheduler;
