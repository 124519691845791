import { useParams } from "react-router-dom";
import { useHealthcareCompanies } from "./useHealthcareCompanies";
import { useMemo } from "react";

export const useUserPermissions = () => {
  const { clinicId } = useParams();

  const { companies, isLoading, isError, error } = useHealthcareCompanies(true);

  const selectedClinic = useMemo(() => {
    return companies?.find((clinic) => clinic.id === clinicId);
  }, [companies, clinicId]);

  const userPermissions: string[] | undefined =
    selectedClinic?.userPermissions?.list;

  return { userPermissions, isLoading, isError, error };
};
