import bgTranslations from "@/locales/polaris_bg.json";
import enTranslations from "@shopify/polaris/locales/en.json";
import React, { ReactNode } from "react";
import { AppProvider } from "@shopify/polaris";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface PolarisProviderProps {
  children: ReactNode;
}

const PolarisProvider: React.FC<PolarisProviderProps> = ({ children }) => {
  const { i18n } = useTranslation();
  function AdapterLink({
    url,
    external = false,
    children,
    ...rest
  }: {
    url: string;
    external?: boolean;
    children?: React.ReactNode;
  }) {
    if (external) {
      return (
        <a href={url} {...rest} target="_blank">
          {children}
        </a>
      );
    } else {
      return (
        <Link to={url} {...rest}>
          {children}
        </Link>
      );
    }
  }

  return (
    <AppProvider
      i18n={i18n.language === "bg" ? bgTranslations : enTranslations}
      linkComponent={AdapterLink}
    >
      {children}
    </AppProvider>
  );
};

export default PolarisProvider;
