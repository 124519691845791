export const appointments = [
  {
    title: "Routine Checkup with Dr. Smith",
    startDate: new Date(2024, 7, 6, 9, 35),
    endDate: new Date(2024, 7, 6, 10, 30),
    id: 0,
    location: "Room 1",
  },
  {
    title: "Teeth Whitening Session",
    startDate: new Date(2024, 7, 2, 11, 0),
    endDate: new Date(2024, 7, 2, 12, 0),
    id: 1,
    location: "Room 1",
  },
  {
    title: "Root Canal Treatment with Dr. Jones",
    startDate: new Date(2024, 7, 2, 13, 30),
    endDate: new Date(2024, 7, 2, 15, 0),
    id: 2,
    location: "Room 2",
  },
  {
    title: "Dental Implant Consultation",
    startDate: new Date(2024, 7, 3, 10, 0),
    endDate: new Date(2024, 7, 3, 11, 0),
    id: 3,
    location: "Room 2",
  },
  {
    title: "Follow-Up Visit",
    startDate: new Date(2024, 7, 3, 12, 0),
    endDate: new Date(2024, 7, 3, 13, 0),
    id: 4,
    location: "Room 3",
  },
  {
    title: "Pediatric Dental Checkup",
    startDate: new Date(2024, 7, 3, 14, 0),
    endDate: new Date(2024, 7, 3, 15, 0),
    id: 5,
    location: "Room 3",
  },
  {
    title: "Orthodontic Adjustment",
    startDate: new Date(2024, 7, 4, 9, 45),
    endDate: new Date(2024, 7, 4, 11, 15),
    id: 6,
    location: "Room 1",
  },
  {
    title: "Gum Disease Treatment",
    startDate: new Date(2024, 7, 4, 12, 30),
    endDate: new Date(2024, 7, 4, 14, 30),
    id: 7,
    location: "Room 2",
  },
  {
    title: "Dental Cleaning",
    startDate: new Date(2024, 7, 4, 15, 0),
    endDate: new Date(2024, 7, 4, 16, 30),
    id: 8,
    location: "Room 3",
  },
  {
    title: "Consultation for Wisdom Teeth Removal",
    startDate: new Date(2024, 7, 5, 11, 0),
    endDate: new Date(2024, 7, 5, 12, 0),
    id: 9,
    location: "Room 3",
  },
  {
    title: "Emergency Dental Visit",
    startDate: new Date(2024, 7, 5, 13, 0),
    endDate: new Date(2024, 7, 5, 14, 30),
    id: 10,
    location: "Room 1",
  },
  {
    title: "Dental Crowns Fitting",
    startDate: new Date(2024, 7, 5, 15, 0),
    endDate: new Date(2024, 7, 5, 16, 30),
    id: 11,
    location: "Room 2",
  },
  {
    title: "Veneer Consultation",
    startDate: new Date(2024, 7, 6, 10, 0),
    endDate: new Date(2024, 7, 6, 11, 30),
    id: 12,
    location: "Room 2",
  },
  {
    title: "Dental X-rays",
    startDate: new Date(2024, 7, 6, 13, 30),
    endDate: new Date(2024, 7, 6, 15, 0),
    id: 13,
    location: "Room 3",
  },
  {
    title: "Final Orthodontic Consultation",
    startDate: new Date(2024, 7, 6, 16, 0),
    endDate: new Date(2024, 7, 6, 17, 30),
    id: 14,
    location: "Room 3",
  },
  {
    title: "Implant Placement Surgery",
    startDate: new Date(2024, 7, 7, 9, 30),
    endDate: new Date(2024, 7, 7, 12, 0),
    id: 15,
    location: "Room 1",
  },
  {
    title: "Cosmetic Dentistry Consultation",
    startDate: new Date(2024, 7, 7, 13, 30),
    endDate: new Date(2024, 7, 7, 15, 0),
    id: 16,
    location: "Room 3",
  },
  {
    title: "Full Mouth Restoration Planning",
    startDate: new Date(2024, 7, 7, 15, 30),
    endDate: new Date(2024, 7, 7, 17, 0),
    id: 17,
    location: "Room 2",
  },
  {
    title: "Consultation for Dental Braces",
    startDate: new Date(2024, 7, 8, 10, 0),
    endDate: new Date(2024, 7, 8, 11, 30),
    id: 18,
    location: "Room 1",
  },
  {
    title: "Treatment Planning for Oral Surgery",
    startDate: new Date(2024, 7, 8, 13, 30),
    endDate: new Date(2024, 7, 8, 15, 0),
    id: 19,
    location: "Room 3",
  },
  {
    title: "Regular Checkup and Cleaning",
    startDate: new Date(2024, 7, 8, 16, 0),
    endDate: new Date(2024, 7, 8, 17, 30),
    id: 20,
    location: "Room 2",
  },
  {
    title: "Emergency Consultation",
    startDate: new Date(2024, 7, 9, 10, 0),
    endDate: new Date(2024, 7, 9, 11, 0),
    id: 21,
    location: "Room 1",
  },
  {
    title: "Oral Hygiene Workshop",
    startDate: new Date(2024, 7, 9, 13, 0),
    endDate: new Date(2024, 7, 9, 14, 30),
    id: 22,
    location: "Room 2",
  },
  {
    title: "Fitting of Dental Bridges",
    startDate: new Date(2024, 7, 10, 9, 0),
    endDate: new Date(2024, 7, 10, 10, 30),
    id: 23,
    location: "Room 3",
  },
  {
    title: "Consultation for Gum Treatment",
    startDate: new Date(2024, 7, 10, 11, 0),
    endDate: new Date(2024, 7, 10, 12, 30),
    id: 24,
    location: "Room 1",
  },
  {
    title: "Review of Orthodontic Treatment",
    startDate: new Date(2024, 7, 10, 13, 30),
    endDate: new Date(2024, 7, 10, 15, 0),
    id: 25,
    location: "Room 2",
  },
  {
    title: "Preparation for Dental Surgery",
    startDate: new Date(2024, 7, 10, 15, 30),
    endDate: new Date(2024, 7, 10, 17, 0),
    id: 26,
    location: "Room 3",
  },
  {
    title: "Follow-Up on Oral Surgery",
    startDate: new Date(2024, 7, 11, 10, 0),
    endDate: new Date(2024, 7, 11, 11, 30),
    id: 27,
    location: "Room 1",
  },
  {
    title: "Review of Dental Implants",
    startDate: new Date(2024, 7, 11, 13, 0),
    endDate: new Date(2024, 7, 11, 14, 30),
    id: 28,
    location: "Room 2",
  },
  {
    title: "Consultation for Cosmetic Procedures",
    startDate: new Date(2024, 7, 11, 15, 0),
    endDate: new Date(2024, 7, 11, 16, 30),
    id: 29,
    location: "Room 3",
  },
  {
    title: "Emergency Dental Care",
    startDate: new Date(2024, 7, 12, 8, 0),
    endDate: new Date(2024, 7, 12, 9, 30),
    id: 30,
    location: "Room 1",
  },
  {
    title: "Orthodontic Braces Adjustment",
    startDate: new Date(2024, 7, 12, 10, 0),
    endDate: new Date(2024, 7, 12, 11, 30),
    id: 31,
    location: "Room 2",
  },
  {
    title: "Dental Hygiene Appointment",
    startDate: new Date(2024, 7, 12, 13, 0),
    endDate: new Date(2024, 7, 12, 14, 30),
    id: 32,
    location: "Room 3",
  },
  {
    title: "Preparation for Dental Crowns",
    startDate: new Date(2024, 7, 13, 9, 30),
    endDate: new Date(2024, 7, 13, 11, 0),
    id: 33,
    location: "Room 1",
  },
  {
    title: "Patient Education Session",
    startDate: new Date(2024, 7, 13, 12, 0),
    endDate: new Date(2024, 7, 13, 13, 30),
    id: 34,
    location: "Room 2",
  },
  {
    title: "Consultation for Dentures",
    startDate: new Date(2024, 7, 13, 14, 0),
    endDate: new Date(2024, 7, 13, 15, 30),
    id: 35,
    location: "Room 3",
  },
  {
    title: "General Checkup and Consultation",
    startDate: new Date(2024, 7, 14, 10, 0),
    endDate: new Date(2024, 7, 14, 11, 30),
    id: 36,
    location: "Room 1",
  },
  {
    title: "Emergency Dental Procedure",
    startDate: new Date(2024, 7, 14, 12, 0),
    endDate: new Date(2024, 7, 14, 13, 30),
    id: 37,
    location: "Room 2",
  },
  {
    title: "Post-Surgery Follow-Up",
    startDate: new Date(2024, 7, 14, 14, 0),
    endDate: new Date(2024, 7, 14, 15, 30),
    id: 38,
    location: "Room 3",
  },
  {
    title: "Review of Treatment Progress",
    startDate: new Date(2024, 7, 15, 10, 0),
    endDate: new Date(2024, 7, 15, 11, 30),
    id: 39,
    location: "Room 1",
  },
];
