import { Banner, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const NotPlannedBanner = () => {
  const { t } = useTranslation();

  return (
    <Banner title={t("notPlannedBanner.title")} tone="info">
      <Text as="p" tone="subdued">
        {t("notPlannedBanner.description")}
      </Text>
    </Banner>
  );
};

export default NotPlannedBanner;
