import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PatientFiltersState {
  tab: number;
  sort: string[];
  tags: string[];
  query: string;
  page: number;
}

const initialState: PatientFiltersState = {
  tab: 0,
  sort: ["name asc"],
  tags: [],
  query: "",
  page: 1,
};

const patientSlice = createSlice({
  name: "patientFilters",
  initialState,
  reducers: {
    setTab: (state, action: PayloadAction<number>) => {
      state.tab = action.payload;
    },
    setSort: (state, action: PayloadAction<string[]>) => {
      state.sort = action.payload;
    },
    setTags: (state, action: PayloadAction<string[]>) => {
      state.tags = action.payload;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    clearFilters: (state) => {
      state.tags = [];
      state.query = "";
      state.page = 1;
    },
  },
});

export const { setTab, setSort, setTags, setQuery, setPage, clearFilters } =
  patientSlice.actions;

export default patientSlice.reducer;
