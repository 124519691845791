import React, { useState, useMemo, useCallback } from "react";
import {
  IndexTable,
  Card,
  Page,
  IndexFilters,
  Badge,
  ChoiceList,
  Link,
  useSetIndexFiltersMode,
  Loading,
  Modal,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import PropertiesModal from "./PropertiesModal";
import { GroupData, Property } from "../../types";
import useApiRequest from "@/hooks/useApiRequest";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import TabsTableSkeleton from "@/common/Skeletons/TabsTableSkeleton";
import AppPageWrapper from "@/common/AppPageWrapper";
import { ExportIcon, ResetIcon } from "@shopify/polaris-icons";

const PatientProperties: React.FC = () => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingProperty, setEditingProperty] = useState<Property>();
  const { mode, setMode } = useSetIndexFiltersMode();
  const [showResetModal, setShowResetModal] = useState(false);

  const [queryValue, setQueryValue] = useState("");
  const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const { GET, DELETE } = useApiRequest();
  const queryClient = useQueryClient();
  const params = useParams();
  const clinicId = params.clinicId;

  const {
    data: groupsData = [],
    isLoading: isSchemaLoading,
    isFetching: isSchemaFetching,
  } = useQuery<GroupData[]>({
    queryKey: ["patient-schema"],
    queryFn: () => GET(`/api/healthcare-companies/${clinicId}/patients/schema`),
  });

  const groups = useMemo(
    () => (groupsData.length ? groupsData[0]?.groups : []),
    [groupsData]
  );

  // Create a map of groupId to groupName
  const groupNameMap = useMemo(() => {
    const map: Record<string, string> = {};
    groups.forEach((group) => {
      map[group.name] = group.label;
    });
    return map;
  }, [groups]);

  const { data: properties = [], isLoading } = useQuery<Property[]>({
    queryKey: ["patient-schema-properties"],
    queryFn: () =>
      GET(`/api/healthcare-companies/${clinicId}/patients/schema/properties`),
  });

  const tabs = useMemo(
    () => [
      {
        id: "all",
        content: `${t("properties.tabs.all")} (${properties.length})`,
      },
      {
        id: "custom",
        content: `${t("properties.tabs.custom")} (${
          properties.filter((p) => !p.isMedenticDefined).length
        })`,
      },
      {
        id: "readOnly",
        content: `${t("properties.tabs.readOnly")} (${
          properties.filter((p) => p.isReadOnlyValue).length
        })`,
      },
      {
        id: "archived",
        content: `${t("properties.tabs.archived")} (${
          properties.filter((p) => p.isHiddenDefinition).length
        })`,
      },
    ],
    [properties, t]
  );

  const handleTabChange = (index: number) => {
    setSelectedTab(index);
  };

  const handleOpenModal = (property?: Property) => {
    setEditingProperty(property);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingProperty(undefined);
  };

  const filteredProperties = useMemo(() => {
    let filtered = properties;

    // Filter by query
    if (queryValue) {
      filtered = filtered.filter((property) =>
        property.label.toLowerCase().includes(queryValue.toLowerCase())
      );
    }

    // Filter by tabs
    if (selectedTab === 1) {
      filtered = filtered.filter((property) => !property.isMedenticDefined);
    } else if (selectedTab === 2) {
      filtered = filtered.filter((property) => property.isReadOnlyValue);
    } else if (selectedTab === 3) {
      filtered = filtered.filter((property) => property.isHiddenDefinition);
    }

    // Filter by group
    if (selectedGroup.length) {
      filtered = filtered.filter((property) =>
        selectedGroup.includes(property.groupId)
      );
    }

    // Filter by type
    if (selectedType.length) {
      filtered = filtered.filter(
        (property) =>
          property?.renderer && selectedType.includes(property?.renderer?.type)
      );
    }

    return filtered;
  }, [properties, queryValue, selectedGroup, selectedType, selectedTab]);

  const appliedFilters = [];
  if (selectedGroup.length > 0) {
    appliedFilters.push({
      key: "group",
      label: `${t("properties.filters.group")}: ${selectedGroup
        .map((group) => groupNameMap[group])
        .join(", ")}`,
      onRemove: () => setSelectedGroup([]),
    });
  }
  if (selectedType.length > 0) {
    appliedFilters.push({
      key: "type",
      label: `${t("properties.filters.type")}: ${selectedType.join(", ")}`,
      onRemove: () => setSelectedType([]),
    });
  }
  const handleClearAll = useCallback(() => {
    setQueryValue("");
    setSelectedGroup([]);
    setSelectedType([]);
  }, []);

  const handleExport = () => {
    console.log("Export action triggered");
  };

  const handleGroups = () => {
    console.log("Groups action triggered");
  };

  const resetSchemaMutation = useMutation({
    mutationFn: () =>
      DELETE(`/api/healthcare-companies/${clinicId}/patients/schema`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patient-schema"] });
      queryClient.invalidateQueries({
        queryKey: ["patient-schema-properties"],
      });
      setShowResetModal(false);
    },
    onError: (error) => {
      console.error("Failed to reset schema:", error);
    },
  });

  const handleResetSchema = () => {
    resetSchemaMutation.mutate();
  };

  return (
    <AppPageWrapper showNearDevelopmentBanner>
      <Page
        fullWidth
        title={t("properties.title")}
        primaryAction={{
          content: t("properties.addProperty"),
          onAction: () => handleOpenModal(),
        }}
        secondaryActions={[
          {
            content: t("properties.groups"),
            onAction: handleGroups,
            disabled: true,
          },
        ]}
        actionGroups={[
          {
            title: t("patients.moreActions"),
            actions: [
              {
                content: t("patients.exportSchema"),
                icon: ExportIcon,
                disabled: true,
                onAction: handleExport,
              },
              {
                content: t("patients.resetSchema"),
                icon: ResetIcon,
                destructive: true,
                onAction: () => setShowResetModal(true),
              },
            ],
          },
        ]}
      >
        {isSchemaFetching && <Loading />}
        {isLoading || isSchemaLoading ? (
          <TabsTableSkeleton />
        ) : (
          <Card padding={"0"}>
            <IndexFilters
              canCreateNewView={false}
              tabs={tabs}
              selected={selectedTab}
              onSelect={handleTabChange}
              mode={mode}
              setMode={setMode}
              filters={[
                {
                  key: "group",
                  label: t("properties.filters.group"),
                  filter: (
                    <ChoiceList
                      title={t("properties.filters.group")}
                      titleHidden
                      choices={groups.map((group) => ({
                        label: groupNameMap[group.name],
                        value: group.name,
                      }))}
                      selected={selectedGroup}
                      onChange={setSelectedGroup}
                      allowMultiple
                    />
                  ),
                },
                {
                  key: "type",
                  label: t("properties.filters.type"),
                  filter: (
                    <ChoiceList
                      title={t("properties.filters.type")}
                      titleHidden
                      choices={[
                        { label: "Text", value: "text" },
                        { label: "Number", value: "number" },
                        { label: "Phone", value: "phoneNumber" },
                      ]}
                      selected={selectedType}
                      onChange={setSelectedType}
                      allowMultiple
                    />
                  ),
                },
              ]}
              appliedFilters={appliedFilters}
              queryValue={queryValue}
              onQueryChange={setQueryValue}
              onQueryClear={() => setQueryValue("")}
              onQueryBlur={() => console.log("Query blur")}
              onClearAll={handleClearAll}
              cancelAction={{
                onAction: () => {},
                disabled: false,
                loading: false,
              }}
            />
            <IndexTable
              itemCount={filteredProperties.length}
              resourceName={{
                singular: t("common.property"),
                plural: t("common.properties"),
              }}
              headings={[
                { title: t("properties.columns.name") },
                { title: t("properties.columns.type") },
                { title: t("properties.columns.group") },
                { title: t("properties.columns.isCustom") },
                { title: t("properties.columns.mandatory") },
                { title: t("properties.columns.inCreateForm") },
                { title: t("properties.columns.usedIn") },
              ]}
            >
              {filteredProperties.map(
                (
                  {
                    id,
                    label,
                    renderer,
                    groupId,
                    isMedenticDefined,
                    isRequired,
                    usage,
                    isHiddenDefinition,
                    isInCreateForm,
                  },
                  index
                ) => (
                  <IndexTable.Row
                    id={id}
                    key={id}
                    position={index}
                    onClick={
                      isHiddenDefinition
                        ? undefined
                        : () => {
                            handleOpenModal(filteredProperties[index]);
                          }
                    }
                  >
                    <IndexTable.Cell>{label}</IndexTable.Cell>
                    <IndexTable.Cell>
                      {t(`properties.type.${renderer?.id}`)}
                    </IndexTable.Cell>
                    <IndexTable.Cell>{groupNameMap[groupId]}</IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge
                        progress={isMedenticDefined ? "incomplete" : "complete"}
                      >
                        {isMedenticDefined ? t("common.no") : t("common.yes")}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge
                        progress={isInCreateForm ? "complete" : "incomplete"}
                      >
                        {isInCreateForm ? t("common.yes") : t("common.no")}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge progress={isRequired ? "complete" : "incomplete"}>
                        {isRequired ? t("common.yes") : t("common.no")}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {usage && usage > 0 ? (
                        <div onClick={(event) => event.stopPropagation()}>
                          <Link>{usage}</Link>
                        </div>
                      ) : (
                        usage
                      )}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                )
              )}
            </IndexTable>
          </Card>
        )}

        {isModalOpen && (
          <PropertiesModal
            open={isModalOpen}
            onClose={handleCloseModal}
            initialData={editingProperty}
          />
        )}
      </Page>
      <br />
      {showResetModal && (
        <Modal
          open={showResetModal}
          onClose={() => setShowResetModal(false)}
          title={t("patients.resetSchemaModal.title")}
          primaryAction={{
            content: t("patients.resetSchemaModal.cta.primary"),
            destructive: true,
            onAction: handleResetSchema,
            loading: resetSchemaMutation.isPending,
          }}
          secondaryActions={[
            {
              content: t("patients.resetSchemaModal.cta.secondary"),
              onAction: () => setShowResetModal(false),
              disabled: resetSchemaMutation.isPending,
            },
          ]}
        >
          <Modal.Section>
            {t("patients.resetSchemaModal.description")}
          </Modal.Section>
        </Modal>
      )}
    </AppPageWrapper>
  );
};

export default PatientProperties;
