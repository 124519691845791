/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useCallback } from "react";
import {
  Modal,
  BlockStack,
  SkeletonBodyText,
  Box,
  Banner,
  Button,
  Popover,
  ActionList,
  InlineStack,
  Checkbox,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Property, GroupData, Group } from "../types";
import useApiRequest from "@/hooks/useApiRequest";
import { PropertyTypeRenderer } from "@/common/PropertyTypeRenderer/PropertyTypeRenderer";
import TranslateLink from "@/common/TranslateLink";
import { MenuHorizontalIcon } from "@shopify/polaris-icons";
import { CLINIC_ROUTES } from "@/utils/Constants";
import { showToast } from "@/reducers/toastSlice";
import { useDispatch } from "react-redux";

interface AddPatientModalProps {
  open: boolean;
  onClose: () => void;
}

const AddPatientModal: React.FC<AddPatientModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { GET, POST } = useApiRequest();
  const queryClient = useQueryClient();
  const params = useParams();
  const clinicId = params.clinicId;
  const [showPickedFields, setShowPickedFields] = useState(true);
  const navigate = useNavigate();
  const [activePopover, setActivePopover] = useState(false);
  const [createAnother, setCreateAnother] = useState(false);
  const dispatch = useDispatch();
  const { data: groupsData = [], isLoading: isSchemaLoading } = useQuery<
    GroupData[]
  >({
    queryKey: ["patient-schema"],
    queryFn: () => GET(`/api/healthcare-companies/${clinicId}/patients/schema`),
  });

  // Filter & sort
  const groups = useMemo(
    () => (groupsData.length ? groupsData[0]?.groups : []),
    [groupsData]
  );

  const groupedProperties = useMemo(() => {
    if (!groups || groups.length === 0) return [];
    return groups.map((g: Group) => {
      const filtered = g.properties
        .filter((p) => (showPickedFields ? p.isInCreateForm : true))
        .sort((a, b) => (a.displayOrder ?? 999) - (b.displayOrder ?? 999));
      return {
        ...g,
        properties: filtered,
      };
    });
  }, [groups, showPickedFields]);

  // Initialize form values with defaultValue or empty
  const initialFormValues = useMemo(() => {
    const init: Record<string, any> = {};
    groupedProperties.forEach((group) => {
      group.properties.forEach((p) => {
        if (p.defaultValue !== undefined) {
          init[p.name] = p.defaultValue;
        } else {
          init[p.name] = undefined;
        }
      });
    });
    return init;
  }, [groupedProperties]);

  const [formValues, setFormValues] =
    useState<Record<string, any>>(initialFormValues);

  // Errors: { [propName]: "Error message" }
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = useCallback(
    (propName: string, newValue: any) => {
      setFormValues((prev) => ({ ...prev, [propName]: newValue }));
      setErrors((prev) => ({ ...prev, [propName]: "" }));
    },
    [setFormValues, setErrors]
  );

  // Create mutation
  const createPatientMutation = useMutation({
    mutationFn: async (payload: Record<string, any>) => {
      return POST(`/api/healthcare-companies/${clinicId}/patients`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patients"] });
      dispatch(
        showToast({
          message: t("addPatientModal.addPatientSuccessMsg"),
          type: "success",
        })
      );
      if (createAnother) {
        setFormValues(initialFormValues);
      } else {
        onClose();
      }
    },
    onError: (err: any) => {
      const error = err?.response?.data?.error;
      console.error(error);
      if (error.inline && error.display) {
        setErrors((prev) => ({
          ...prev,
          [error.inline]: error.display,
        }));
      }
    },
  });

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    groupedProperties.forEach((group) => {
      group.properties.forEach((p: Property) => {
        const value = formValues[p.name];

        // Check if the field is required
        if (p.isRequired) {
          if (
            value === "" ||
            value === null ||
            (Array.isArray(value) && value.length === 0)
          ) {
            newErrors[p.name] = t("validation.requiredField");
          }
        }

        // Check if the field has a regex pattern and validate against it
        if (p.regExp && value) {
          const regex = new RegExp(p.regExp);
          if (!regex.test(value)) {
            newErrors[p.name] = t("validation.notAValidValue");
          }
        }
      });
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    if (validateForm()) {
      createPatientMutation.mutate(formValues);
    }
  };

  const handleNavigate = () => {
    navigate(`../${CLINIC_ROUTES.PATIENTS_DATA_MANAGEMENT}`);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("addPatientModal.title")}
      primaryAction={{
        content: t("common.save"),
        onAction: handleSave,
        loading: createPatientMutation.isPending,
      }}
      size="large"
      secondaryActions={[
        {
          content: t("common.cancel"),
          onAction: onClose,
        },
      ]}
      footer={
        <InlineStack gap="400" align="center">
          <Checkbox
            label={t("addPatientModal.addAnotherPatient")}
            checked={createAnother}
            onChange={() => setCreateAnother(!createAnother)}
          />
        </InlineStack>
      }
    >
      {isSchemaLoading ? (
        <Box padding={"400"}>
          <SkeletonBodyText />
        </Box>
      ) : (
        <Box paddingBlock={"400"} paddingInline={"400"}>
          <BlockStack gap="400">
            <Banner tone="info">
              <TranslateLink
                text={t("addPatientModal.customizeFieldsMessage")}
              />
            </Banner>

            <InlineStack gap="400" align="space-between">
              <p>{t("addPatientModal.requiredFieldsNote")}</p>
              <div>
                <Popover
                  active={activePopover}
                  activator={
                    <div>
                      <Button
                        onClick={() => setActivePopover(!activePopover)}
                        variant="plain"
                        icon={MenuHorizontalIcon}
                      ></Button>
                    </div>
                  }
                  onClose={() => setActivePopover(false)}
                  preferredAlignment="right"
                >
                  <ActionList
                    items={[
                      {
                        content: t("addPatientModal.configureField"),
                        onAction: handleNavigate,
                      },
                      {
                        content: showPickedFields
                          ? t("addPatientModal.showAllFields")
                          : t("addPatientModal.showPickedFields"),
                        onAction: () => setShowPickedFields(!showPickedFields),
                      },
                    ]}
                  />
                </Popover>
              </div>
            </InlineStack>

            {groupedProperties.map((group) => {
              if (!group.properties.length) return null;

              return (
                <Box key={group.name}>
                  <BlockStack gap={"300"}>
                    {/* Render each property using PropertyTypeRenderer */}
                    <BlockStack gap="400">
                      {group.properties.map((prop: Property) => (
                        <PropertyTypeRenderer
                          isRequired={prop.isRequired}
                          key={prop.name}
                          id={prop.renderer?.id ?? "textInput"}
                          label={prop.label}
                          value={formValues[prop.name]}
                          onChange={(val) => handleChange(prop.name, val)}
                          disabled={false}
                          helpText={prop.description}
                          placeholder=""
                          comboOptions={prop.options ?? []}
                          error={errors[prop.name]} // Pass error message to the renderer
                        />
                      ))}
                    </BlockStack>
                  </BlockStack>
                </Box>
              );
            })}
          </BlockStack>
        </Box>
      )}
    </Modal>
  );
};

export default AddPatientModal;
