import React, { useState } from "react";
import {
  Card,
  IndexTable,
  Button,
  Text,
  BlockStack,
  Loading,
  Box,
  Popover,
  ActionList,
  InlineStack,
} from "@shopify/polaris";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useTranslation } from "react-i18next";
import TableSkeleton from "@/common/Skeletons/TableSkeleton";
import { Invitation } from "@/types/commonTypes";
import RenderDate from "@/common/RenderDate";
import {
  CheckCircleIcon,
  MenuHorizontalIcon,
  XIcon,
} from "@shopify/polaris-icons";
import "../styles.scss";
const InvitesCard: React.FC = () => {
  const { t } = useTranslation();
  const { GET, POST, DELETE } = useApiRequest();
  const queryClient = useQueryClient();

  const { data: invitations = [], isLoading } = useQuery<Invitation[]>({
    queryKey: ["invitations"],
    queryFn: () => GET("/api/user/me/invites"),
  });

  const [loadingAcceptToken, setLoadingAcceptToken] = useState<string | null>(
    null
  );
  const [loadingDeclineToken, setLoadingDeclineToken] = useState<string | null>(
    null
  );
  const [activePopover, setActivePopover] = useState<string | null>(null);

  const acceptMutation = useMutation({
    mutationFn: (token: string) =>
      POST<{ message: string }>("/api/user/me/invites/accept", { token }),
    onMutate: (token) => setLoadingAcceptToken(token),
    onSuccess: () => {
      setLoadingAcceptToken(null);
      queryClient.invalidateQueries({ queryKey: ["invitations"] });
    },
    onError: () => setLoadingAcceptToken(null),
  });

  const declineMutation = useMutation({
    mutationFn: (token: string) =>
      DELETE<{ message: string }>("/api/user/me/invites/decline", false, {
        token,
      }),
    onMutate: (token) => setLoadingDeclineToken(token),
    onSuccess: () => {
      setLoadingDeclineToken(null);
      queryClient.invalidateQueries({ queryKey: ["invitations"] });
    },
    onError: () => setLoadingDeclineToken(null),
  });

  const togglePopover = (token: string) => {
    setActivePopover(activePopover === token ? null : token);
  };

  return (
    <Card padding={"0"}>
      {isLoading && <Loading />}
      <BlockStack gap="400">
        <Box padding={"400"} paddingBlockEnd={"0"}>
          <BlockStack gap="200">
            <Text as="h1" variant="headingMd">
              {t("teamsAccess.invites.title")}
            </Text>
            <Text variant="bodyMd" as="p">
              {t("teamsAccess.invites.description")}
            </Text>
          </BlockStack>
        </Box>

        {isLoading ? (
          <TableSkeleton />
        ) : (
          <IndexTable
            resourceName={{
              singular: t("common.invitation"),
              plural: t("common.invitations"),
            }}
            itemCount={invitations.length}
            headings={[
              { title: t("common.company") },
              { title: t("common.sentBy") },
              { title: t("common.role") },
              { title: t("common.expiresAt") },
              { title: "" },
            ]}
            selectable={false}
          >
            {invitations.map((invite, index) => (
              <IndexTable.Row
                id={invite.token}
                key={invite.token}
                position={index}
              >
                <IndexTable.Cell>{invite.company.name}</IndexTable.Cell>
                <IndexTable.Cell>
                  {invite.inviter.firstName} {invite.inviter.lastName}
                </IndexTable.Cell>
                <IndexTable.Cell>{invite.role}</IndexTable.Cell>
                <IndexTable.Cell>
                  <RenderDate date={invite.expiresAt} />
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <div className="desktopButtons">
                    <InlineStack gap="100" align="end">
                      <Button
                        variant="tertiary"
                        onClick={() => acceptMutation.mutate(invite.token)}
                        loading={loadingAcceptToken === invite.token}
                        size="slim"
                        icon={CheckCircleIcon}
                      >
                        {t("common.accept")}
                      </Button>
                      <Button
                        variant="tertiary"
                        onClick={() => declineMutation.mutate(invite.token)}
                        loading={loadingDeclineToken === invite.token}
                        size="slim"
                        tone="critical"
                        icon={XIcon}
                      >
                        {t("common.decline")}
                      </Button>
                    </InlineStack>
                  </div>
                  <div className="mobileButtons">
                    <InlineStack align="end">
                      <Popover
                        active={activePopover === invite.token}
                        activator={
                          <Button
                            variant="tertiary"
                            onClick={() => togglePopover(invite.token)}
                            size="slim"
                            icon={MenuHorizontalIcon}
                          ></Button>
                        }
                        onClose={() => setActivePopover(null)}
                      >
                        <ActionList
                          items={[
                            {
                              content: t("common.accept"),
                              onAction: () =>
                                acceptMutation.mutate(invite.token),
                              disabled: loadingAcceptToken === invite.token,
                            },
                            {
                              content: t("common.decline"),
                              onAction: () =>
                                declineMutation.mutate(invite.token),
                              disabled: loadingDeclineToken === invite.token,
                              destructive: true,
                            },
                          ]}
                        />
                      </Popover>
                    </InlineStack>
                  </div>
                </IndexTable.Cell>
              </IndexTable.Row>
            ))}
          </IndexTable>
        )}
      </BlockStack>
    </Card>
  );
};

export default InvitesCard;
