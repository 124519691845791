import { dismissToast, selectToasts } from "@/reducers/toastSlice";
import { Toast } from "@shopify/polaris";
import { useDispatch, useSelector } from "react-redux";

const AppToast = () => {
  const dispatch = useDispatch();
  const toasts = useSelector(selectToasts);

  if (toasts.length === 0) {
    return null;
  }

  return (
    <>
      {toasts.map((toast, index) => (
        <Toast
          key={index + toast.message}
          content={toast.message}
          error={toast.type === "error"}
          onDismiss={() => {
            dispatch(dismissToast());
            if (toast.onDismiss) toast.onDismiss();
          }}
        />
      ))}
    </>
  );
};

export default AppToast;
