import useApiRequest from "./useApiRequest";
import { Clinics } from "@/types/clinic";
import { useQuery } from "@tanstack/react-query";

export const useHealthcareCompanies = (enabled: boolean) => {
  const { GET } = useApiRequest();

  const fetchHealthcareCompanies = async (): Promise<Clinics> => {
    const healthcareCompaniesData: Clinics = await GET(
      "/api/healthcare-companies"
    );
    return healthcareCompaniesData;
  };

  const { data, error, isLoading, isFetching, isError } = useQuery<
    Clinics,
    Error
  >({
    queryKey: ["healthcare-companies"],
    queryFn: fetchHealthcareCompanies,
    enabled,
  });

  return { companies: data, error, isLoading, isFetching, isError };
};
