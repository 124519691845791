export const ROOTS = {
  CLINIC: "/c",
  PATIENT: "/p",
  USER: "/u",
  AUTH: "/v",
};

export const USER_ROUTES = {
  DETAILS: "details",
  PROFILE: "profile",
  SECURITY: "security",
  CALENDER: "calender",
  COMPLIANCE: "compliance",
  TEAMACCESS: "team-access",
  APP_MODES: "mode",
  QUESTIONNAIRE: "questionnaire",
  INVITES: "invites",
  AUTOJOIN: "auto-join",
};

export const CLINIC_ROUTES = {
  PATIENTS: "patients",
  PATIENT_DETAILS: "patients/:patientId",
  PATIENTS_DATA_MANAGEMENT: "patients/data-management",
  PATIENTS_TAGS: "patients/tags",
  PATIENTS_PROCEDURES: "patients/procedures",
  PATIENTS_TREATMENT_PLANS: "patients/treatment-plans",
  PATIENTS_MEDICATIONS: "patients/medications",
  APPOINTMENTS: "appointments",
  STATISTICS: "statistics",
  MINI_SITE: "mini-site",
  PAYMENTS: "payments",
  DOCUMENTS: "documents",
  PERKS: "perks",
  SETTINGS: "settings",
  SCHEDULER: "scheduler",
};

export const SETTINGS_ROUTES = {
  DETAILS: "details",
  LOCATIONS: "locations",
  SUBSCRIPTION_PLAN: "subscription-plan",
  MEMBERS_PERMISSIONS: "members-permissions",
  COMPLIANCE: "compliance",
  INVOICES: "invoices",
};

export const PATIENT_ROUTES = {
  DASHBOARD: "dashboard",
};

export const ROUTES = {
  REGISTER: "/v/register",
  REGISTER_CHECK_EMAIL: "/v/register/check-email",
  LOGIN: "/v/login",
  LOGOUT: "/v/logout",
  PASSWORD_RESET: "/v/password-reset",
  OTT: "/v/ott",
  TWO_FACTOR: "/v/login/two-factor",
  ALTERNATIVE_EMAIL_VERIFY: "/v/alternative-email",
  JOIN: "/v/join",
};

export const REGISTER_STEPS = {
  OPTIONS: "options",
  FORM: "form",
  GOOGLE_SIGN_UP: "google_sign_up",
};
