import CardWrapper from "@/auth/components/CardWrapper";
import useNavigateToClinic from "@/hooks/useNavigateToClinic";
import { ChevronRightIcon } from "@shopify/polaris-icons";
import { Clinic } from "@/types/clinic";
import { PageTypes } from "@/types/commonTypes";
import { ROOTS } from "@/utils/Constants";
import { removeLocalStorage, setLocalStorage } from "@/utils/localStorageUtils";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Avatar,
  Badge,
  BlockStack,
  Box,
  Icon,
  InlineStack,
  ResourceItem,
  ResourceList,
  Text,
} from "@shopify/polaris";

function ClinicList() {
  const { t } = useTranslation();
  const { navigateToClinic } = useNavigateToClinic();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const clinics = queryClient.getQueryData<Clinic[]>(["healthcare-companies"]);

  return (
    <CardWrapper type={PageTypes.UNKNOW}>
      <Box paddingBlockEnd={"500"}>
        <Text as="p" variant="headingLg">
          {t("clinicList.title")}
        </Text>
      </Box>

      <div className="resource-list-container">
        <Box
          borderColor="border"
          borderWidth="025"
          borderStyle="solid"
          borderRadius="200"
        >
          <ResourceList
            resourceName={{ singular: "clinic", plural: "clinics" }}
            items={[
              { id: "new", name: t("clinicList.addNew") },
              ...(clinics || []),
            ]}
            renderItem={(clinic: Clinic | { id: string; name: string }) => {
              if (clinic.id === "new") {
                return (
                  <ResourceItem
                    verticalAlignment="center"
                    id={clinic.id}
                    accessibilityLabel={t("clinicList.addNew")}
                    onClick={() => navigate(ROOTS.CLINIC + "/add")}
                    media={<Avatar size="md" />}
                  >
                    <InlineStack align="space-between" blockAlign="center">
                      <Text variant="bodyMd" fontWeight="bold" as="p">
                        {clinic.name}
                      </Text>
                      <Box>
                        <Icon source={ChevronRightIcon} />
                      </Box>
                    </InlineStack>
                  </ResourceItem>
                );
              }

              const { id, name, avatar, website, userPermissions } =
                clinic as Clinic;
              const media = (
                <Avatar
                  size="md"
                  name={name}
                  initials={avatar?.initials}
                  source={avatar?.image}
                />
              );

              return (
                <ResourceItem
                  verticalAlignment="center"
                  id={id}
                  media={media}
                  onClick={() => {
                    removeLocalStorage("__REDIRECT_PATH");
                    setLocalStorage("__LAST_VISITED_C_ID", id);
                    navigateToClinic(clinics);
                  }}
                  accessibilityLabel={`View details for ${name}`}
                >
                  <InlineStack
                    align="space-between"
                    wrap={false}
                    blockAlign="center"
                  >
                    <InlineStack align="space-between" gap="100">
                      <BlockStack gap="0">
                        <InlineStack align="start" gap="200">
                          <Text variant="bodyMd" fontWeight="bold" as="h3">
                            {name}
                          </Text>
                          <Box>
                            <Badge tone="info">{userPermissions.role}</Badge>
                          </Box>
                        </InlineStack>
                        <Box maxWidth="400px">
                          <Text as="p" tone="subdued">
                            {website}
                          </Text>
                        </Box>
                      </BlockStack>
                    </InlineStack>
                    <Box>
                      <Icon source={ChevronRightIcon} />
                    </Box>
                  </InlineStack>
                </ResourceItem>
              );
            }}
          />
        </Box>
      </div>

      <Box paddingBlockEnd={"500"}></Box>
    </CardWrapper>
  );
}

export default ClinicList;
