import React, { useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { Banner, BlockStack, Modal, Text } from "@shopify/polaris";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface DownloadRecoveryCodesModalProps {
  open: boolean;
  onClose: () => void;
}

const DownloadRecoveryCodesModal: React.FC<DownloadRecoveryCodesModalProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { GET } = useApiRequest();
  const [downloadCase, setDownloadCase] = useState(false);
  const [copyCase, setCopyCase] = useState(false);

  const {
    data: recoveryCodes,
    isFetching: isLoading,
    refetch,
  } = useQuery({
    queryKey: ["recovery-codes"],
    queryFn: () => GET("/api/user/me/two-factor/recovery-codes"),
    enabled: false,
  });

  const downloadOrCopy = async (copyMode = false) => {
    try {
      let codes = recoveryCodes;
      if (!codes) {
        const { data } = await refetch();
        codes = data;
      }
      setDownloadCase(false);
      setCopyCase(false);
      if (copyMode) {
        navigator.clipboard.writeText(codes as string);
      } else {
        downloadRecoveryCodes(codes as string);
      }
    } catch (error) {
      setDownloadCase(false);
      setCopyCase(false);
    }
  };

  const handleDownloadCodes = async () => {
    setDownloadCase(true);
    downloadOrCopy(false);
  };

  const handleCopyCodes = async () => {
    setCopyCase(true);
    downloadOrCopy(true);
  };

  const downloadRecoveryCodes = (codes: string) => {
    const blob = new Blob([codes], { type: "text/plain;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "recovery-codes.txt";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("twoStepAuth.downloadRecoveryCodesTitle")}
      primaryAction={{
        content: t("twoStepAuth.downloadRecoveryCodesButton"),
        onAction: handleDownloadCodes,
        loading: isLoading && downloadCase,
      }}
      secondaryActions={[
        {
          content: t("common.copyCodes"),
          onAction: handleCopyCodes,
          loading: isLoading && copyCase,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap="500">
          <Banner title={t("twoStepAuth.setupComplete")} tone="success" />
          <Text as="p" tone="subdued">
            {t("twoStepAuth.recoveryCodesDescription")}
          </Text>
          <BlockStack gap={"200"}>
            <Text as="h2" variant="headingSm">
              {t("twoStepAuth.saveCodesTitle")}
            </Text>
            <Text as="p">{t("twoStepAuth.saveRecoveryCodes")}</Text>
          </BlockStack>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default DownloadRecoveryCodesModal;
