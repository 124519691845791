import TableSkeleton from "./TableSkeleton";
import TabsSkeleton from "./TabsSkeleton";
import { Card } from "@shopify/polaris";

function TabsTableSkeleton() {
  return (
    <Card roundedAbove="sm" padding={"0"}>
      <TabsSkeleton />
      <TableSkeleton />
    </Card>
  );
}

export default TabsTableSkeleton;
