import { SudoUser, User } from "@/types/user";
import { Box, Banner } from "@shopify/polaris";
import TranslateLink from "./TranslateLink";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store";
import { setShowSudoBanner } from "@/reducers/appSlice";

function SudoBanner({
  user,
  sudoUser,
}: {
  user: User | undefined;
  sudoUser: SudoUser;
}) {
  const dispatch = useDispatch();

  const { showSudoBanner } = useSelector((state: RootState) => state.app);

  return sudoUser && showSudoBanner ? (
    <Box paddingBlockEnd={"200"}>
      <Banner
        title={`Elevated Access!`}
        tone={"warning"}
        onDismiss={() => dispatch(setShowSudoBanner(false))}
      >
        <TranslateLink
          text={`You are currently accessing **${user?.firstName} ${user?.lastName}'s** account (*${user?.email}*) as **${sudoUser?.sudo?.firstName}  ${sudoUser?.sudo?.lastName}** (*${sudoUser?.sudo?.email}*).`}
        ></TranslateLink>
      </Banner>
    </Box>
  ) : (
    ""
  );
}

export default SudoBanner;
