import useApiRequest from "./useApiRequest";
import { ErrorResponse } from "@/types/commonTypes";
import { PatientDetailResponse } from "@/clinic/pages/patient/types";
import { useQuery } from "@tanstack/react-query";

const usePatientDetails = (patientId: string) => {
  const { GET } = useApiRequest();

  const fetchPatientDetails = async (): Promise<PatientDetailResponse> => {
    return GET<PatientDetailResponse>(`/api/patients/${patientId}`);
  };

  const { data, isLoading, error } = useQuery<
    PatientDetailResponse,
    ErrorResponse
  >({
    queryKey: ["patientDetails", patientId],
    queryFn: fetchPatientDetails,
  });

  return {
    patient: data?.patient || null,
    isLoading,
    error,
  };
};

export default usePatientDetails;
