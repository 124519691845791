import BGRForm from "./BGRForm";
import { BGRComplianceData } from "../../types";

interface FormsProps {
  formState: BGRComplianceData;
  setFormState: React.Dispatch<React.SetStateAction<BGRComplianceData>>;
  setShowSaveBar: (show: boolean) => void;
  setValidationErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  showValidationError: boolean;
}

export const Forms: Record<string, (props: FormsProps) => JSX.Element> = {
  BGR: ({
    formState,
    setFormState,
    setShowSaveBar,
    setValidationErrors,
    showValidationError,
  }) => (
    <BGRForm
      formState={formState}
      setFormState={setFormState}
      setShowSaveBar={setShowSaveBar}
      setValidationErrors={setValidationErrors}
      showValidationError={showValidationError}
    />
  ),
};
