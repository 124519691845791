import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/store";

interface ToastState {
  message: string;
  type?: string;
  onDismiss?: () => void;
}

const initialState = {
  toasts: [] as ToastState[],
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    showToast(state, action: PayloadAction<ToastState>) {
      state.toasts.push(action.payload);
    },
    dismissToast(state) {
      state.toasts.shift();
    },
  },
});

// Actions
export const { showToast, dismissToast } = toastSlice.actions;

// Selectors
export const selectToasts = (state: RootState) => state.toast.toasts;

// Reducer
const toast = toastSlice.reducer;
export default toast;
