// localStorageUtils.ts

export const getLocalStorage = <T>(key: string): T | null => {
  const storedValue = localStorage.getItem(key);
  try {
    return storedValue ? (JSON.parse(storedValue) as T) : null;
  } catch {
    return null;
  }
};

export const setLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const removeLocalStorage = (key: string): void => {
  localStorage.removeItem(key);
};
