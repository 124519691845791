import { Clinic, Clinics } from "@/types/clinic";
import { findClinicByPath } from "@/utils/utils";
import { getLocalStorage, removeLocalStorage } from "@/utils/localStorageUtils";
import { ROOTS } from "@/utils/Constants";
import { setIsInitializingApp } from "@/reducers/appSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const useNavigateToClinic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const gotoClinicPath = (
    clinic: Clinic,
    pathToRedirect: string = pathname
  ) => {
    /**
     * if path has clinic id then goto path, 
     * but there could be another case (|| case) also where user is refreshing the page while he/she was in user's page
       or any other page other then clinic but that path should not be simply /, then also simply goto that path
    *  */
    dispatch(setIsInitializingApp(false));

    if (pathToRedirect.startsWith(ROOTS.CLINIC) || pathToRedirect === "/") {
      if (pathToRedirect.includes(clinic.id)) {
        navigate(pathToRedirect);
      } else {
        navigate(`${ROOTS.CLINIC}/${clinic.id}`);
      }
    }
  };

  /**

   * Cases in mind while implementation
   * 1. someone redirected to clinic and he/she was not logged in, in that case we will have __REDIRECT_PATH,  so want to redirect to same path after login,
   * But here after login we are first checking if clinic in the path still has permission or not
   * 
   * 2. was already loggedIn, just refresh the page, but we check the permissions and then if user has permission to the clinic then redirecting to same path
   * 
   */
  const navigateToClinic = (clinics: Clinics = []) => {
    if (clinics.length === 0) {
      return;
    }
    const redirectPathBeforeLogin = getLocalStorage(
      "__REDIRECT_PATH"
    ) as string;
    const lastClinicId = getLocalStorage("__LAST_VISITED_C_ID") as string;
    const clinicFromPath = findClinicByPath(clinics, redirectPathBeforeLogin);
    removeLocalStorage("__REDIRECT_PATH");

    if (redirectPathBeforeLogin && clinicFromPath) {
      // Case: user just got loggedIn
      gotoClinicPath(clinicFromPath, redirectPathBeforeLogin);
    } else if (clinics?.length === 1) {
      gotoClinicPath(clinics[0]);
    } else if (lastClinicId) {
      const lastNavClinic = clinics?.find((c) => c.id === lastClinicId);
      if (lastNavClinic) {
        gotoClinicPath(lastNavClinic);
      } else {
        /**
         * Case where you don't have access to last visited clinic now
         */
        dispatch(setIsInitializingApp(false));
        navigate(ROOTS.CLINIC);
      }
    } else {
      dispatch(setIsInitializingApp(false));
      navigate(ROOTS.CLINIC);
    }
  };

  return { navigateToClinic };
};

export default useNavigateToClinic;
