import { createContext, useContext } from "react";

interface PasswordVerificationContextProps {
  isPasswordVerified: boolean;
  verifyPassword: () => Promise<boolean>;
  setVerifiedTimeOnLogin: () => void;
}

export const PasswordVerificationContext = createContext<
  PasswordVerificationContextProps | undefined
>(undefined);

// eslint-disable-next-line react-refresh/only-export-components
export const usePasswordVerification = () => {
  const context = useContext(PasswordVerificationContext);
  if (!context) {
    throw new Error(
      "usePasswordVerification must be used within a PasswordVerificationProvider"
    );
  }
  return context;
};
