import React, { useEffect, useState, useRef } from 'react';
import { createComponent } from '@lit/react';
import '@khmyznikov/pwa-install';
import { Button } from '@shopify/polaris';

// Get the custom element constructor
const PWAInstallElement = customElements.get('pwa-install') as CustomElementConstructor;

interface PWAInstallElementWithDialog extends HTMLElement {
  showDialog: () => void;
}

// Create a React component from the web component
const PWAInstallComponent = createComponent({
  tagName: 'pwa-install',
  elementClass: PWAInstallElement,
  react: React,
  events: {
    'pwa-install-success-event': 'onInstallSuccess',
    'pwa-install-fail-event': 'onInstallFail',
  },
});

export function PWAInstall() {
  const [showInstallButton, setShowInstallButton] = useState(false);
  const installRef = useRef<PWAInstallElementWithDialog>(null);

  useEffect(() => {
    // Check if the app is already installed
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (!isStandalone) {
      setShowInstallButton(true);
    }
  }, []);

  const handleInstallClick = () => {
    if (installRef.current) {
      installRef.current.showDialog();
    }
  };

  if (!showInstallButton) return null;

  return (
    <>
      <PWAInstallComponent
        ref={installRef}
        manifest-path="/manifest.json"
        icon-path="/icon-square-192.png"
      />
      <Button onClick={handleInstallClick}>
        Install App
      </Button>
    </>
  );
} 