import Paper from "@mui/material/Paper";
import { appointments } from "./demo-data/appointments";
import { ViewState } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  WeekView,
  Appointments,
  AllDayPanel,
} from "@devexpress/dx-react-scheduler-material-ui";
const currentDate = "2024-08-06";

const SchedulerComponent = () => {
  return (
    <Paper>
      <Scheduler data={appointments} height={660}>
        <ViewState defaultCurrentDate={currentDate} />
        <WeekView startDayHour={8.5} endDayHour={19} />
        <Appointments />
        <AllDayPanel />
      </Scheduler>
    </Paper>
  );
};

export default SchedulerComponent;
