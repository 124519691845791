import AuthCardWrapper from "./AuthCardWrapper";
import React, { useEffect } from "react";
import useOTT from "../../hooks/useOTT";
import { Box, InlineStack, Spinner, Text } from "@shopify/polaris";
import { ROUTES } from "../../utils/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const OttExchange: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { handleOTT, setReloadOnOttExchange } = useOTT();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ott = queryParams.get("ott");
    queryParams.delete("ott");
    if (ott) {
      setReloadOnOttExchange(true);
      handleOTT(ott);
    } else {
      navigate(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setReloadOnOttExchange, handleOTT]);

  return (
    <AuthCardWrapper>
      <InlineStack align="start">
        <Text as="h1" fontWeight="bold" variant="headingLg">
          {t("auth.login.LoggingYouIn")}
        </Text>
      </InlineStack>
      <InlineStack align="center">
        <Box padding={"1600"}>
          <Spinner />
        </Box>
      </InlineStack>
    </AuthCardWrapper>
  );
};

export default OttExchange;
