import {
  BlockStack,
  Card,
  Checkbox,
  Autocomplete,
  Select,
  Text,
  Tag,
  ContextualSaveBar,
  InlineStack,
  Box,
  SkeletonBodyText,
} from "@shopify/polaris";
import { useState, useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import TranslateLink from "@/common/TranslateLink";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AutoJoinSettings, Role } from "../types";
import useApiRequest from "@/hooks/useApiRequest";
import { useParams } from "react-router-dom";

interface AutoEnrollSettingsCardProps {
  autoEnrollSettings: AutoJoinSettings | undefined;
  allowedDomains: string[] | undefined;
  roles: Role[];
  isLoading: boolean;
}

const AutoEnrollSettingsCard: React.FC<AutoEnrollSettingsCardProps> = ({
  autoEnrollSettings,
  allowedDomains,
  roles,
  isLoading,
}) => {
  const { t } = useTranslation();
  const { PUT } = useApiRequest();
  const queryClient = useQueryClient();
  const params = useParams();
  const uuid = params.clinicId;

  const [checked, setChecked] = useState<boolean>(
    autoEnrollSettings?.active ?? false
  );
  const [selectedRole, setSelectedRole] = useState<string>(
    autoEnrollSettings?.roleId?.toString() ?? ""
  );
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    autoEnrollSettings?.domains ?? []
  );
  const [inputValue, setInputValue] = useState<string>("");

  const allDomains = useMemo(() => {
    return (
      allowedDomains?.map((domain) => ({ value: domain, label: domain })) ?? []
    );
  }, [allowedDomains]);

  const [options, setOptions] = useState(allDomains);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  useEffect(() => {
    setChecked(autoEnrollSettings?.active || false);
    setSelectedRole(autoEnrollSettings?.roleId?.toString() || "");
    setSelectedOptions(autoEnrollSettings?.domains || []);
  }, [autoEnrollSettings]);

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (value === "") {
        setOptions(allDomains);
        return;
      }

      const filterRegex = new RegExp(value, "i");
      const resultOptions = allDomains.filter((option) =>
        option.label?.match(filterRegex)
      );

      setOptions(resultOptions);
    },
    [allDomains]
  );

  const removeTag = useCallback(
    (tag: string) => () => {
      const options = [...selectedOptions];
      options.splice(options.indexOf(tag), 1);
      setSelectedOptions(options);
    },
    [selectedOptions]
  );

  const verticalContentMarkup =
    selectedOptions.length > 0 ? (
      <InlineStack gap="200">
        {selectedOptions.map((option) => (
          <Tag
            key={`option${option}`}
            onRemove={() => {
              removeTag(option)();
              handleAnyChange();
            }}
            disabled={!checked}
          >
            {option}
          </Tag>
        ))}
      </InlineStack>
    ) : null;

  const textField = (
    <Autocomplete.TextField
      onChange={updateText}
      label={t("membersAndPermissions.autoEnrollSettings.domainLabel")}
      value={inputValue}
      placeholder={t(
        "membersAndPermissions.autoEnrollSettings.searchPlaceholder"
      )}
      autoComplete="off"
      disabled={!checked}
    />
  );

  const handleRoleChange = useCallback(
    (value: string) => setSelectedRole(value),
    []
  );

  const roleOptions = roles.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));

  const mutation = useMutation({
    mutationFn: (updatedSettings: AutoJoinSettings) =>
      PUT(`/api/healthcare-companies/${uuid}/team/settings`, {
        autoJoin: updatedSettings,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["autoEnrollSettings", uuid],
      });
      setIsDirty(false);
    },
  });

  const handleSave = useCallback(() => {
    const updatedSettings: AutoJoinSettings = {
      active: checked,
      roleId: parseInt(selectedRole, 10),
      domains: selectedOptions,
    };

    mutation.mutate(updatedSettings);
  }, [checked, selectedRole, selectedOptions, mutation]);

  const handleDiscard = useCallback(() => {
    setChecked(autoEnrollSettings?.active || false);
    setSelectedRole(autoEnrollSettings?.roleId?.toString() || "");
    setSelectedOptions(autoEnrollSettings?.domains || []);
    setIsDirty(false);
  }, [autoEnrollSettings]);

  const handleAnyChange = useCallback(() => {
    setIsDirty(true);
  }, []);

  return (
    <>
      {isDirty && (
        <ContextualSaveBar
          message={t("common.unsavedChanges")}
          saveAction={{
            onAction: handleSave,
            loading: mutation.isPending,
          }}
          discardAction={{
            onAction: handleDiscard,
          }}
        />
      )}
      <Card>
        <BlockStack gap={"200"}>
          <Text as="h2" variant="headingLg">
            {t("membersAndPermissions.autoEnrollSettings.title")}
          </Text>
          <TranslateLink
            text={t("membersAndPermissions.autoEnrollSettings.description")}
          />
          {isLoading ? (
            <SkeletonBodyText lines={5} />
          ) : (
            <>
              <Checkbox
                label={t(
                  "membersAndPermissions.autoEnrollSettings.joinAutomatically"
                )}
                checked={checked}
                onChange={(newChecked) => {
                  setChecked(newChecked);
                  handleAnyChange();
                }}
                helpText={
                  <TranslateLink
                    text={t(
                      "membersAndPermissions.autoEnrollSettings.joinAutomaticallyDescription",
                      {
                        domains:
                          selectedOptions.length > 0
                            ? selectedOptions.join(", ")
                            : t(
                                "membersAndPermissions.autoEnrollSettings.domainLabel"
                              ),
                        role: roleOptions.find((r) => r.value === selectedRole)
                          ?.label,
                      }
                    )}
                  />
                }
              />

              <Box paddingBlock={"200"}>
                <BlockStack gap={"200"}>
                  <Autocomplete
                    allowMultiple
                    textField={textField}
                    selected={selectedOptions}
                    options={options}
                    onSelect={(newSelected) => {
                      setSelectedOptions(newSelected);
                      handleAnyChange();
                    }}
                  />
                  {verticalContentMarkup}
                  <Text as="p" variant="bodyMd" tone="subdued">
                    {t(
                      "membersAndPermissions.autoEnrollSettings.domainDescription"
                    )}
                  </Text>
                </BlockStack>
              </Box>
              <Select
                label={t("membersAndPermissions.autoEnrollSettings.roleLabel")}
                options={roleOptions}
                onChange={(value) => {
                  handleRoleChange(value);
                  handleAnyChange();
                }}
                value={selectedRole}
                disabled={!checked}
                helpText={t(
                  "membersAndPermissions.autoEnrollSettings.roleDescription"
                )}
              />
            </>
          )}
        </BlockStack>
      </Card>
    </>
  );
};

export default AutoEnrollSettingsCard;
