import { Doctor } from "./types";
import { IndexFiltersProps } from "@shopify/polaris";
import { TFunction } from "i18next";
type Tone =
  | "base"
  | "inherit"
  | "subdued"
  | "caution"
  | "warning"
  | "critical"
  | "interactive"
  | "info"
  | "success"
  | "primary"
  | "emphasis"
  | "magic"
  | "textCaution"
  | "textWarning"
  | "textCritical"
  | "textInfo"
  | "textSuccess"
  | "textPrimary"
  | "textMagic";

export const createTabs = (
  t: TFunction,
  patientsLength: number,
  doctors: Doctor[]
) => [
  {
    content: `${t("patients.all")}(${patientsLength})`,
    id: "all",
    index: 0,
  },
  { content: t("patients.last30Days"), id: "last30Days", index: 1 },
  { content: t("patients.vip"), id: "vip", index: 2 },
  { content: t("patients.implants"), id: "implants", index: 3 },
  ...doctors.map((doctor, inx) => ({
    content: doctor.name,
    id: doctor.id,
    index: 4 + inx,
  })),
];

const VALID_TONES: Tone[] = [
  "base",
  "inherit",
  "subdued",
  "caution",
  "warning",
  "critical",
  "interactive",
  "info",
  "success",
  "primary",
  "emphasis",
  "magic",
  "textCaution",
  "textWarning",
  "textCritical",
  "textInfo",
  "textSuccess",
  "textPrimary",
  "textMagic",
];

export function getToneOrUndefined(value: unknown): Tone | undefined {
  return VALID_TONES.includes(value as Tone) ? (value as Tone) : undefined;
}

export const sortOptions = (t: TFunction): IndexFiltersProps["sortOptions"] => [
  {
    label: t("patients.columns.name"),
    value: "name asc" as const,
    directionLabel: "A-Z",
  },
  {
    label: t("patients.columns.name"),
    value: "name desc" as const,
    directionLabel: "Z-A",
  },
  {
    label: t("patients.columns.age"),
    value: "age asc" as const,
    directionLabel: "Ascending",
  },
  {
    label: t("patients.columns.age"),
    value: "age desc" as const,
    directionLabel: "Descending",
  },
  {
    label: t("patients.columns.dateOfBirth"),
    value: "dob asc" as const,
    directionLabel: "Ascending",
  },
  {
    label: t("patients.columns.dateOfBirth"),
    value: "dob desc" as const,
    directionLabel: "Descending",
  },
];
