import { PatientsExportResponse } from "@/clinic/pages/patient/types";
import { useQuery } from "@tanstack/react-query";
import useApiRequest from "./useApiRequest";

interface UsePatientParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appliedFilters?: Record<string, any>;
  columns?: string[];
}

export default function usePatientsExport(
  clinicId?: string,
  { appliedFilters = {}, columns = [] }: UsePatientParams = {
    appliedFilters: {},
    columns: [],
  }
) {
  const { GET } = useApiRequest();

  return useQuery({
    queryKey: ["patients-export", clinicId, appliedFilters, columns],
    queryFn: async () => {
      const params = new URLSearchParams();

      Object.entries(appliedFilters).forEach(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          params.append(key, String(value));
        }
      });

      if (columns.length > 0) {
        params.append("fields", columns.join(","));
      }

      return GET<PatientsExportResponse>(
        `/api/healthcare-companies/${clinicId}/patients/export?${params.toString()}`
      );
    },
    enabled: false,
  });
}
