import { Clinic } from "@/types/clinic";
import { Tone } from "@shopify/polaris/build/ts/src/components/Badge";
export const formatDate = (date: string, locale = "en-US") => {
  const l = locale == "en" ? "en-US" : locale;
  return date ? new Intl.DateTimeFormat(l).format(new Date(date)) : "";
};

/**
 * Extracts the clinic ID from the provided path and finds the corresponding clinic from the list of clinics.
 *
 * @param clinics - An array of clinics to search from.
 * @param path - The path from which to extract the clinic ID.
 * @returns The clinic that matches the extracted clinic ID, or null if not found.
 */
export const findClinicByPath = (
  clinics: Clinic[],
  path?: string
): Clinic | null => {
  // Match the clinic ID pattern in the path
  const clinicIdMatch = path?.match(/\/c\/([a-f0-9-]{36})\//);
  if (!clinicIdMatch) {
    return null;
  }

  // Extract the clinic ID
  const clinicId = clinicIdMatch[1];
  // Find the clinic in the array by ID
  return clinics.find((clinic) => clinic.id === clinicId) || null;
};

export const getBadgeTone = (status: string): Tone | undefined => {
  switch (status?.toLocaleLowerCase()) {
    case "active":
    case "accepted":
      return "success";
    case "expired":
      return "attention";
    default:
      return undefined;
  }
};
