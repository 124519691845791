import { useSelector } from "react-redux";
import useApiRequest from "./useApiRequest";
import { useQuery } from "@tanstack/react-query";
import { RootState } from "@/store";
import { useLocation, useSearchParams } from "react-router-dom";
import { ROUTES } from "@/utils/Constants";
export type AfterLoginTaskType =
  | {
      next: string;
    }
  | undefined;
export const useAfterLoginTask = (enabled: boolean, isSudoSession: boolean) => {
  const { GET } = useApiRequest();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const hasInviteToken = token && location.pathname?.startsWith(ROUTES.JOIN);

  const skip =
    useSelector((state: RootState) => state.auth.skip_activation_check) ||
    hasInviteToken;

  const fetchAfterLoginTask = async (): Promise<AfterLoginTaskType> => {
    if (hasInviteToken || isSudoSession) {
      return { next: "" };
    } else {
      const response: AfterLoginTaskType = await GET(
        `/api/user/me/activation-check${skip ? `?skip=${skip}` : ""}`
      );
      return response;
    }
  };

  return useQuery<AfterLoginTaskType, Error>({
    queryKey: ["activation-check"],
    queryFn: fetchAfterLoginTask,
    enabled,
  });
};
