import React from "react";
import { Button, ButtonProps } from "@shopify/polaris";
import { usePasswordVerification } from "./PasswordVerificationContext";
import { useTranslation } from "react-i18next";

interface PasswordProtectedButtonProps {
  onVerified?: (isVerified: boolean) => void;
  label?: string;
  variant?: ButtonProps["variant"];
  isLoading?: boolean;
}

const PasswordProtectedButton: React.FC<PasswordProtectedButtonProps> = ({
  onVerified,
  label,
  variant = "plain",
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const { verifyPassword } = usePasswordVerification();

  const handleChangeClick = async () => {
    try {
      const isVerified = await verifyPassword();
      onVerified && onVerified(isVerified);
    } catch (error) {
      console.error("Password verification failed:", error);
      // Handle error case if needed, e.g., show a message to the user
    }
  };

  return (
    <Button onClick={handleChangeClick} variant={variant} loading={isLoading}>
      {label ?? t("common.change")}
    </Button>
  );
};

export default PasswordProtectedButton;
