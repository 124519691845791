import React, { useState } from "react";
import {
  Card,
  IndexTable,
  Button,
  Text,
  BlockStack,
  Loading,
  Box,
  InlineStack,
} from "@shopify/polaris";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useTranslation } from "react-i18next";
import TableSkeleton from "@/common/Skeletons/TableSkeleton";
import { Organization } from "../types";
import { CheckCircleIcon } from "@shopify/polaris-icons";

const JoinOrganizationsCard: React.FC = () => {
  const { t } = useTranslation();
  const { GET, POST } = useApiRequest();
  const queryClient = useQueryClient();

  const [joiningCompanyId, setJoiningCompanyId] = useState<string | null>(null);

  const { data: organizations = [], isLoading } = useQuery<Organization[]>({
    queryKey: ["join-organizations"],
    queryFn: () => GET("/api/user/me/teams/join-list"),
  });

  const joinMutation = useMutation({
    mutationFn: (hccId: string) => {
      setJoiningCompanyId(hccId);
      return POST<{ message: string }>("/api/user/me/teams/join", { hccId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
      queryClient.invalidateQueries({ queryKey: ["join-organizations"] });
    },
    onError: () => {
      setJoiningCompanyId(null);
    },
  });

  return (
    <Card padding={"0"}>
      {isLoading && <Loading />}
      <BlockStack gap="400">
        <Box padding={"400"} paddingBlockEnd={"0"}>
          <BlockStack gap="200">
            <Text as="h1" variant="headingMd">
              {t("teamsAccess.joinOrganizations.title")}
            </Text>
            <Text variant="bodyMd" as="p">
              {t("teamsAccess.joinOrganizations.description")}
            </Text>
          </BlockStack>
        </Box>
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <IndexTable
            resourceName={{
              singular: t("common.organization"),
              plural: t("common.organizations"),
            }}
            itemCount={organizations.length}
            headings={[
              { title: t("common.company") },
              { title: t("common.role") },
              { title: t("common.domainMatch") },
              { title: "" },
            ]}
            selectable={false}
          >
            {organizations.map((org, index) => {
              return (
                <IndexTable.Row
                  id={org.company.id}
                  key={org.company.id}
                  position={index}
                >
                  <IndexTable.Cell>{org.company.name}</IndexTable.Cell>
                  <IndexTable.Cell>{org.role}</IndexTable.Cell>
                  <IndexTable.Cell>{org.domains.join(", ")}</IndexTable.Cell>
                  <IndexTable.Cell>
                    <InlineStack align="end">
                      <Button
                        variant="tertiary"
                        onClick={() => joinMutation.mutate(org.company.id)}
                        loading={joiningCompanyId === org.company.id}
                        size="slim"
                        icon={CheckCircleIcon}
                      >
                        {t("common.join")}
                      </Button>
                    </InlineStack>
                  </IndexTable.Cell>
                </IndexTable.Row>
              );
            })}
          </IndexTable>
        )}
      </BlockStack>
    </Card>
  );
};

export default JoinOrganizationsCard;
