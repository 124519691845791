import { useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "@/types/user";
import { useTranslation } from "react-i18next";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);

type UseFormattedDateProps = {
  date: string;
};

const useFormattedDate = ({ date }: UseFormattedDateProps) => {
  const queryClient = useQueryClient();
  const { i18n } = useTranslation();
  const user = queryClient.getQueryData<User>(["user"]);

  const locale = i18n.language;
  const userTimezone = user?.timezone?.id ?? "UTC";

  dayjs.locale(locale);

  return useMemo(() => {
    const formattedDate = dayjs.utc(date).tz(userTimezone).format("LLL");
    const fromNow = dayjs.utc(date).tz(userTimezone).fromNow();

    return {
      formattedDate,
      fromNow,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, userTimezone, locale]);
};

export default useFormattedDate;
