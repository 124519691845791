import useApiRequest from "./useApiRequest";
import { useQuery } from "@tanstack/react-query";
import { User } from "@/types/user";

export const useUser = (enabled: boolean) => {
  const fetchUser = async (): Promise<User> => {
    const userData: User = await GET("/api/user/me");
    return userData;
  };
  const { GET } = useApiRequest();

  const { data, error, isLoading, isFetching, isError, refetch } = useQuery<
    User,
    Error
  >({
    queryKey: ["user"],
    queryFn: fetchUser,
    enabled,
  });

  return {
    user: data,
    error,
    isLoading,
    isFetching,
    isError,
    refetchUser: refetch,
  };
};
