import Cookies from "js-cookie";
import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "@/utils/Constants";
import { setLocalStorage } from "@/utils/localStorageUtils";

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const accessToken = Cookies.get("access_token");
  const location = useLocation();
  if (!accessToken || accessToken === "undefined") {
    /**
     * if there is no token and user tries to access some protected routes
     * then redirect to login and save path in storage
     */
    if (location.pathname !== "/") {
      setLocalStorage("__REDIRECT_PATH", location.pathname);
    }
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
