import SettingsPageWrapper from "./SettingsPageWrapper";
import React, { useEffect, useState } from "react";
import {
  BlockStack,
  Box,
  Card,
  ContextualSaveBar,
  FormLayout,
  Layout,
  Loading,
  Page,
  TextField,
  Select,
  Text,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useApiRequest from "@/hooks/useApiRequest";
import CompanyProfilePicture from "./CompanyProfilePicture";
import { useParams } from "react-router-dom";
import { Company, Country, Timezone } from "../types";

const CompanyDetails: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { GET, PUT } = useApiRequest();
  const params = useParams();
  const companyId = params.clinicId;

  const [name, setName] = useState<string>("");
  const [website, setWebsite] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [customerPhoneCountryCode, setCustomerPhoneCountryCode] =
    useState<string>("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState<string>("");
  const [timezone, setTimezone] = useState<string>("");
  const [taxNumber, setTaxNumber] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [apartment, setApartment] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [provinceCode, setProvinceCode] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [countries, setCountries] = useState<Country[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [errors, setErrors] = useState<Record<string, boolean>>({});

  const { data: companyData, isLoading: isCompanyLoading } = useQuery<Company>({
    queryKey: ["healthcare-companies", companyId],
    queryFn: () => GET(`/api/healthcare-companies/${companyId}`),
  });

  const { data: timezones = [], isLoading: isTimezonesLoading } = useQuery<
    Timezone[]
  >({
    queryKey: ["timezones"],
    queryFn: () => GET("/api/metadata/timezones"),
  });

  const { data: countryData = [] } = useQuery<Country[]>({
    queryKey: ["countries"],
    queryFn: () => GET("/api/metadata/countries"),
  });

  useEffect(() => {
    if (countryData.length > 0) {
      setCountries(countryData);
    }
  }, [countryData]);

  useEffect(() => {
    if (companyData) {
      setName(companyData.name);
      setWebsite(companyData.website);
      setCustomerEmail(companyData.customerEmail);
      setCustomerPhoneCountryCode(companyData.customerPhoneCountryCode);
      setCustomerPhoneNumber(companyData.customerPhone);
      setTimezone(companyData.timezone?.id);
      setTaxNumber(companyData.taxNumber);
      setAddress(companyData.address?.address1);
      setApartment(companyData.address?.address2);
      setCity(companyData.address?.city);
      setProvinceCode(companyData.address?.provinceCode);
      setCountry(companyData.address?.countryISO3);
    }
  }, [companyData]);

  const handleInputChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    value: string
  ) => {
    setter(value);
    setHasUnsavedChanges(true);
  };

  const validateFields = () => {
    const newErrors: Record<string, boolean> = {};
    if (!name) newErrors.name = true;
    if (!customerPhoneCountryCode) newErrors.customerPhoneCountryCode = true;
    if (!address) newErrors.address = true;
    if (!city) newErrors.city = true;
    if (!taxNumber) newErrors.taxNumber = true;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateCompany = useMutation({
    mutationFn: (updatedData: Record<string, string | null | undefined>) =>
      PUT(`/api/healthcare-companies/${companyId}`, updatedData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
      setHasUnsavedChanges(false);
    },
  });

  const handleDiscard = () => {
    setHasUnsavedChanges(false);
    setErrors({});
    setName(companyData?.name ?? "");
    setWebsite(companyData?.website ?? "");
    setCustomerEmail(companyData?.customerEmail ?? "");
    setCustomerPhoneCountryCode(companyData?.customerPhoneCountryCode ?? "");
    setCustomerPhoneNumber(companyData?.customerPhone ?? "");
    setTimezone(companyData?.timezone?.id ?? "");
    setTaxNumber(companyData?.taxNumber ?? "");
    setAddress(companyData?.address?.address1 ?? "");
    setApartment(companyData?.address?.address2 ?? "");
    setCity(companyData?.address?.city ?? "");
    setProvinceCode(companyData?.address?.provinceCode ?? "");
    setCountry(companyData?.address?.countryISO3 ?? "");
  };

  const handleSave = () => {
    if (!validateFields()) {
      return;
    }

    if (hasUnsavedChanges) {
      updateCompany.mutate({
        name,
        website,
        customerEmail,
        customerPhoneCountryCode,
        customerPhoneNumber,
        timezone,
        taxNumber,
        address1: address,
        address2: apartment,
        city,
        provinceCode: provinceCode,
        countryISO3: country,
        taxType: "EU_VAT",
      });
    }
  };

  const renderSkeleton = () => {
    return (
      <BlockStack gap={"400"}>
        <BlockStack gap={"200"}>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={2} />
        </BlockStack>
        <BlockStack gap={"200"}>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={2} />
        </BlockStack>
        <BlockStack gap={"200"}>
          <SkeletonDisplayText size="small" />
          <SkeletonBodyText lines={2} />
        </BlockStack>
      </BlockStack>
    );
  };

  return (
    <SettingsPageWrapper>
      <Page title={t("companyDetails.details")} narrowWidth>
        {isCompanyLoading ? <Loading /> : null}
        {hasUnsavedChanges && (
          <ContextualSaveBar
            message={t("companyDetails.unsavedChanges")}
            saveAction={{
              onAction: handleSave,
              loading: updateCompany.isPending,
            }}
            discardAction={{
              onAction: handleDiscard,
            }}
          />
        )}
        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap="400">
                <BlockStack gap="200">
                  <Text as="h1" variant="headingMd">
                    {t("companyDetails.companyLogo")}
                  </Text>

                  <CompanyProfilePicture
                    loading={isTimezonesLoading || isCompanyLoading}
                    logoUrl={companyData?.avatar?.image ?? ""}
                    companyId={companyId ?? ""}
                  />
                </BlockStack>

                <FormLayout>
                  <BlockStack gap="100">
                    <Text as="h1" variant="headingMd">
                      {t("companyDetails.details")}
                    </Text>
                    <Text as="p" tone="subdued">
                      {t("companyDetails.detailsDescription")}
                    </Text>
                  </BlockStack>
                  {isTimezonesLoading || isCompanyLoading ? (
                    renderSkeleton()
                  ) : (
                    <>
                      <FormLayout.Group>
                        <TextField
                          label={t("companyDetails.legalBusinessName")}
                          value={name}
                          onChange={(value) =>
                            handleInputChange(setName, value)
                          }
                          autoComplete="off"
                          requiredIndicator
                          error={
                            errors.name &&
                            t("common.requiredField", {
                              field_name: t("companyDetails.legalBusinessName"),
                            })
                          }
                        />
                        <TextField
                          label={t("companyDetails.companyWebsite")}
                          value={website}
                          onChange={(value) =>
                            handleInputChange(setWebsite, value)
                          }
                          autoComplete="off"
                        />
                      </FormLayout.Group>

                      <FormLayout.Group>
                        <TextField
                          label={t("companyDetails.companyPhone")}
                          value={customerPhoneNumber}
                          onChange={(value) =>
                            handleInputChange(setCustomerPhoneNumber, value)
                          }
                          autoComplete="tel"
                          prefix={customerPhoneCountryCode}
                          connectedLeft={
                            <Select
                              label={t("companyDetails.countryCode")}
                              value={customerPhoneCountryCode}
                              onChange={(value) =>
                                handleInputChange(
                                  setCustomerPhoneCountryCode,
                                  value
                                )
                              }
                              options={countries.map((c) => ({
                                label: `${c.name}`,
                                value: c.phoneCountryCode,
                              }))}
                              labelHidden
                            />
                          }
                        />

                        <TextField
                          label={t("companyDetails.companyEmail")}
                          value={customerEmail}
                          onChange={(value) =>
                            handleInputChange(setCustomerEmail, value)
                          }
                          autoComplete="email"
                        />
                      </FormLayout.Group>

                      <Select
                        label={t("companyDetails.timezone")}
                        options={timezones.map((t) => ({
                          value: t.id,
                          label: t.label,
                        }))}
                        value={timezone}
                        onChange={(value) =>
                          handleInputChange(setTimezone, value)
                        }
                        helpText={t("companyDetails.timezoneFooter")}
                      />
                    </>
                  )}
                </FormLayout>
              </BlockStack>
            </Card>
          </Layout.Section>
          <Layout.Section>
            <Card>
              <BlockStack gap="400">
                <BlockStack gap="200">
                  <Text as="h1" variant="headingMd">
                    {t("companyDetails.billingInformation")}
                  </Text>

                  <Text as="p" tone="subdued">
                    {t("companyDetails.billingInformationDescription")}
                  </Text>
                </BlockStack>
                {isTimezonesLoading || isCompanyLoading ? (
                  renderSkeleton()
                ) : (
                  <FormLayout>
                    <TextField
                      label={t("companyDetails.taxNumber")}
                      value={taxNumber}
                      onChange={(value) =>
                        handleInputChange(setTaxNumber, value)
                      }
                      autoComplete="off"
                      requiredIndicator
                      error={
                        errors.taxNumber &&
                        t("common.requiredField", {
                          field_name: t("companyDetails.taxNumber"),
                        })
                      }
                    />

                    <TextField
                      label={t("companyDetails.address")}
                      value={address}
                      onChange={(value) => handleInputChange(setAddress, value)}
                      autoComplete="off"
                      requiredIndicator
                      error={
                        errors.address &&
                        t("common.requiredField", {
                          field_name: t("companyDetails.address"),
                        })
                      }
                    />

                    <TextField
                      label={t("companyDetails.apartment")}
                      value={apartment}
                      onChange={(value) =>
                        handleInputChange(setApartment, value)
                      }
                      autoComplete="off"
                    />

                    <FormLayout.Group>
                      <TextField
                        label={t("companyDetails.city")}
                        value={city}
                        onChange={(value) => handleInputChange(setCity, value)}
                        autoComplete="off"
                        requiredIndicator
                        error={
                          errors.city &&
                          t("common.requiredField", {
                            field_name: t("companyDetails.city"),
                          })
                        }
                      />
                      <TextField
                        label={t("companyDetails.postalCode")}
                        value={provinceCode}
                        onChange={(value) =>
                          handleInputChange(setProvinceCode, value)
                        }
                        autoComplete="off"
                      />
                    </FormLayout.Group>

                    <Select
                      label={t("companyDetails.country")}
                      value={country}
                      options={countries.map((c) => ({
                        label: `${c.name}`,
                        value: c.iso3,
                      }))}
                      onChange={(value) => handleInputChange(setCountry, value)}
                    />
                  </FormLayout>
                )}
              </BlockStack>
            </Card>
          </Layout.Section>
        </Layout>
        <Box paddingBlock="200" />
      </Page>
    </SettingsPageWrapper>
  );
};

export default CompanyDetails;
