import { CLINIC_ROUTES, ROOTS, SETTINGS_ROUTES } from "@/utils/Constants";
import { TFunction } from "i18next";

import {
  BillIcon,
  CalendarTimeIcon,
  ChartVerticalFilledIcon,
  ContractIcon,
  CreditCardIcon,
  CursorIcon,
  DiscountFilledIcon,
  GlobeIcon,
  HomeIcon,
  LocationIcon,
  OrganizationIcon,
  PersonFilledIcon,
  PlanIcon,
  TeamIcon,
} from "@shopify/polaris-icons";

interface SubNavigationItem {
  label: string;
  url: string;
  selected: boolean;
  adminOnly?: boolean;
}

interface ClinicNavigationItem {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  url: string;
  selected: boolean;
  badge?: React.ReactNode; // Badge can contain React nodes
  subNavigationItems?: SubNavigationItem[]; // Added for handling sub-navigation items
  adminOnly?: boolean;
}
export const getClinicNavigationItems = (
  t: TFunction,
  selectedClinicId: string,
  locationPathname: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showPerksNew?: any,
  isMobile?: boolean
): ClinicNavigationItem[] => [
  {
    label: t("clinicNavigation.home"),
    icon: HomeIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}`,
    selected: locationPathname === `${ROOTS.CLINIC}/${selectedClinicId}`,
  },
  {
    label: t("clinicNavigation.patients"),
    icon: PersonFilledIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}`,
    selected: locationPathname.includes(
      `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}`
    ),
    subNavigationItems: isMobile
      ? [
          {
            label: t("clinicNavigation.patients"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}`,
            selected:
              locationPathname ===
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}`,
          },
          {
            label: t("clinicNavigation.tags"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/tags`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/tags`
            ),
          },
          {
            label: t("clinicNavigation.procedures"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/procedures`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/procedures`
            ),
          },
          {
            label: t("clinicNavigation.treatmentPlans"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/treatment-plans`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/treatment-plans`
            ),
          },
          {
            label: t("clinicNavigation.dataManagement"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/data-management`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/data-management`
            ),
            adminOnly: true,
          },
        ]
      : [
          {
            label: t("clinicNavigation.tags"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/tags`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/tags`
            ),
          },
          {
            label: t("clinicNavigation.procedures"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/procedures`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/procedures`
            ),
          },
          {
            label: t("clinicNavigation.treatmentPlans"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/treatment-plans`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/treatment-plans`
            ),
          },
          {
            label: t("clinicNavigation.dataManagement"),
            url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/data-management`,
            selected: locationPathname.endsWith(
              `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PATIENTS}/data-management`
            ),
            adminOnly: true,
          },
        ],
  },
  {
    label: t("clinicNavigation.appointments"),
    icon: CalendarTimeIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.APPOINTMENTS}`,
    selected:
      locationPathname ===
      `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.APPOINTMENTS}`,
  },
  {
    label: t("clinicNavigation.statistics"),
    icon: ChartVerticalFilledIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.STATISTICS}`,
    selected:
      locationPathname ===
      `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.STATISTICS}`,
  },
  {
    label: t("clinicNavigation.miniSite"),
    icon: CursorIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.MINI_SITE}`,
    selected:
      locationPathname ===
      `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.MINI_SITE}`,
  },
  {
    label: t("clinicNavigation.payments"),
    icon: CreditCardIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PAYMENTS}`,
    selected: locationPathname.endsWith(
      `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PAYMENTS}`
    ),
  },
  {
    label: t("clinicNavigation.documents"),
    icon: ContractIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.DOCUMENTS}`,
    selected:
      locationPathname ===
      `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.DOCUMENTS}`,
  },
  {
    label: t("clinicNavigation.perks"),
    icon: DiscountFilledIcon,
    url: `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PERKS}`,
    selected: locationPathname.endsWith(
      `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.PERKS}`
    ),
    badge: showPerksNew,
  },
];

export const getSettingsNavigationItems = (
  t: TFunction,
  selectedClinicId: string,
  locationPathname: string
): ClinicNavigationItem[] => {
  const SETTING_BASE = `${ROOTS.CLINIC}/${selectedClinicId}/${CLINIC_ROUTES.SETTINGS}`;
  return [
    {
      label: t("settingsNavigation.details"),
      icon: OrganizationIcon,
      url: `${SETTING_BASE}/${SETTINGS_ROUTES.DETAILS}`,
      selected: locationPathname.includes(
        `${SETTING_BASE}/${SETTINGS_ROUTES.DETAILS}`
      ),
    },
    {
      label: t("settingsNavigation.locations"),
      icon: LocationIcon,
      url: `${SETTING_BASE}/${SETTINGS_ROUTES.LOCATIONS}`,
      selected: locationPathname.includes(
        `${SETTING_BASE}/${SETTINGS_ROUTES.LOCATIONS}`
      ),
    },
    {
      label: t("settingsNavigation.subscription-plan"),
      icon: PlanIcon,
      url: `${SETTING_BASE}/${SETTINGS_ROUTES.SUBSCRIPTION_PLAN}`,
      selected: locationPathname.includes(
        `${SETTING_BASE}/${SETTINGS_ROUTES.SUBSCRIPTION_PLAN}`
      ),
    },
    {
      label: t("settingsNavigation.members-permissions"),
      icon: TeamIcon,
      url: `${SETTING_BASE}/${SETTINGS_ROUTES.MEMBERS_PERMISSIONS}`,
      selected: locationPathname.includes(
        `${SETTING_BASE}/${SETTINGS_ROUTES.MEMBERS_PERMISSIONS}`
      ),
    },
    {
      label: t("settingsNavigation.compliance"),
      icon: GlobeIcon,
      url: `${SETTING_BASE}/${SETTINGS_ROUTES.COMPLIANCE}`,
      selected: locationPathname.includes(
        `${SETTING_BASE}/${SETTINGS_ROUTES.COMPLIANCE}`
      ),
    },
    {
      label: t("settingsNavigation.invoices"),
      icon: BillIcon,
      url: `${SETTING_BASE}/${SETTINGS_ROUTES.INVOICES}`,
      selected: locationPathname.includes(
        `${SETTING_BASE}/${SETTINGS_ROUTES.INVOICES}`
      ),
    },
  ];
};
