import AppToast from "@/common/AppToast";
import React, { ReactNode, useState } from "react";
import {
  Box,
  Button,
  Frame,
  FullscreenBar,
  Icon,
  InlineStack,
  Navigation,
  Text,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import "../styles.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSettingsNavigationItems } from "@/clinic/utils";
import { MenuIcon, SettingsFilledIcon, XIcon } from "@shopify/polaris-icons";
import { ROOTS } from "@/utils/Constants";
interface SettingsPageWrapperProps {
  children: ReactNode;
}

const logo = {
  width: 124,
  topBarSource: "https://assets.medentic.app/logos/logotype-light.svg",
  accessibilityLabel: "Medentic",
};

const SettingsPageWrapper: React.FC<SettingsPageWrapperProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);
  const location = useLocation();
  const params = useParams();
  const selectedClinicId = params.clinicId;

  const navigate = useNavigate();
  const toggleMobileNavigation = () => {
    setShowMobileNavigation((prevState) => !prevState);
  };

  if (!selectedClinicId) {
    return null;
  }

  const closeSettings = () => {
    navigate(`${ROOTS.CLINIC}/${selectedClinicId}`);
  };

  const settingsNavigationItems = getSettingsNavigationItems(
    t,
    selectedClinicId,
    location.pathname
  );

  const navigation = (
    <Navigation location={location.pathname}>
      <Navigation.Section items={settingsNavigationItems} fill />
    </Navigation>
  );

  return (
    <div className="company-settings">
      <Frame
        logo={logo}
        navigation={navigation}
        topBar={undefined}
        showMobileNavigation={showMobileNavigation}
        onNavigationDismiss={toggleMobileNavigation}
      >
        <AppToast />

        <FullscreenBar onAction={closeSettings}>
          <Box width="100%" padding={"400"}>
            <InlineStack align="space-between" blockAlign="center">
              <Box>
                <InlineStack gap={"300"}>
                  <div className="mob_toggle_button">
                    <Button
                      variant="plain"
                      icon={MenuIcon}
                      onClick={toggleMobileNavigation}
                    />
                  </div>

                  <InlineStack gap={"100"}>
                    <Icon source={SettingsFilledIcon} />
                    <Text as="h2" variant="headingLg">
                      {t("companySettings.settingsTitle")}
                    </Text>
                  </InlineStack>
                </InlineStack>
              </Box>
              <Box paddingInlineEnd={"200"} paddingBlockStart={"025"}>
                <Button variant="plain" icon={XIcon} onClick={closeSettings} />
              </Box>
            </InlineStack>
          </Box>
        </FullscreenBar>
        {children}
      </Frame>
    </div>
  );
};

export default SettingsPageWrapper;
