import AppToast from "@/common/AppToast";
import ClinicNavigation from "@/clinic/components/ClinicNavigation";
import ClinicTopBar from "@/clinic/components/ClinicTopBar";
import React, { ReactNode, useState } from "react";
import UserNavigation from "@/user/components/UserNavigation";
import UserTopBar from "@/user/components/UserTopBar";
import { Frame } from "@shopify/polaris";
import { PageTypes } from "@/types/commonTypes";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { ROOTS } from "@/utils/Constants";
// import PatientNavigation from "@/patient/components/PatientNavigation"; // Import PatientNavigation
// import PatientTopBar from "@/patient/components/PatientTopBar"; // Import PatientTopBar

interface AppFrameProps {
  type: PageTypes;
  children: ReactNode;
}

const AppFrame: React.FC<AppFrameProps> = ({ type, children }) => {
  const [showMobileNavigation, setShowMobileNavigation] = useState(false);

  const isAppLoading = useSelector(
    (state: RootState) => state.app.isInitializingApp
  );

  const toggleMobileNavigation = () => {
    setShowMobileNavigation((prevState) => !prevState);
  };

  const logo = {
    width: 124,
    topBarSource: "https://assets.medentic.app/logos/logotype-light.svg",
    accessibilityLabel: "Medentic",
    url: ROOTS.CLINIC,
  };

  let navigation;
  let topBar;

  switch (type) {
    case PageTypes.USER:
      navigation = <UserNavigation />;
      topBar = <UserTopBar onNavigationToggle={toggleMobileNavigation} />;
      break;
    case PageTypes.CLINIC:
      navigation = <ClinicNavigation />;
      topBar = <ClinicTopBar onNavigationToggle={toggleMobileNavigation} />;
      break;
    case PageTypes.PATIENT:
      // TODO: have to make separate nav and topbar for patient
      navigation = undefined;
      topBar = <UserTopBar onNavigationToggle={toggleMobileNavigation} />;
      break;
    default:
      navigation = null;
      topBar = <UserTopBar onNavigationToggle={toggleMobileNavigation} />;
      break;
  }

  const hideTopNav =
    type === PageTypes.AUTH || type === PageTypes.UNKNOW || isAppLoading;

  return (
    <Frame
      logo={hideTopNav ? undefined : logo}
      navigation={hideTopNav ? undefined : navigation}
      topBar={hideTopNav ? undefined : topBar}
      showMobileNavigation={hideTopNav ? false : showMobileNavigation}
      onNavigationDismiss={toggleMobileNavigation}
    >
      <AppToast />
      {children}
    </Frame>
  );
};

export default AppFrame;
