import AppPageWrapper from "@/common/AppPageWrapper";
import { Box, InlineStack, Page, Text } from "@shopify/polaris";

export default function PatientDashboard() {
  return (
    <AppPageWrapper>
      <Page title={"Patient"}>
        <Box
          padding="500"
          borderColor="border-secondary"
          borderRadius="400"
          background="bg-fill-info"
        >
          <InlineStack align="space-between" blockAlign="center">
            <Text variant="headingMd" as="h1">
              Comming Soon...
            </Text>
          </InlineStack>
        </Box>
      </Page>
    </AppPageWrapper>
  );
}
