import React, { useState, useMemo } from "react";
import {
  Modal,
  TextField,
  Select,
  Checkbox,
  InlineStack,
  Button,
  Text,
  BlockStack,
  Box,
  SkeletonDisplayText,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { Option, Property, GroupData, FieldType } from "../../types";
import {
  PlusIcon,
  XIcon,
  XSmallIcon,
  DragHandleIcon,
} from "@shopify/polaris-icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useParams } from "react-router-dom";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TranslateLink from "@/common/TranslateLink";
import { PropertyTypeRenderer } from "@/common/PropertyTypeRenderer/PropertyTypeRenderer";

interface PropertiesModalProps {
  open: boolean;
  onClose: () => void;
  initialData?: Property;
}

const ItemType = "COMBOBOX_OPTION";

const DraggableOption: React.FC<{
  option: Option;
  index: number;
  disabled: boolean;
  moveOption: (fromIndex: number, toIndex: number) => void;
  onRemoveOption: (index: number) => void;
  onChange: (index: number, key: keyof Option, value: string) => void;
}> = ({ option, index, moveOption, onRemoveOption, onChange, disabled }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveOption(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))}>
      <InlineStack gap="100">
        <Button icon={DragHandleIcon} disabled />
        <Box width="44%">
          <TextField
            label=""
            autoComplete="false"
            placeholder="Label"
            value={option.label}
            onChange={(value) => onChange(index, "label", value)}
            disabled={disabled}
          />
        </Box>
        <Box width="44%">
          <TextField
            label=""
            autoComplete="false"
            placeholder="Value"
            value={option.value}
            onChange={(value) => onChange(index, "value", value)}
            disabled={disabled}
          />
        </Box>
        <Button
          icon={XIcon}
          onClick={() => onRemoveOption(index)}
          variant="plain"
          tone="critical"
          disabled={disabled}
        />
      </InlineStack>
    </div>
  );
};

const PropertiesModal: React.FC<PropertiesModalProps> = ({
  open,
  onClose,
  initialData,
}) => {
  const { t } = useTranslation();
  const { GET, POST, PUT } = useApiRequest();
  const params = useParams();
  const clinicId = params.clinicId;
  const queryClient = useQueryClient();

  const { data: groupsData = [], isLoading: isGroupsLoading } = useQuery<
    GroupData[]
  >({
    queryKey: ["patient-schema"],
    queryFn: () => GET(`/api/healthcare-companies/${clinicId}/patients/schema`),
  });

  const groups = useMemo(
    () => (groupsData.length ? groupsData[0]?.groups : []),
    [groupsData]
  );

  const groupOptions = useMemo(() => {
    return groups.map((group) => ({ label: group.label, value: group.name }));
  }, [groups]);

  const [groupId, setGroupId] = useState(
    initialData?.groupId ?? groupOptions[0]?.value ?? ""
  );
  const [label, setLabel] = useState(initialData?.label ?? "");
  const [name, setName] = useState(initialData?.name ?? "");
  const [description, setDescription] = useState(
    initialData?.description ?? ""
  );
  const [renderType, setRenderType] = useState<FieldType>(
    (initialData?.renderer?.id as FieldType) ?? "textInput"
  );

  const [customOptionsEnabled, setCustomOptionsEnabled] = useState(
    !!initialData?.customOptions
  );
  const [customOptions, setCustomOptions] = useState(
    initialData?.customOptions ?? ""
  );
  const [defaultValueEnabled, setDefaultValueEnabled] = useState(
    !!initialData?.defaultValue
  );
  const [defaultValue, setDefaultValue] = useState(
    initialData?.defaultValue ?? ""
  );
  const [hasUniqueValue, setHasUniqueValue] = useState(
    initialData?.hasUniqueValue ?? false
  );
  const [isRequired, setIsRequired] = useState(
    initialData?.isRequired ?? false
  );
  const [isShortlisted, setIsShortlisted] = useState(
    initialData?.isShortlisted ?? false
  );
  const [isInCreateForm, setIsInCreateForm] = useState(
    initialData?.isInCreateForm ?? false
  );
  const [comboboxOptions, setComboboxOptions] = useState<Option[]>(
    (initialData?.options ?? []).sort((a, b) => a.displayOrder - b.displayOrder)
  );

  const [errors, setErrors] = useState({
    groupId: "",
    label: "",
    name: "",
    description: "",
    customOptions: "",
    defaultValue: "",
  });

  const isEditing = !!initialData;
  const isReadOnly = isEditing && initialData?.isReadOnlyDefinition;

  // Reordering logic for combobox options
  const moveOption = (fromIndex: number, toIndex: number) => {
    const updatedOptions = [...comboboxOptions];
    const [movedItem] = updatedOptions.splice(fromIndex, 1);
    updatedOptions.splice(toIndex, 0, movedItem);
    updatedOptions.forEach((option, idx) => {
      option.displayOrder = idx + 1;
    });
    setComboboxOptions(updatedOptions);
  };

  const handleAddOption = () => {
    setComboboxOptions((prev) => [
      ...prev,
      { label: "", value: "", displayOrder: prev.length + 1 },
    ]);
  };

  const handleRemoveOption = (index: number) => {
    const updatedOptions = comboboxOptions.filter((_, i) => i !== index);
    updatedOptions.forEach((option, idx) => {
      option.displayOrder = idx + 1;
    });
    setComboboxOptions(updatedOptions);
  };

  const handleRemoveAll = () => {
    setComboboxOptions([]);
  };

  const handleRenderTypeChange = (selected: string) => {
    setRenderType(selected as FieldType);
  };

  const validateFields = () => {
    const newErrors = {
      groupId: !groupId ? t("propertiesModal.alert.groupRequired") : "",
      label: !label ? t("propertiesModal.alert.labelRequired") : "",
      name: !name
        ? t("common.requiredField", {
            field_name: t("propertiesModal.name"),
          })
        : "",
      description: !description
        ? t("propertiesModal.alert.descriptionRequired")
        : "",
      customOptions:
        customOptionsEnabled && !customOptions
          ? t("common.requiredField", {
              field_name: t("propertiesModal.customRendererOptions"),
            })
          : "",
      defaultValue:
        defaultValueEnabled && !defaultValue
          ? t("common.requiredField", {
              field_name: t("propertiesModal.defaultValue"),
            })
          : "",
    };
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => !error);
  };

  const handleLabelBlur = () => {
    // Auto-generate 'name' from 'label' if empty
    if (!name) {
      const generatedName = label.toLowerCase().replace(/[^a-z0-9]/g, "_");
      setName(generatedName);
    }
  };

  // Mutations
  const updateMutation = useMutation({
    mutationFn: async (property: Property) => {
      return PUT(
        `/api/healthcare-companies/${clinicId}/patients/schema/properties/${initialData?.name}`,
        property
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["patient-schema-properties"],
      });
      queryClient.invalidateQueries({ queryKey: ["patient-schema"] });
      onClose();
    },
  });

  const createMutation = useMutation({
    mutationFn: async (property: Property) => {
      return POST(
        `/api/healthcare-companies/${clinicId}/patients/schema/properties`,
        property
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["patient-schema-properties"],
      });
      queryClient.invalidateQueries({ queryKey: ["patient-schema"] });
      onClose();
    },
  });

  const handleSave = () => {
    if (!validateFields()) return;

    const property: Property = {
      id: initialData?.id ?? Date.now().toString(),
      groupId,
      label,
      name,
      description,
      renderType,
      customOptions: customOptionsEnabled ? customOptions : "",
      defaultValue: defaultValueEnabled ? defaultValue : "",
      hasUniqueValue,
      isRequired,
      isShortlisted,
      isInCreateForm,
      options: comboboxOptions,
      isMedenticDefined: initialData?.isMedenticDefined || false,
      isReadOnlyDefinition: initialData?.isReadOnlyDefinition || false,
      isHiddenDefinition: initialData?.isHiddenDefinition || false,
    };

    if (isEditing) {
      updateMutation.mutate(property);
    } else {
      createMutation.mutate(property);
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        open={open}
        onClose={onClose}
        title={
          isEditing
            ? t("propertiesModal.title.edit")
            : t("propertiesModal.title.create")
        }
        primaryAction={{
          content: isEditing ? t("common.update") : t("common.create"),
          onAction: handleSave,
          loading: createMutation.isPending || updateMutation.isPending,
        }}
        secondaryActions={[
          {
            content: t("common.cancel"),
            onAction: onClose,
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap="200">
            {isGroupsLoading ? (
              <SkeletonDisplayText />
            ) : (
              <Select
                label={t("propertiesModal.group")}
                options={groupOptions}
                value={groupId}
                onChange={setGroupId}
                helpText={t("propertiesModal.groupHelpText")}
                error={errors.groupId}
                disabled={isReadOnly}
              />
            )}

            <TextField
              autoComplete="false"
              label={t("propertiesModal.label")}
              value={label}
              onChange={setLabel}
              onBlur={handleLabelBlur}
              helpText={t("propertiesModal.labelHelpText")}
              error={errors.label}
              autoFocus
            />

            <TextField
              autoComplete="false"
              label={t("propertiesModal.name")}
              value={name}
              onChange={setName}
              helpText={t("propertiesModal.nameHelpText")}
              error={errors.name}
              disabled={isReadOnly}
            />

            <TextField
              autoComplete="false"
              label={t("propertiesModal.description")}
              value={description}
              onChange={setDescription}
              multiline={2}
              helpText={t("propertiesModal.descriptionHelpText")}
              error={errors.description}
            />

            {/* Field Type */}
            <Select
              label={t("propertiesModal.fieldType")}
              options={[
                {
                  label: t("properties.type.checkbox"),
                  value: "checkbox",
                },
                { label: t("properties.type.date"), value: "date" },
                {
                  label: t("properties.type.phoneNumber"),
                  value: "phoneNumber",
                },
                {
                  label: t("properties.type.number"),
                  value: "number",
                },
                {
                  label: t("properties.type.textInput"),
                  value: "textInput",
                },
                {
                  label: t("properties.type.textArea"),
                  value: "textArea",
                },
                { label: t("properties.type.email"), value: "email" },
                {
                  label: t("properties.type.combobox"),
                  value: "combobox",
                },
                {
                  label: t("properties.type.combeBoxMultiple"),
                  value: "combeBoxMultiple",
                },
                {
                  label: t("properties.type.dropZone"),
                  value: "dropZone",
                },
              ]}
              value={renderType}
              onChange={handleRenderTypeChange}
              helpText={t("propertiesModal.fieldTypeHelpText")}
              disabled={isReadOnly}
            />
            {renderType === "combobox" && (
              <Box
                borderColor="border"
                borderWidth="025"
                borderStyle="solid"
                padding="200"
                borderRadius="200"
              >
                <BlockStack gap="200">
                  <InlineStack align="space-between">
                    <Text as="h3">{t("propertiesModal.options")}</Text>
                    {!!comboboxOptions.length && (
                      <Button
                        onClick={handleRemoveAll}
                        icon={XSmallIcon}
                        variant="plain"
                        tone="critical"
                        disabled={isReadOnly}
                      >
                        {t("propertiesModal.removeAll")}
                      </Button>
                    )}
                  </InlineStack>
                  {comboboxOptions.map((option, index) => (
                    <DraggableOption
                      key={index}
                      option={option}
                      index={index}
                      moveOption={moveOption}
                      onRemoveOption={handleRemoveOption}
                      disabled={isReadOnly}
                      onChange={(
                        idx,
                        key: keyof Option,
                        value: Option[keyof Option]
                      ) => {
                        const updatedOptions = [...comboboxOptions];
                        updatedOptions[idx][key] = value as never;
                        setComboboxOptions(updatedOptions);
                      }}
                    />
                  ))}
                  <Box>
                    <Button
                      onClick={handleAddOption}
                      icon={PlusIcon}
                      disabled={isReadOnly}
                    >
                      {t("propertiesModal.addOption")}
                    </Button>
                  </Box>
                </BlockStack>
              </Box>
            )}

            {renderType === "combeBoxMultiple" && (
              <Box
                borderColor="border"
                borderWidth="025"
                borderStyle="solid"
                padding="200"
                borderRadius="200"
              >
                <BlockStack gap="200">
                  <InlineStack align="space-between">
                    <Text as="h3">{t("propertiesModal.optionsMultiple")}</Text>
                    {!!comboboxOptions.length && (
                      <Button
                        onClick={handleRemoveAll}
                        icon={XSmallIcon}
                        variant="plain"
                        tone="critical"
                        disabled={isReadOnly}
                      >
                        {t("propertiesModal.removeAll")}
                      </Button>
                    )}
                  </InlineStack>
                  {comboboxOptions.map((option, index) => (
                    <DraggableOption
                      key={index}
                      option={option}
                      index={index}
                      moveOption={moveOption}
                      onRemoveOption={handleRemoveOption}
                      disabled={isReadOnly}
                      onChange={(
                        idx,
                        key: keyof Option,
                        value: Option[keyof Option]
                      ) => {
                        const updatedOptions = [...comboboxOptions];
                        updatedOptions[idx][key] = value as never;
                        setComboboxOptions(updatedOptions);
                      }}
                    />
                  ))}
                  <Box>
                    <Button
                      onClick={handleAddOption}
                      icon={PlusIcon}
                      disabled={isReadOnly}
                    >
                      {t("propertiesModal.addOption")}
                    </Button>
                  </Box>
                </BlockStack>
              </Box>
            )}

            {/* --------------- CUSTOM OPTIONS --------------- */}
            <Checkbox
              label={t("propertiesModal.customRendererOptions")}
              checked={customOptionsEnabled}
              onChange={() => setCustomOptionsEnabled(!customOptionsEnabled)}
              helpText={
                <TranslateLink
                  disabled={isReadOnly}
                  text={t("propertiesModal.customRendererOptionsHelpText")}
                />
              }
              disabled={isReadOnly}
            />
            {customOptionsEnabled && (
              <TextField
                autoComplete="false"
                label=""
                value={customOptions}
                onChange={setCustomOptions}
                error={errors.customOptions}
                disabled={isReadOnly}
              />
            )}

            {/* --------------- DEFAULT VALUE --------------- */}
            <Checkbox
              label={t("propertiesModal.defaultValue")}
              checked={defaultValueEnabled}
              onChange={() => setDefaultValueEnabled(!defaultValueEnabled)}
              helpText={t("propertiesModal.defaultValueHelpText")}
              disabled={isReadOnly}
            />

            {defaultValueEnabled && (
              <PropertyTypeRenderer
                id={renderType}
                label=""
                value={defaultValue}
                onChange={setDefaultValue}
                disabled={isReadOnly}
                helpText={errors.defaultValue}
                comboOptions={comboboxOptions}
              />
            )}

            <Checkbox
              label={t("propertiesModal.unique")}
              checked={hasUniqueValue}
              onChange={setHasUniqueValue}
              helpText={t("propertiesModal.uniqueHelpText")}
              disabled={isReadOnly}
            />
            <Checkbox
              label={t("propertiesModal.required")}
              checked={isRequired}
              onChange={setIsRequired}
              helpText={t("propertiesModal.requiredHelpText")}
              disabled={isReadOnly}
            />
            <Checkbox
              label={t("propertiesModal.shortlisted")}
              checked={isShortlisted}
              onChange={setIsShortlisted}
              helpText={t("propertiesModal.shortlistedHelpText")}
              disabled={isReadOnly}
            />
            <Checkbox
              label={t("propertiesModal.inCreateForm")}
              checked={isInCreateForm}
              onChange={setIsInCreateForm}
              helpText={t("propertiesModal.inCreateFormHelpText")}
              disabled={isReadOnly}
            />
          </BlockStack>
        </Modal.Section>
      </Modal>
    </DndProvider>
  );
};
export default PropertiesModal;
