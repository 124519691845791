import useApiRequest from "./useApiRequest";
import { useQuery } from "@tanstack/react-query";
import { SudoUser } from "@/types/user";

export const useSudoUser = (enabled: boolean) => {
  const fetchSudoUser = async (): Promise<SudoUser> => {
    const userData: SudoUser = await GET("/api/user/me/session/current");
    return userData;
  };
  const { GET } = useApiRequest();

  return useQuery<SudoUser, Error>({
    queryKey: ["sudo-user"],
    queryFn: fetchSudoUser,
    enabled,
  });
};
