import styled from "styled-components";
import { Text } from "@shopify/polaris";

const DividerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  text-align: center;
  margin: 8px 0;

  & > div {
    flex: 1;
    height: 1px;
    background-color: #dcdcdc;
  }

  & > p {
    margin: 0 16px;
  }
`;

function DividerWithText({ text }: { text: string }) {
    return (
        <DividerWrapper>
            <div />
            <Text as="p" tone="subdued">
                {text}
            </Text>
            <div />
        </DividerWrapper>
    );
}

export default DividerWithText;
