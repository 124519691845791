import { useState } from "react";
import SettingsPageWrapper from "./SettingsPageWrapper";
import { Page, Button, Layout, Box, Loading } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import TeamMembersCard from "./TeamMembersCard";
import PendingInvitationsCard from "./PendingInvitationsCard";
import AutoEnrollSettingsCard from "./AutoEnrollSettingsCard";
import AddUserModal from "./AddUserModal";
import useApiRequest from "@/hooks/useApiRequest";
import { useParams } from "react-router-dom";
import {
  Invite,
  Member,
  Role,
  Status,
  AutoEnrollSettingsResponse,
} from "../types";
import { PersonAddIcon } from "@shopify/polaris-icons";

const SettingsMembersAndPermissions = () => {
  const { t } = useTranslation();
  const params = useParams();
  const uuid = params.clinicId;

  const { GET } = useApiRequest();
  const [isAddUserModalActive, setIsAddUserModalActive] = useState(false);

  const handleOpenAddUserModal = () => setIsAddUserModalActive(true);
  const handleCloseAddUserModal = () => setIsAddUserModalActive(false);

  const { data: rolesData = [], isLoading: isLoadingRoles } = useQuery<Role[]>({
    queryKey: ["rolesAndPermissions", uuid],
    queryFn: () =>
      GET(`/api/healthcare-companies/${uuid}/team/roles-and-permissions`),
  });

  const {
    data: teamMembersData = [],
    isLoading: isLoadingTeamMembers,
    isFetching: isFetchingTeamMembers,
  } = useQuery<Member[]>({
    queryKey: ["teamMembers", uuid],
    queryFn: () => GET(`/api/healthcare-companies/${uuid}/team/members`),
  });

  const { data: inviteStatusesData = [], isLoading: isLoadingInviteStatuses } =
    useQuery<Status[]>({
      queryKey: ["inviteStatuses", uuid],
      queryFn: () =>
        GET(`/api/healthcare-companies/${uuid}/team/invites/statuses`),
    });

  const {
    data: invitedMembersData = [],
    isLoading: isLoadingInvitedMembers,
    isFetching: isFetchingInvitedMembers,
  } = useQuery<Invite[]>({
    queryKey: ["invitedMembers", uuid],
    queryFn: () => GET(`/api/healthcare-companies/${uuid}/team/invites`),
  });

  const {
    data: autoEnrollSettingsData,
    isLoading: isLoadingAutoEnrollSettings,
  } = useQuery<AutoEnrollSettingsResponse>({
    queryKey: ["autoEnrollSettings", uuid],
    queryFn: () => GET(`/api/healthcare-companies/${uuid}/team/settings`),
  });

  return (
    <SettingsPageWrapper>
      {(isFetchingTeamMembers ||
        isFetchingInvitedMembers ||
        isLoadingRoles ||
        isLoadingTeamMembers ||
        isLoadingInvitedMembers ||
        isLoadingInviteStatuses ||
        isLoadingAutoEnrollSettings) && <Loading />}
      <Page
        title={t("membersAndPermissions.title")}
        primaryAction={
          <Button
            onClick={handleOpenAddUserModal}
            icon={PersonAddIcon}
            size="large"
            disabled={isLoadingRoles}
          >
            {t("membersAndPermissions.add_user_cta")}
          </Button>
        }
      >
        <Layout>
          <Layout.Section>
            <TeamMembersCard
              roles={rolesData}
              teamMembers={teamMembersData}
              onAddUser={handleOpenAddUserModal}
              isLoading={isLoadingTeamMembers}
            />
          </Layout.Section>
          <Layout.Section>
            <PendingInvitationsCard
              isLoading={isLoadingInvitedMembers}
              invitedMembers={invitedMembersData}
              inviteStatuses={inviteStatusesData}
              onAddUser={handleOpenAddUserModal}
            />
          </Layout.Section>
          <Layout.Section>
            <AutoEnrollSettingsCard
              autoEnrollSettings={autoEnrollSettingsData?.autoJoin}
              allowedDomains={autoEnrollSettingsData?.meta?.allowedDomains}
              roles={rolesData}
              isLoading={isLoadingInviteStatuses || isLoadingRoles}
            />
          </Layout.Section>
        </Layout>
      </Page>

      <AddUserModal
        active={isAddUserModalActive}
        onClose={handleCloseAddUserModal}
        roles={rolesData}
        clinicId={uuid ?? ""}
      />

      <Box paddingBlockStart={"400"} />
    </SettingsPageWrapper>
  );
};

export default SettingsMembersAndPermissions;
