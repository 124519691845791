import React, { useEffect } from "react";
import {
  ResourceList,
  Avatar,
  Text,
  Button,
  Box,
  Spinner,
  InlineStack,
  BlockStack,
  Loading,
} from "@shopify/polaris";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { useTranslation } from "react-i18next";
import CardWrapper from "../CardWrapper";
import { Invitation, PageTypes } from "@/types/commonTypes";
import "./styles.scss";
import { removeLocalStorage } from "@/utils/localStorageUtils";
import { useNavigate } from "react-router-dom";
import TranslateLink from "@/common/TranslateLink";
import { setSkipActivationCheck } from "@/auth/authSlice";
import { useDispatch } from "react-redux";
import { CheckCircleIcon, XIcon } from "@shopify/polaris-icons";

const InvitationsCard: React.FC = () => {
  const { t } = useTranslation();
  const { GET, POST, DELETE } = useApiRequest();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: invitations = [],
    isLoading,
    isFetched,
    isFetching,
  } = useQuery<Invitation[]>({
    queryKey: ["invitations"],
    queryFn: () => GET("/api/user/me/invites"),
  });

  useEffect(() => {
    if (isFetched && invitations?.length === 0) {
      handleSkip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetched, invitations]);

  const acceptInvitationMutation = useMutation({
    mutationFn: (token: string) =>
      POST<{ message: string }>("/api/user/me/invites/accept", { token }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitations"] });
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
    },
  });

  const declineInvitationMutation = useMutation({
    mutationFn: (token: string) =>
      DELETE<{ message: string }>("/api/user/me/invites/decline", false, {
        token,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitations"] });
    },
  });

  const handleSkip = (isDone = false) => {
    removeLocalStorage("__REDIRECT_PATH");
    if (!isDone) {
      dispatch(setSkipActivationCheck("invites"));
    }
    setTimeout(() => {
      queryClient.refetchQueries({ queryKey: ["activation-check"] });
      navigate("/");
    }, 1);
  };

  const handleAccept = (token: string) => {
    acceptInvitationMutation.mutate(token);
  };

  const handleDecline = (token: string) => {
    declineInvitationMutation.mutate(token);
  };

  return (
    <CardWrapper type={PageTypes.UNKNOW} wrapperClass="white-card-wrapper">
      {acceptInvitationMutation.isPending ||
      declineInvitationMutation.isPending ||
      isFetching ? (
        <Loading />
      ) : (
        ""
      )}
      {isLoading ? (
        <Box minHeight="300" paddingBlockEnd={"1000"}>
          <InlineStack align="center" blockAlign="center">
            <Spinner />
          </InlineStack>
        </Box>
      ) : invitations.length > 0 ? (
        <BlockStack gap={"400"}>
          <Text variant="heading2xl" as="h1">
            {t("invitationsCard.title")}
          </Text>
          <TranslateLink
            text={t("invitationsCard.description")}
          ></TranslateLink>
          <div className="resource-list-container">
            <Box
              borderColor="border"
              borderWidth="025"
              borderStyle="solid"
              borderRadius="200"
            >
              <ResourceList
                resourceName={{ singular: "invitation", plural: "invitations" }}
                items={invitations}
                renderItem={(invitation) => {
                  const {
                    token,
                    company: { name: organizationName, avatar, website },
                  } = invitation;

                  const shortcutActions = [
                    {
                      content: t("common.accept"),
                      onAction: () => handleAccept(token),
                      loading: acceptInvitationMutation.isPending,
                      icon: CheckCircleIcon,
                    },
                    {
                      content: t("common.decline"),
                      onAction: () => handleDecline(token),
                      destructive: true,
                      loading: declineInvitationMutation.isPending,
                      icon: XIcon,
                    },
                  ];

                  return (
                    <ResourceList.Item
                      verticalAlignment="center"
                      id={token}
                      media={
                        <Avatar
                          size="lg"
                          name={organizationName}
                          source={avatar.image}
                          initials={avatar.initials}
                        />
                      }
                      onClick={() => {}}
                      shortcutActions={shortcutActions}
                      accessibilityLabel={`View details for ${organizationName}`}
                      persistActions
                    >
                      <BlockStack gap={"0"}>
                        <Text variant="bodyMd" fontWeight="bold" as="h3">
                          {organizationName}
                        </Text>

                        <Text as="p" variant="bodySm" tone="subdued">
                          {website}
                        </Text>
                      </BlockStack>
                    </ResourceList.Item>
                  );
                }}
              />
            </Box>
          </div>

          <BlockStack gap={"200"}>
            <Button fullWidth onClick={() => handleSkip()} size="large">
              {t("common.skipForNow")}
            </Button>
            <Text as="p" tone="subdued">
              {t("invitationsCard.footerNote")}
            </Text>
          </BlockStack>
        </BlockStack>
      ) : (
        ""
      )}
    </CardWrapper>
  );
};

export default InvitationsCard;
