import { useState, useCallback } from "react";
import {
  Card,
  Tabs,
  IndexTable,
  Badge,
  Popover,
  ActionList,
  Text,
  BlockStack,
  Button,
  Box,
  InlineStack,
  Avatar,
  Loading,
  EmptyState,
  IconSource,
} from "@shopify/polaris";
import RenderDate from "@/common/RenderDate";
import {
  EditIcon,
  HideIcon,
  MenuHorizontalIcon,
  PersonAddIcon,
  PersonRemoveIcon,
  SendIcon,
} from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { Invite, Status } from "../types";
import TranslateLink from "@/common/TranslateLink";
import { getBadgeTone } from "@/utils/utils";
import SearchIcon from "@/assets/searchIcon.svg";
import TableSkeleton from "@/common/Skeletons/TableSkeleton";

interface PendingInvitationsCardProps {
  invitedMembers: Invite[];
  inviteStatuses: Status[];
  onAddUser: () => void;
  isLoading: boolean;
}

const actionIcons: Record<string, IconSource> = {
  edit: EditIcon,
  resend: SendIcon,
  remove: PersonRemoveIcon,
  hide: HideIcon,
};

const PendingInvitationsCard: React.FC<PendingInvitationsCardProps> = ({
  invitedMembers,
  inviteStatuses,
  onAddUser,
  isLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { DELETE, POST } = useApiRequest();
  const params = useParams();
  const uuid = params.clinicId;
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = useState(0);
  const [activePopoverId, setActivePopoverId] = useState<string | null>(null);
  const invitationsTabs = inviteStatuses.map(({ id, label }) => ({
    id,
    content: label,
  }));

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelectedTab(selectedTabIndex),
    []
  );

  const filteredInvitations = invitedMembers.filter((invitation) => {
    if (selectedTab === 0) return invitation.status === "Pending";
    if (selectedTab === 1) return invitation.status === "Accepted";
    if (selectedTab === 2) return invitation.status === "Declined";
    if (selectedTab === 3) return invitation.status === "Expired";
    return true;
  });

  const togglePopover = (id: string) => {
    setActivePopoverId((prev) => (prev === id ? null : id));
  };

  const deleteMutation = useMutation({
    mutationFn: (inviteId: string) =>
      DELETE<{ message: string }>(
        `/api/healthcare-companies/${uuid}/team/invites/${inviteId}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitedMembers", uuid] });
    },
    onError: (error) => {
      console.error("Failed to remove invite:", error);
    },
  });

  const resendMutation = useMutation({
    mutationFn: (inviteId: string) =>
      POST<{ message: string }>(
        `/api/healthcare-companies/${uuid}/team/invites/${inviteId}/resend`,
        {}
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitedMembers", uuid] });
    },
    onError: (error) => {
      console.error("Failed to resend invite:", error);
    },
  });

  const handleAction = (action: string, inviteId: string) => {
    switch (action) {
      case "resend":
        resendMutation.mutate(inviteId);
        break;
      case "remove":
      case "hide":
        deleteMutation.mutate(inviteId);
        break;
      case "edit":
        navigate(`/c/${uuid}/settings/members-permissions/invites/${inviteId}`);
        break;
      default:
        break;
    }
    togglePopover(inviteId);
  };

  const renderEmptyState = () => {
    return (
      <EmptyState
        heading={t("membersAndPermissions.pendingInvitations.emptyState.title")}
        action={{
          content: t("common.addUsers"),
          onAction: onAddUser,
          icon: PersonAddIcon,
        }}
        image={SearchIcon}
      >
        <p>
          {t("membersAndPermissions.pendingInvitations.emptyState.description")}
        </p>
      </EmptyState>
    );
  };

  return (
    <>
      {deleteMutation.isPending || resendMutation.isPending ? <Loading /> : ""}
      <Card padding={"0"}>
        <Box padding={"400"}>
          <BlockStack gap={"200"}>
            <Text as="h2" variant="headingMd">
              {t("membersAndPermissions.pendingInvitations.title")}
            </Text>
            <TranslateLink
              text={t("membersAndPermissions.pendingInvitations.description")}
            />
          </BlockStack>
        </Box>
        <Tabs
          tabs={invitationsTabs}
          selected={selectedTab}
          onSelect={handleTabChange}
        />
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <IndexTable
            resourceName={{ singular: "invitation", plural: "invitations" }}
            itemCount={filteredInvitations.length}
            selectable={false}
            emptyState={renderEmptyState()}
            headings={[
              { title: t("common.userDetails") },
              { title: t("common.accessStatus") },
              { title: t("common.role") },
              { title: t("common.invitedOn") },
              { title: t("common.invitedBy") },
              { title: "" },
            ]}
          >
            {filteredInvitations.map(
              (
                { id, invitee, inviter, status, role, createdAt, actions },
                index
              ) => (
                <IndexTable.Row
                  id={id}
                  key={id}
                  position={index}
                  onClick={
                    activePopoverId ||
                    status === "Accepted" ||
                    status === "Declined"
                      ? undefined
                      : () => {
                          handleAction("edit", id);
                        }
                  }
                >
                  <IndexTable.Cell>{invitee.email}</IndexTable.Cell>
                  <IndexTable.Cell>
                    <Badge tone={getBadgeTone(status)}>{status}</Badge>
                  </IndexTable.Cell>
                  <IndexTable.Cell>{role}</IndexTable.Cell>
                  <IndexTable.Cell>
                    <RenderDate date={createdAt} />
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <InlineStack gap={"200"} blockAlign="center">
                      <Avatar
                        source={inviter.avatar?.image}
                        initials={inviter.avatar?.initials}
                        size="lg"
                      />
                      <BlockStack gap={"0"}>
                        {`${inviter.firstName} ${inviter.lastName}`}
                        <Text as="p" tone="subdued">
                          {`${inviter.email}`}
                        </Text>
                      </BlockStack>
                    </InlineStack>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {!!actions?.length && (
                      <Popover
                        active={activePopoverId === id}
                        activator={
                          <div onClick={(e) => e.stopPropagation()}>
                            <Button
                              onClick={() => togglePopover(id)}
                              variant="plain"
                              icon={MenuHorizontalIcon}
                            ></Button>
                          </div>
                        }
                        onClose={() => setActivePopoverId(null)}
                        preferredAlignment="right"
                      >
                        <ActionList
                          items={actions?.map((action) => ({
                            content: t(`common.${action}`),
                            onAction: () => handleAction(action, id),
                            icon: actionIcons[action] as IconSource,
                            destructive: action === "remove",
                          }))}
                        />
                      </Popover>
                    )}
                  </IndexTable.Cell>
                </IndexTable.Row>
              )
            )}
          </IndexTable>
        )}
      </Card>
    </>
  );
};

export default PendingInvitationsCard;
