import useApiRequest from "./useApiRequest";
import { ROOTS, ROUTES } from "@/utils/Constants";
import { saveTokenData } from "@/auth/utils";
import { setEmailFor2FA, setPasswordFor2FA } from "@/auth/authSlice";
import { setIsInitializingApp } from "@/reducers/appSlice";
import { TokenResponse } from "@/auth/types";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { getLocalStorage, removeLocalStorage } from "@/utils/localStorageUtils";
import { useState } from "react";

const useOTT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { POST } = useApiRequest();
  const queryClient = useQueryClient();
  const inviteToken = getLocalStorage("invite_token");

  const [reloadOnOttExchange, setReloadOnOttExchange] = useState(false);

  const exchangeOTT = async (ott: string): Promise<TokenResponse> => {
    const response = await POST<TokenResponse>("/api/auth/ott/exchange", {
      ott,
    });
    return response;
  };

  const acceptInviteMutation = useMutation({
    mutationFn: () =>
      POST("/api/user/me/invites/accept", { token: inviteToken }, true),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
      removeLocalStorage("invite_token");
      dispatch(setIsInitializingApp(true));
      if (reloadOnOttExchange) {
        window.location.href = "/";
      } else {
        const clinicId = getLocalStorage("__LAST_VISITED_C_ID") as string;
        if (clinicId) {
          navigate(`${ROOTS.CLINIC}/${clinicId}`);
        } else {
          navigate(`${ROOTS.CLINIC}`);
        }
      }
    },
  });

  const { mutateAsync: handleOTT } = useMutation({
    mutationFn: exchangeOTT,
    onSuccess: (tokenData) => {
      // if we have invite token just call the API to accept invite with that token and remove token from storage
      saveTokenData(tokenData);
      dispatch(setEmailFor2FA(""));
      dispatch(setPasswordFor2FA(""));
      if (inviteToken) {
        acceptInviteMutation.mutate();
      } else if (reloadOnOttExchange) {
        window.location.href = "/";
      } else {
        dispatch(setIsInitializingApp(true));
        navigate("/");
      }
    },
    onError: () => {
      navigate(ROUTES.LOGIN);
    },
  });

  return { handleOTT, setReloadOnOttExchange };
};

export default useOTT;
