import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import RegisterToJoin from "./RegisterToJoin";
import LoginToJoin from "./LoginToJoin";
import useApiRequest from "@/hooks/useApiRequest";
import { InviteData } from "@/auth/types";
import { setLocalStorage } from "@/utils/localStorageUtils";
import CardWrapper from "../CardWrapper";
import { ErrorResponse, PageTypes } from "@/types/commonTypes";
import { Banner, BlockStack, Box, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

function JoinForm() {
  const { GET } = useApiRequest();
  const [formMode, setFormMode] = useState("register");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { t } = useTranslation();

  const {
    data: inviteData,
    isLoading,
    isError,
    error,
  } = useQuery<InviteData, ErrorResponse>({
    queryKey: ["invite-data", token],
    queryFn: () => GET(`/api/auth/invites/${token}`),
    enabled: !!token,
  });

  useEffect(() => {
    if (!isLoading && inviteData && !isError) {
      setLocalStorage("__LAST_VISITED_C_ID", inviteData?.company?.id);
    }
  }, [isLoading, inviteData, isError]);

  const navigateToHome = () => {
    window.location.href = "/";
  };

  const renderJoinForm = () => {
    return formMode === "register" ? (
      <RegisterToJoin
        inviteData={inviteData}
        isLoading={isLoading}
        acceptWithRegisteredUser={() => {
          setFormMode("login");
        }}
      />
    ) : (
      <LoginToJoin
        inviteData={inviteData}
        acceptWithEmail={() => {
          setFormMode("register");
        }}
      />
    );
  };
  const renderErrorCard = () => {
    return (
      <CardWrapper
        type={PageTypes.UNKNOW}
        wrapperClass="white-card-wrapper"
        footerClass="relative-auth-footer"
      >
        <Banner tone="critical">
          {error?.response?.data?.error?.display ?? "Invalid token"}
        </Banner>
        <Box paddingBlockStart={"300"} paddingBlockEnd={"1200"}>
          <BlockStack inlineAlign="start" gap={"200"}>
            <Button variant="plain" onClick={navigateToHome}>
              {t("common.gotoHome")}
            </Button>
          </BlockStack>
        </Box>
      </CardWrapper>
    );
  };

  return isError && !isLoading ? renderErrorCard() : renderJoinForm();
}

export default JoinForm;
