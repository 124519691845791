import { useState, useEffect, useCallback } from "react";
import {
  Page,
  Card,
  Badge,
  Select,
  FormLayout,
  TextField,
  ContextualSaveBar,
  SkeletonBodyText,
  SkeletonDisplayText,
  Loading,
  MenuActionDescriptor,
  IconSource,
} from "@shopify/polaris";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { Invite, Role } from "../types";
import { getBadgeTone } from "@/utils/utils";
import SettingsPageWrapper from "./SettingsPageWrapper";
import useFormattedDate from "@/hooks/useFormattedDate";
import {
  EditIcon,
  HideIcon,
  PersonRemoveIcon,
  SendIcon,
} from "@shopify/polaris-icons";

const actionIcons: Record<string, IconSource> = {
  edit: EditIcon,
  resend: SendIcon,
  remove: PersonRemoveIcon,
  hide: HideIcon,
};
const EditInvite = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clinicId, inviteId } = useParams<{
    clinicId: string;
    inviteId: string;
  }>();
  const queryClient = useQueryClient();
  const { GET, PATCH, DELETE, POST } = useApiRequest();

  const [selectedRole, setSelectedRole] = useState<number>();
  const [email, setEmail] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const {
    data: inviteData,
    isLoading: isLoadingInvite,
    isFetching: isFetchingInvite,
  } = useQuery<Invite>({
    queryKey: ["invite", clinicId, inviteId],
    queryFn: () =>
      GET(`/api/healthcare-companies/${clinicId}/team/invites/${inviteId}`),
  });

  const { data: rolesData = [], isLoading: isLoadingRoles } = useQuery<Role[]>({
    queryKey: ["roles", clinicId],
    queryFn: () =>
      GET(`/api/healthcare-companies/${clinicId}/team/roles-and-permissions`),
  });

  useEffect(() => {
    if (inviteData?.roleId) {
      setSelectedRole(inviteData.roleId);
      setEmail(inviteData.invitee?.email || "");
    }
  }, [inviteData]);

  const updateInviteMutation = useMutation({
    mutationFn: (updatedData: { roleId: number | undefined; email: string }) =>
      PATCH(`/api/healthcare-companies/${clinicId}/team/invites/${inviteId}`, {
        roleId: updatedData.roleId,
        email: updatedData.email,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["invite", clinicId, inviteId],
      });
      queryClient.invalidateQueries({ queryKey: ["invitedMembers", clinicId] });
      setIsDirty(false);
    },
  });

  const resendInviteMutation = useMutation({
    mutationFn: () =>
      POST(
        `/api/healthcare-companies/${clinicId}/team/invites/${inviteId}/resend`,
        {}
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitedMembers", clinicId] });
      queryClient.invalidateQueries({
        queryKey: ["invite", clinicId, inviteId],
      });
    },
  });

  const deleteInviteMutation = useMutation({
    mutationFn: () =>
      DELETE(`/api/healthcare-companies/${clinicId}/team/invites/${inviteId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invitedMembers", clinicId] });
      navigate(`/c/${clinicId}/settings/members-permissions`);
    },
  });

  const handleRoleChange = useCallback((value: string) => {
    setSelectedRole(parseInt(value, 10));
    setIsDirty(true);
  }, []);

  const handleEmailChange = useCallback((value: string) => {
    setEmail(value);
    setIsDirty(true);
  }, []);

  const resetState = () => {
    setIsDirty(false);
    setSelectedRole(inviteData?.roleId);
    setEmail(inviteData?.invitee?.email ?? "");
  };
  const handleSave = useCallback(() => {
    updateInviteMutation.mutate({ roleId: selectedRole, email });
  }, [selectedRole, email, updateInviteMutation]);

  const handleRemove = useCallback(() => {
    deleteInviteMutation.mutate();
  }, [deleteInviteMutation]);

  const handleResend = useCallback(() => {
    resendInviteMutation.mutate();
  }, [resendInviteMutation]);

  const dynamicActions = (inviteData?.actions || []).reduce((acc, action) => {
    switch (action) {
      case "resend":
        acc.push({
          content: t("common.resend"),
          onAction: handleResend,
          loading: resendInviteMutation.isPending,
          icon: actionIcons[action] as IconSource,
        });
        break;
      case "remove":
      case "hide":
        acc.push({
          content: t("common.remove"),
          onAction: handleRemove,
          loading: deleteInviteMutation.isPending,
          destructive: true,
          icon: actionIcons[action] as IconSource,
        });
        break;
      default:
        break;
    }
    return acc;
  }, [] as MenuActionDescriptor[]);

  const invitedBy =
    inviteData?.inviter?.firstName || inviteData?.inviter?.lastName
      ? `${inviteData?.inviter?.firstName} ${inviteData?.inviter?.lastName}`
      : undefined;
  const { formattedDate: invitedOn } = useFormattedDate({
    date: inviteData?.createdAt ?? "",
  });

  const roleOptions = rolesData.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));

  return (
    <SettingsPageWrapper>
      {isDirty && (
        <ContextualSaveBar
          message={t("common.unsavedChanges")}
          saveAction={{
            onAction: handleSave,
            loading: updateInviteMutation.isPending,
          }}
          discardAction={{
            onAction: resetState,
          }}
        />
      )}
      {isFetchingInvite && <Loading />}
      {isLoadingInvite || isLoadingRoles ? (
        <Page>
          <Loading />
          <SkeletonDisplayText />
          <br />
          <Card>
            <SkeletonBodyText lines={7} />
          </Card>
        </Page>
      ) : (
        <Page
          backAction={{
            onAction: () =>
              navigate(`/c/${clinicId}/settings/members-permissions`),
          }}
          title={inviteData?.invitee?.email}
          titleMetadata={
            <Badge tone={getBadgeTone(inviteData?.status ?? "")}>
              {inviteData?.status}
            </Badge>
          }
          subtitle={
            invitedBy
              ? t("membersAndPermissions.invitedBy", {
                  name: invitedBy,
                  date: invitedOn,
                })
              : t("membersAndPermissions.invitedOn", {
                  date: invitedOn,
                })
          }
          secondaryActions={dynamicActions}
        >
          <Card>
            <FormLayout>
              <TextField
                label={t("common.email")}
                value={email}
                onChange={handleEmailChange}
                autoComplete="off"
              />
              <Select
                label={t("common.role")}
                options={roleOptions}
                value={selectedRole + ""}
                onChange={handleRoleChange}
                disabled={updateInviteMutation.isPending}
              />
            </FormLayout>
          </Card>
        </Page>
      )}
    </SettingsPageWrapper>
  );
};

export default EditInvite;
