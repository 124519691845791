import {
  Card,
  Select,
  Text,
  BlockStack,
  ContextualSaveBar,
  Page,
  Box,
  SkeletonDisplayText,
} from "@shopify/polaris";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { BGRComplianceData, Country, UserComplianceData } from "../types";
import { useEffect, useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import AppPageWrapper from "@/common/AppPageWrapper";
import { Forms } from "./Forms";
import { CountryAPIMapping } from "../CountryAPIMapping";

const UserCompliancePage = () => {
  const { t } = useTranslation();
  const { GET, PUT } = useApiRequest();

  const [selectedCountry, setSelectedCountry] = useState<string>("None");
  const [formState, setFormState] = useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BGRComplianceData | Record<string, any>
  >({});
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});
  const [showValidationError, setShowValidationError] = useState(false);

  const { data: countriesData, isLoading: isCountriesLoading } = useQuery({
    queryKey: ["metadata-countries"],
    queryFn: () => GET<Country[]>("/api/metadata/countries"),
  });

  const { data: userComplianceData, isLoading: isComplianceLoading } = useQuery(
    {
      queryKey: ["user-compliance"],
      queryFn: () => GET<UserComplianceData>("/api/user/me/compliance"),
    }
  );

  const supportedCountries = countriesData?.filter((c: Country) =>
    userComplianceData?.availableCountries.includes(c.iso3)
  );

  const countryOptions = [
    { name: t("userCompliance.none"), code: "None" },
    ...(supportedCountries || []).map((c) => ({ name: c.name, code: c.iso3 })),
  ];

  useEffect(() => {
    if (userComplianceData) {
      setSelectedCountry(userComplianceData.country || "None");
    }
  }, [userComplianceData]);

  const saveComplianceMutation = useMutation({
    mutationFn: async () => {
      if (selectedCountry === "None") {
        await PUT("/api/user/me/compliance", { country: null });
      } else {
        await PUT("/api/user/me/compliance", { country: selectedCountry });
        await PUT(`/api/compliance${CountryAPIMapping[selectedCountry]}`, {
          ...formState,
        });
      }
    },
    onSuccess: () => {
      setShowSaveBar(false);
      setShowValidationError(false); // Reset submission state after successful save
    },
  });

  const handleCountryChange = (value: string) => {
    setSelectedCountry(value);
    setFormState({});
    setValidationErrors({});
    setShowSaveBar(true);
  };

  const handleSave = () => {
    setShowValidationError(true);

    // Prevent save if there are validation errors
    if (Object.keys(validationErrors).length > 0) {
      console.error("Validation errors:", validationErrors);
      return;
    }

    // Trigger save mutation if no validation errors
    saveComplianceMutation.mutate();
  };

  return (
    <AppPageWrapper>
      {showSaveBar && (
        <ContextualSaveBar
          message={t("userCompliance.contextualSaveBar.message")}
          saveAction={{
            onAction: handleSave,
            loading: saveComplianceMutation.isPending,
          }}
          discardAction={{
            onAction: () => setShowSaveBar(false),
          }}
        />
      )}
      <Page title={t("userCompliance.title")} narrowWidth>
        <BlockStack gap={"400"}>
          <Card>
            <BlockStack gap="200">
              <Text as="h2" variant="headingMd">
                {t("userCompliance.cardTitle")}
              </Text>
              <Text as="p">{t("userCompliance.description")}</Text>
              {isCountriesLoading || isComplianceLoading ? (
                <SkeletonDisplayText />
              ) : (
                <Box maxWidth="40%" paddingBlockStart={"200"}>
                  <Select
                    label=""
                    options={countryOptions.map((c) => ({
                      label: c.name,
                      value: c.code,
                    }))}
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  />
                </Box>
              )}
            </BlockStack>
          </Card>
          {selectedCountry !== "None" && (
            <Card>
              {Forms[selectedCountry]?.({
                formState: formState as BGRComplianceData,
                setFormState,
                setShowSaveBar,
                setValidationErrors,
                showValidationError,
              })}
            </Card>
          )}
        </BlockStack>
      </Page>
      <Box paddingBlockEnd={"400"} />
    </AppPageWrapper>
  );
};

export default UserCompliancePage;
