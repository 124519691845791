import TagManager from "react-gtm-module";
import { RootState } from "@/store";
import { setGtmEventSent } from "@/reducers/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

interface GTMEventParams {
  [key: string]: string | number | boolean | undefined;
}

export const sendGTMEvent = (
  eventName: string,
  eventParams: GTMEventParams
): void => {
  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...eventParams,
    },
  });
};

export const useAppLoadGTM = () => {
  const dispatch = useDispatch();
  const isGTMEventSent = useSelector(
    (state: RootState) => state.app.gtmEventSent
  );

  useEffect(() => {
    if (!isGTMEventSent && import.meta.env.NODE_ENV === "production") {
      sendGTMEvent("app_load", {
        timestamp: new Date().toISOString(),
      });
      dispatch(setGtmEventSent(true));
    }
  }, [isGTMEventSent, dispatch]);
};
