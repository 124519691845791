export interface ErrorResponse {
  response: {
    status: number;
    data: {
      error: {
        display: string;
        system: string;
        inline?: string;
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    };
    config: {
      url: string;
    };
  };
}

export interface GeneralResponse {
  message: string;
}

export enum PageTypes {
  CLINIC = "CLINIC",
  PATIENT = "PATIENT",
  USER = "USER",
  AUTH = "AUTH",
  SETTINGS = "SETTINGS",
  UNKNOW = "UNKNOWN",
  NOT_FOUND = "NOT_FOUND",
}

export type UserPermissions = string[];

export interface Invitation {
  token: string;
  createdAt: string;
  expiresAt: string;
  role: string;
  roleId: number;
  status: string;
  company: {
    id: string;
    name: string;
    avatar: {
      image: string;
      initials: string;
      colorSchema: {
        background: string;
        text: string;
      };
    };
    website: string;
  };
  inviter: {
    firstName: string;
    lastName: string;
    avatar: {
      initials: string;
      colorSchema: {
        background: string;
        text: string;
      };
      image: string;
    };
    email: string;
  };
}
