import React, { useState } from "react";
import TranslateLink from "@/common/TranslateLink";
import useApiRequest from "@/hooks/useApiRequest";
import { ErrorResponse } from "@/types/commonTypes";
import { RootState } from "@/store";
import { useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { VerifyResponse } from "../types";

import {
  BlockStack,
  Box,
  InlineStack,
  Link,
  TextField,
  Loading,
  Modal,
  Bleed,
  InlineError,
} from "@shopify/polaris";

interface CheckAuthForEmailUpdateProps {
  onDone?: () => void;
  onClose: () => void;
}

const CheckAuthForEmailUpdate: React.FC<CheckAuthForEmailUpdateProps> = ({
  onClose,
  onDone,
}) => {
  const { t } = useTranslation();
  const { POST } = useApiRequest();
  const [authCode, setAuthCode] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const email = useSelector(
    (state: RootState) => state.auth.email_tobe_verified
  );

  const verifyCode = async (code: string) => {
    return POST<VerifyResponse>("/api/user/me/email/verify", {
      code,
      new_email: email,
    });
  };

  const verifyMutation = useMutation({
    mutationFn: verifyCode,
    onSuccess: async () => {
      onDone?.();
    },
    onError: (error: ErrorResponse) => {
      if (error?.response?.data?.error?.inline === "code_ti") {
        setError(
          error?.response?.data?.error?.display || t("auth.common.error")
        );
      }
    },
  });

  const resendCodeMutation = useMutation({
    mutationFn: async () => {
      return POST<{ message: string }>("/api/auth/email/verify/request", {
        email,
      });
    },

    onError: (error: ErrorResponse) => {
      setError(error?.response?.data?.error?.display || t("auth.common.error"));
    },
  });

  const handleVerify = () => {
    if (error) setError(null);
    if (authCode.length >= 6) {
      verifyMutation.mutate(authCode);
    }
  };

  const handleResendCode = () => {
    setError(null);
    resendCodeMutation.mutate();
  };

  return (
    <Modal
      open={true}
      onClose={() => onClose()}
      title={t("auth.regEmailCheck.checkYourEmail")}
      primaryAction={{
        content: t("common.verify"),
        onAction: handleVerify,
        loading: verifyMutation.isPending,
        disabled: authCode.length < 6,
      }}
      secondaryActions={[
        {
          content: t("common.cancel"),
          onAction: () => onClose(),
        },
      ]}
    >
      <Modal.Section>
        {resendCodeMutation.isPending && <Loading />}
        <BlockStack gap="600">
          <BlockStack gap="400" inlineAlign="start">
            <TranslateLink
              text={t("auth.regEmailCheck.description", { email })}
            ></TranslateLink>
          </BlockStack>
          <TextField
            label={t("auth.regEmailCheck.enterCode")}
            autoComplete="off"
            value={authCode}
            onChange={(value) => {
              const trimmedValue = value.replace(/\s+/g, "").trim();
              setError(null);
              setAuthCode(trimmedValue);
            }}
            autoFocus
          />
          {error && <InlineError fieldID="verification_code" message={error} />}

          <Box paddingBlockEnd="200">
            <InlineStack gap="100">
              <Link onClick={handleResendCode} removeUnderline>
                {t("auth.regEmailCheck.resendCode")}
              </Link>

              <Bleed marginInlineStart={"100"}>,</Bleed>
              <></>
              <TranslateLink text={t("auth.regEmailCheck.helpCenter")} />
            </InlineStack>
          </Box>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default CheckAuthForEmailUpdate;
