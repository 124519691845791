import { useState, useCallback } from "react";
import {
  Card,
  Tabs,
  IndexTable,
  Badge,
  Popover,
  ActionList,
  Text,
  BlockStack,
  Button,
  Box,
  InlineStack,
  Icon,
  Avatar,
  Loading,
  EmptyState,
  Tooltip,
  IconSource,
} from "@shopify/polaris";

import SearchIcon from "@/assets/searchIcon.svg";
import {
  EditIcon,
  MenuHorizontalIcon,
  PersonAddIcon,
  PersonRemoveIcon,
  ShieldCheckMarkIcon,
  ShieldNoneIcon,
  StatusActiveIcon,
  XCircleIcon,
} from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { Member, Role } from "../types";
import RenderDate from "@/common/RenderDate";
import TranslateLink from "@/common/TranslateLink";
import { getBadgeTone } from "@/utils/utils";
import TableSkeleton from "@/common/Skeletons/TableSkeleton";

interface TeamMembersCardProps {
  teamMembers: Member[];
  roles: Role[];
  onAddUser: () => void;
  isLoading: boolean;
}

const actionIcons: Record<string, IconSource> = {
  edit: EditIcon,
  activate: StatusActiveIcon,
  deactivate: XCircleIcon,
  remove: PersonRemoveIcon,
};

const TeamMembersCard: React.FC<TeamMembersCardProps> = ({
  teamMembers,
  roles,
  onAddUser,
  isLoading,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { PATCH, DELETE } = useApiRequest();
  const queryClient = useQueryClient();
  const params = useParams();
  const clinicId = params.clinicId;

  const [selectedTab, setSelectedTab] = useState(0);
  const [activePopoverId, setActivePopoverId] = useState<string | null>(null);

  const teamTabs = [
    {
      id: "all-team",
      content: t("membersAndPermissions.teamMembers.table.all"),
    },
    {
      id: "active-team",
      content: t("membersAndPermissions.teamMembers.table.active"),
    },
    {
      id: "inactive-team",
      content: t("membersAndPermissions.teamMembers.table.inactive"),
    },
  ];

  const handleTabChange = useCallback(
    (selectedTabIndex: number) => setSelectedTab(selectedTabIndex),
    []
  );

  const filteredMembers = teamMembers.filter((member) => {
    if (selectedTab === 1) return member.status === 1;
    if (selectedTab === 2) return member.status !== 1;
    return true;
  });

  const togglePopover = (id: string) => {
    setActivePopoverId((prev) => (prev === id ? null : id));
  };

  const statusMutation = useMutation({
    mutationFn: (data: { memberId: string; status: number }) =>
      PATCH<{ message: string }>(
        `/api/healthcare-companies/${clinicId}/team/members/${data.memberId}`,
        {
          status: data.status,
        }
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teamMembers", clinicId] });
    },
    onError: (error) => {
      console.error("Failed to update member status:", error);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: (memberId: string) =>
      DELETE<{ message: string }>(
        `/api/healthcare-companies/${clinicId}/team/members/${memberId}`
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teamMembers", clinicId] });
    },
    onError: (error) => {
      console.error("Failed to remove member:", error);
    },
  });

  const handleAction = (action: string, memberId: string) => {
    switch (action) {
      case "edit":
      case "changeRole":
        navigate(
          `/c/${clinicId}/settings/members-permissions/members/${memberId}`
        );
        break;
      case "activate":
        statusMutation.mutate({ memberId, status: 1 });
        break;
      case "deactivate":
        statusMutation.mutate({ memberId, status: 0 });
        break;
      case "remove":
        deleteMutation.mutate(memberId);
        break;
      default:
        break;
    }
    togglePopover(memberId);
  };

  const renderEmptyState = () => {
    return (
      <EmptyState
        heading={t("membersAndPermissions.teamMembers.emptyState.title")}
        action={{
          content: t("common.addUsers"),
          onAction: onAddUser,
          icon: PersonAddIcon,
        }}
        image={SearchIcon}
      >
        <p>{t("membersAndPermissions.teamMembers.emptyState.description")}</p>
      </EmptyState>
    );
  };

  return (
    <>
      {(statusMutation.isPending || deleteMutation.isPending) && <Loading />}
      <Card padding={"0"}>
        <Box padding={"400"}>
          <BlockStack gap={"200"}>
            <Text as="h2" variant="headingMd">
              {t("membersAndPermissions.teamMembers.title")}
            </Text>
            <TranslateLink
              text={t("membersAndPermissions.teamMembers.description")}
            />
          </BlockStack>
        </Box>
        <Tabs
          tabs={teamTabs}
          selected={selectedTab}
          onSelect={handleTabChange}
        />
        {isLoading ? (
          <TableSkeleton />
        ) : (
          <IndexTable
            selectable={false}
            resourceName={{ singular: "user", plural: "users" }}
            itemCount={filteredMembers.length}
            emptyState={renderEmptyState()}
            headings={[
              { title: t("common.userDetails") },
              { title: t("common.status") },
              { title: t("common.security"), alignment: "center" },
              { title: t("common.role") },
              { title: t("common.lastActive") },
              { title: "" },
            ]}
          >
            {filteredMembers.map(
              (
                {
                  id,
                  firstName,
                  lastName,
                  email,
                  status,
                  userPermissions,
                  lastActiveAt,
                  tfa,
                  avatar,
                  actions = [],
                  isMe,
                },
                index
              ) => {
                const userDetails = `${firstName} ${lastName}`;
                const userRole =
                  roles.find((role) => role.id === userPermissions.roleId)
                    ?.name ?? userPermissions.role;

                return (
                  <IndexTable.Row
                    disabled={isMe}
                    id={id}
                    key={id}
                    position={index}
                    onClick={() =>
                      !activePopoverId && !isMe && handleAction("edit", id)
                    }
                  >
                    <IndexTable.Cell>
                      <InlineStack gap={"200"} blockAlign="center">
                        <Box>
                          <Avatar
                            source={avatar.image}
                            initials={avatar.initials}
                            size="lg"
                          />
                        </Box>
                        <BlockStack gap={"0"}>
                          {userDetails}
                          <Text as="p" tone="subdued">
                            {email}
                          </Text>
                        </BlockStack>
                      </InlineStack>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Badge
                        tone={getBadgeTone(
                          status === 1 ? "active" : "inactive"
                        )}
                      >
                        {t(
                          `membersAndPermissions.teamMembers.table.${
                            status === 1 ? "active" : "inactive"
                          }`
                        )}
                      </Badge>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <Tooltip
                        content={
                          tfa
                            ? t("common.twoFAEnabled")
                            : t("common.twoFADisabled")
                        }
                      >
                        <Icon
                          source={tfa ? ShieldCheckMarkIcon : ShieldNoneIcon}
                        />
                      </Tooltip>
                    </IndexTable.Cell>
                    <IndexTable.Cell>{userRole}</IndexTable.Cell>
                    <IndexTable.Cell>
                      <RenderDate date={lastActiveAt} />
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      {!!actions?.length && (
                        <Popover
                          active={activePopoverId === id}
                          activator={
                            <div onClick={(e) => e.stopPropagation()}>
                              <Button
                                onClick={() => {
                                  togglePopover(id);
                                }}
                                variant="plain"
                                icon={MenuHorizontalIcon}
                              ></Button>
                            </div>
                          }
                          onClose={() => setActivePopoverId(null)}
                          preferredAlignment="right"
                        >
                          <ActionList
                            items={actions?.map((action) => ({
                              content: t(`common.${action}`),
                              onAction: () => handleAction(action, id),
                              icon: actionIcons[action] as IconSource,
                              destructive: action === "remove",
                            }))}
                          />
                        </Popover>
                      )}
                    </IndexTable.Cell>
                  </IndexTable.Row>
                );
              }
            )}
          </IndexTable>
        )}
      </Card>
    </>
  );
};

export default TeamMembersCard;
