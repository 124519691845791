import { useTranslation } from "react-i18next";
import {
  SkeletonPage,
  Card,
  SkeletonBodyText,
  Box,
  SkeletonDisplayText,
  BlockStack,
  InlineStack,
  SkeletonThumbnail,
} from "@shopify/polaris";

function PerksSkeleton() {
  const [t] = useTranslation();

  return (
    <SkeletonPage title={t("perks.title")}>
      <BlockStack gap="500">
        <Card roundedAbove="sm">
          <Box padding="500">
            <BlockStack gap={"500"}>
              <InlineStack gap={"300"} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={1} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={2} />
              </Box>

              <BlockStack gap={"600"}>
                <Box width="10%">
                  <SkeletonBodyText lines={1} />
                </Box>
                <Box width="20%">
                  <SkeletonBodyText lines={1} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm">
          <Box padding="500">
            <BlockStack gap={"500"}>
              <InlineStack gap={"300"} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={2} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={2} />
              </Box>

              <BlockStack gap={"600"}>
                <Box width="10%">
                  <SkeletonBodyText lines={1} />
                </Box>
                <Box width="30%">
                  <SkeletonBodyText lines={1} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm">
          <Box padding="500">
            <BlockStack gap={"500"}>
              <InlineStack gap={"300"} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={2} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={2} />
              </Box>

              <BlockStack gap={"600"}>
                <Box width="10%">
                  <SkeletonBodyText lines={1} />
                </Box>
                <Box width="20%">
                  <SkeletonBodyText lines={1} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm">
          <Box padding="500">
            <BlockStack gap={"500"}>
              <InlineStack gap={"300"} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={2} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={2} />
              </Box>

              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm">
          <Box padding="500">
            <BlockStack gap={"500"}>
              <InlineStack gap={"300"} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={2} />
                </Box>
              </InlineStack>
              <Box>
                <SkeletonBodyText lines={1} />
              </Box>

              <BlockStack gap={"600"}>
                <Box width="10%">
                  <SkeletonBodyText lines={1} />
                </Box>
                <Box width="10%">
                  <SkeletonBodyText lines={1} />
                </Box>
              </BlockStack>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
        <Card roundedAbove="sm">
          <Box padding="500">
            <BlockStack gap={"500"}>
              <InlineStack gap={"300"} blockAlign="center">
                <Box>
                  <SkeletonThumbnail size="small" />
                </Box>

                <Box width="20%">
                  <SkeletonBodyText lines={2} />
                </Box>
              </InlineStack>
              <Box width="10%">
                <SkeletonBodyText lines={1} />
              </Box>
              <InlineStack align="end">
                <Box width="10%">
                  <SkeletonDisplayText size="small" />
                </Box>
              </InlineStack>
            </BlockStack>
          </Box>
        </Card>
      </BlockStack>
    </SkeletonPage>
  );
}
export default PerksSkeleton;
