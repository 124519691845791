import App from "@/App.tsx";
import i18n from "@/i18n";
import PolarisProvider from "@/common/PolarisProvider.tsx";
import ReactDOM from "react-dom/client";
import RouteMetaHandler from "@/common/RouteMetaHandler.tsx";
import store from "@/store";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { PasswordVerificationProvider } from "./common/PasswordVerificationProvider";
import { Provider as ReduxProvider } from "react-redux";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "@shopify/polaris/build/esm/styles.css";
import "@fontsource/inter";
import "@fontsource/inter/400.css";
import "@fontsource/inter/700.css";
import { initSentry } from "./utils/sentry";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM_ID,
};

TagManager.initialize(tagManagerArgs);
initSentry();

// Register Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js');
      console.log('ServiceWorker registration successful:', registration.scope);
    } catch (error) {
      console.error('ServiceWorker registration failed:', error);
      // Attempt to unregister any existing service workers
      const registrations = await navigator.serviceWorker.getRegistrations();
      for (const registration of registrations) {
        await registration.unregister();
      }
    }
  });
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <I18nextProvider i18n={i18n}>
    <ReduxProvider store={store}>
      <PolarisProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <RouteMetaHandler />
            <PasswordVerificationProvider>
              <App />
            </PasswordVerificationProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </PolarisProvider>
    </ReduxProvider>
  </I18nextProvider>
);
