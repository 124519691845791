import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "@/store";
import { UserPermissions } from "@/types/commonTypes";

interface UserState {
  userPermissions: UserPermissions | null;
}

const initialState: UserState = {
  userPermissions: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserPermissions(state, action: PayloadAction<UserPermissions>) {
      state.userPermissions = action.payload;
    },
  },
});

export const { setUserPermissions } = userSlice.actions;

export const selectUserPermissions = (state: RootState) =>
  state.user.userPermissions;

export default userSlice.reducer;
