import {
  Card,
  ContextualSaveBar,
  Page,
  BlockStack,
  SkeletonBodyText,
} from "@shopify/polaris";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { ComplianceSchema, ComplianceData } from "./types";
import SettingsPageWrapper from "../SettingsPageWrapper";
import ComplianceForm from "./Forms/ComplianceForm";
import CoreForm from "./Forms/CoreForm";

const CompliancePage = () => {
  const { t } = useTranslation();
  const { GET, PUT } = useApiRequest();
  const { clinicId } = useParams<{ clinicId: string }>();

  const [schemas, setSchemas] = useState<ComplianceSchema[]>([]);
  const [data, setData] = useState<ComplianceData>({});
  const [originalData, setOriginalData] = useState<ComplianceData>({});
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string>
  >({});

  // Fetch schema
  const {
    isLoading: isSchemaLoading,
    isSuccess: isSchemaLoaded,
    data: complianceSchema,
  } = useQuery<ComplianceSchema[]>({
    queryKey: ["compliance-schema", clinicId],
    queryFn: async () =>
      GET<ComplianceSchema[]>(
        `/api/healthcare-companies/${clinicId}/compliance/schema`
      ),
  });

  useEffect(() => {
    if (isSchemaLoaded && complianceSchema && !isSchemaLoading) {
      setSchemas(complianceSchema);
    }
  }, [isSchemaLoaded, complianceSchema, isSchemaLoading]);

  // Fetch compliance data
  const {
    isLoading: isDataLoading,
    isSuccess: isDataLoaded,
    data: ComplianceData,
  } = useQuery<ComplianceData>({
    queryKey: ["compliance-data", clinicId],
    queryFn: async () =>
      GET<ComplianceData>(`/api/healthcare-companies/${clinicId}/compliance`),
  });

  useEffect(() => {
    if (isDataLoaded && ComplianceData && !isSchemaLoading) {
      setData(ComplianceData);
      setOriginalData(ComplianceData);
    }
  }, [isDataLoaded, ComplianceData, isSchemaLoading]);

  // Validate required fields
  const validateData = () => {
    const errors: Record<string, string> = {};
    schemas.forEach((schema) => {
      schema.groups.forEach((group) => {
        group.properties.forEach((property) => {
          if (property.isRequired && !data[property.name]) {
            errors[property.name] = t("common.requiredField", {
              field_name: property.label,
            });
          }
        });
      });
    });
    setValidationErrors(errors);
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  // Save compliance data
  const saveComplianceMutation = useMutation({
    mutationFn: async () => {
      if (!validateData()) {
        console.error("Validation errors:", validationErrors);
        return;
      }

      await PUT(`/api/healthcare-companies/${clinicId}/compliance`, data);
    },
    onSuccess: () => {
      setShowSaveBar(false);
      setOriginalData(data);
    },
    onError: (error) => {
      console.error("Error saving compliance data:", error);
    },
  });

  const handleSave = () => {
    saveComplianceMutation.mutate();
  };

  const handleDiscard = () => {
    setData(originalData);
    setValidationErrors({});
    setShowSaveBar(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDataChange = (field: string, value: any) => {
    setData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setShowSaveBar(true);

    if (validationErrors[field]) {
      setValidationErrors((prev) => {
        const updatedErrors = { ...prev };
        delete updatedErrors[field];
        return updatedErrors;
      });
    }
  };

  const coreGroups = schemas
    .flatMap((schema) => schema.groups)
    .filter((group) => group.name === "core");

  const otherGroups = schemas
    .flatMap((schema) => schema.groups)
    .filter((group) => group.name !== "core");

  return (
    <SettingsPageWrapper>
      <Page title={t("compliance.title")} narrowWidth>
        {showSaveBar && (
          <ContextualSaveBar
            message={t("common.unsavedChanges")}
            saveAction={{
              onAction: handleSave,
              loading: saveComplianceMutation.isPending,
            }}
            discardAction={{
              onAction: handleDiscard,
            }}
          />
        )}

        <BlockStack gap="400">
          {isSchemaLoading || isDataLoading ? (
            <>
              <Card>
                <SkeletonBodyText lines={3} />
              </Card>
              <Card>
                <SkeletonBodyText lines={6} />
              </Card>
            </>
          ) : (
            <>
              {coreGroups.length > 0 && (
                <Card>
                  <BlockStack gap="300">
                    {coreGroups.map((coreGroup) => (
                      <CoreForm
                        key={coreGroup.name}
                        schema={coreGroup}
                        data={data}
                        onDataChange={handleDataChange}
                      />
                    ))}
                  </BlockStack>
                </Card>
              )}

              {otherGroups.length > 0 && (
                <Card>
                  <BlockStack gap="300">
                    {otherGroups.map((otherGroup) => (
                      <ComplianceForm
                        key={otherGroup.name}
                        schema={otherGroup}
                        data={data}
                        onDataChange={handleDataChange}
                        validationErrors={validationErrors}
                      />
                    ))}
                  </BlockStack>
                </Card>
              )}
            </>
          )}
        </BlockStack>
      </Page>
      <br />
    </SettingsPageWrapper>
  );
};

export default CompliancePage;
