import AuthCardWrapper from "./AuthCardWrapper";
import DividerWithText from "../../common/DividerWithText";
import TranslateLink from "../../common/TranslateLink";
import { EmailIcon, LogoGoogleIcon } from "@shopify/polaris-icons";
import { REGISTER_STEPS, ROUTES } from "../../utils/Constants";
import { useTranslation } from "react-i18next";
import "../styles.scss";

import {
  Button,
  Link,
  BlockStack,
  Text,
  Box,
  InlineStack,
} from "@shopify/polaris";

type RegisterSteps = (typeof REGISTER_STEPS)[keyof typeof REGISTER_STEPS];
type RegisterOptionsProps = {
  onSelect: (step: RegisterSteps) => void;
};

function RegisterOptions({ onSelect }: RegisterOptionsProps) {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const termsUrl = `https://www.medentic.app/${locale}/legal/terms-of-service`;
  const privacyUrl = `https://www.medentic.app/${locale}/legal/privacy`;

  return (
    <AuthCardWrapper footer={<Box
      paddingBlockStart={"500"}
      paddingBlockEnd={"500"}
      maxWidth="460px"
    >
      <Text as={"p"} alignment="start" tone="subdued">
        <TranslateLink text={t("auth.common.footer.byProceeding", { termsUrl, privacyUrl })} />
      </Text>
    </Box>}>
      <>
        <BlockStack gap="500" inlineAlign="start">
          <Box paddingBlock="200">
            <Text variant="heading2xl" as="h1">
              {t("auth.registration.common.createAccount")}
            </Text>
          </Box>
        </BlockStack>

        <BlockStack gap="300">
          <Box background="bg-fill-secondary" borderRadius="200">
            <div className="wide-button">
              <Button
                size="large"
                fullWidth
                variant="tertiary"
                icon={EmailIcon}
                onClick={() => onSelect(REGISTER_STEPS.FORM)}
              >
                {t("auth.registration.pre.signUpWithEmail")}
              </Button>
            </div>
          </Box>
          <Box background="bg-fill-secondary" borderRadius="200">
            <div className="wide-button">
              <Button
                size="large"
                fullWidth
                variant="tertiary"
                icon={LogoGoogleIcon}
                onClick={() => onSelect(REGISTER_STEPS.GOOGLE_SIGN_UP)}
              >
                {t("auth.registration.pre.signUpWithGoogle")}
              </Button>
            </div>
          </Box>
          <InlineStack align="center" blockAlign="center">
            <DividerWithText text={t("auth.login.or")} />
          </InlineStack>

          <InlineStack gap={"200"} align="center">
            <Text as="p" >
              {t("auth.registration.common.alreadyHaveAccount")}
            </Text>
            <Link url={ROUTES.LOGIN} removeUnderline>{t("auth.registration.common.logIn")}</Link>
          </InlineStack>
        </BlockStack>

      </>
    </AuthCardWrapper>
  );
}

export default RegisterOptions;
