import { Banner, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const NearingDevelopmentBanner = () => {
  const { t } = useTranslation();

  return (
    <Banner title={t("nearingDevelopmentBanner.title")} tone="warning">
      <Text as="p" tone="subdued">
        {t("nearingDevelopmentBanner.description")}
      </Text>
    </Banner>
  );
};

export default NearingDevelopmentBanner;
