import AuthCardWrapper from "./AuthCardWrapper";
import { BlockStack, Box, InlineStack, Spinner, Text } from "@shopify/polaris";
import { getFullUrl } from "@/api/baseAPI";
import { REGISTER_STEPS } from "../../utils/Constants";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles.scss";

type RegisterSteps = (typeof REGISTER_STEPS)[keyof typeof REGISTER_STEPS];
type RegisterOptionsProps = {
  onSelect: (step: RegisterSteps) => void;
};

function RegisterWithGoogle({ onSelect }: RegisterOptionsProps) {
  const { t } = useTranslation();

  useEffect(() => {
    const redirectToGoogle = async () => {
      try {
        window.location.href = getFullUrl(
          `/api/auth/register/with/google/redirect`
        );
      } catch (error) {
        console.error("Failed to redirect:", error);
        onSelect(REGISTER_STEPS.OPTIONS);
      }
    };
    setTimeout(() => {
      redirectToGoogle();
    }, 80);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthCardWrapper>
      <BlockStack align="start" gap="200">
        <Box paddingBlock="200">
          <Text variant="headingLg" as="h1">
            {t("auth.registration.google.redirecting")}
          </Text>
        </Box>
        <Text as="p" variant="bodyMd" tone="subdued">
          {t("auth.registration.google.description")}
        </Text>
        <InlineStack align="center" blockAlign="center">
          <Box padding={"1000"}>
            <Spinner />
          </Box>
        </InlineStack>
      </BlockStack>
    </AuthCardWrapper>
  );
}

export default RegisterWithGoogle;
