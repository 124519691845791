import { Page } from "@shopify/polaris";
import SettingsPageWrapper from "./SettingsPageWrapper";
import NotPlannedBanner from "@/common/NotYetPlannedBanner";

export default function SettingsLocations() {
  return (
    <SettingsPageWrapper>
      <Page>
        <NotPlannedBanner />
      </Page>
    </SettingsPageWrapper>
  );
}
