import React, { useState, useCallback, useEffect, useMemo } from "react";
import { DatePicker, Icon, Popover, TextField } from "@shopify/polaris";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { CalendarIcon } from "@shopify/polaris-icons";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);

const DB_DATE_FORMAT_STRING = "YYYY-MM-DD";

interface DatePickerFieldProps {
  value: string;
  onChange: (value: string) => void;
  label: string;
  alignment?: "left" | "center" | "right";
  disabled: boolean;
  isRequired?: boolean;
}

const DatePickerField: React.FC<DatePickerFieldProps> = ({
  value,
  onChange,
  label,
  alignment = "left",
  disabled,
  isRequired = false,
}) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");

  const [{ month, year }, setDate] = useState({ month: 10, year: 2022 });
  const [active, setActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState<{
    start: Date;
    end: Date;
  }>({
    start: dayjs().toDate(),
    end: dayjs().toDate(),
  });

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    const dateRange = {
      start: value ? dayjs(value).toDate() : dayjs().toDate(),
      end: value ? dayjs(value).toDate() : dayjs().toDate(),
    };

    setSelectedDate(dateRange);
    setDate({
      month: dayjs(dateRange.start).month(),
      year: dayjs(dateRange.start).year(),
    });
  }, [value]);

  const handleChange = useCallback(() => setActive(!active), [active]);

  const handleMonthChange = (month: number, year: number) => {
    setDate({ month, year });
  };

  const isValidDate = (dateString: string): boolean => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;
    const d = new Date(dateString);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) return false;
    return d.toISOString().slice(0, 10) === dateString;
  };

  const handleBlur = () => {
    if (inputValue) {
      if (!isValidDate(inputValue)) {
        setErrorMessage(`Must match ${DB_DATE_FORMAT_STRING} format`);
      } else {
        onChange(inputValue);
        setErrorMessage("");
      }
    }
  };

  const onInputChange = (value: string) => {
    setInputValue(value);
    setErrorMessage("");
  };

  const activator = useMemo(
    () => (
      <div onClick={handleChange} style={{ width: "100%" }}>
        <TextField
          role="combobox"
          label={label}
          prefix={<Icon source={CalendarIcon} />}
          value={inputValue}
          placeholder="YYYY-MM-DD"
          autoComplete="off"
          onBlur={handleBlur}
          onChange={onInputChange}
          error={errorMessage}
          disabled={disabled}
          requiredIndicator={isRequired}
        />
      </div>
    ),
    [selectedDate, handleChange, handleBlur, inputValue, errorMessage, label]
  );

  const onDateChange = ({ start }: { start: Date }) => {
    const dateStr = dayjs(start).format(DB_DATE_FORMAT_STRING);
    onChange(dateStr);
    setInputValue(dateStr);
    setErrorMessage("");
  };

  return (
    <Popover
      key="date-picker-button-key"
      activator={activator}
      active={active}
      onClose={handleChange}
      preferredAlignment={alignment}
    >
      <Popover.Section>
        <DatePicker
          month={month}
          year={year}
          onChange={onDateChange}
          selected={{
            start: new Date(selectedDate.start),
            end: new Date(selectedDate.end),
          }}
          onMonthChange={handleMonthChange}
          allowRange={false}
        />
      </Popover.Section>
    </Popover>
  );
};

export default DatePickerField;
