import { useQueryClient } from "@tanstack/react-query";
import { Clinic } from "../types/clinic";
import { useParams } from "react-router-dom";

export const useClinicCountry = () => {
  const queryClient = useQueryClient();
  const { clinicId } = useParams();

  const clinics =
    queryClient.getQueryData<Clinic[]>(["healthcare-companies"]) || [];
  const selectedClinic = clinics.find((clinic) => clinic.id === clinicId);

  return selectedClinic?.address;
};
