import RegisterForm from "./RegisterForm";
import RegisterOptions from "./RegisterOptions";
import RegisterWithGoogle from "./RegisterWithGoogle";
import { REGISTER_STEPS } from "../../utils/Constants";
import { useState } from "react";
import "../styles.scss";

function Register() {
  const [selectedStep, setSelectedStep] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return params.get("mode") === "form"
      ? REGISTER_STEPS.FORM
      : REGISTER_STEPS.OPTIONS;
  });

  switch (selectedStep) {
    case REGISTER_STEPS.OPTIONS:
      return <RegisterOptions onSelect={setSelectedStep} />;
    case REGISTER_STEPS.FORM:
      return <RegisterForm />;
    case REGISTER_STEPS.GOOGLE_SIGN_UP:
      return <RegisterWithGoogle onSelect={setSelectedStep} />;
    default:
      return <RegisterOptions onSelect={setSelectedStep} />;
  }
}

export default Register;
