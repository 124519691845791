import { useState, useEffect, useCallback } from "react";
import {
  Page,
  Card,
  Badge,
  Select,
  FormLayout,
  TextField,
  ContextualSaveBar,
  SkeletonBodyText,
  SkeletonDisplayText,
  Loading,
} from "@shopify/polaris";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { Member, Role } from "../types";
import { getBadgeTone } from "@/utils/utils";
import SettingsPageWrapper from "./SettingsPageWrapper";
import useFormattedDate from "@/hooks/useFormattedDate";

import {
  PersonRemoveIcon,
  StatusActiveIcon,
  XCircleIcon,
} from "@shopify/polaris-icons";

const EditMember = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { clinicId, memberId } = useParams<{
    clinicId: string;
    memberId: string;
  }>();
  const queryClient = useQueryClient();
  const { GET, PATCH, DELETE } = useApiRequest();

  const [selectedRole, setSelectedRole] = useState<string>("");
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const {
    data: memberData,
    isLoading: isLoadingMember,
    isFetching: isFetchingMember,
  } = useQuery<Member>({
    queryKey: ["member", clinicId, memberId],
    queryFn: () =>
      GET(`/api/healthcare-companies/${clinicId}/team/members/${memberId}`),
  });

  const { data: rolesData = [], isLoading: isLoadingRoles } = useQuery<Role[]>({
    queryKey: ["roles", clinicId],
    queryFn: () =>
      GET(`/api/healthcare-companies/${clinicId}/team/roles-and-permissions`),
  });

  useEffect(() => {
    if (memberData?.userPermissions?.roleId) {
      setSelectedRole(memberData.userPermissions.roleId.toString());
    }
  }, [memberData]);

  const updateRoleMutation = useMutation({
    mutationFn: (roleId: string) =>
      PATCH(`/api/healthcare-companies/${clinicId}/team/members/${memberId}`, {
        roleId: parseInt(roleId, 10),
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["member", clinicId, memberId],
      });
      queryClient.invalidateQueries({
        queryKey: ["teamMembers", clinicId],
      });
      setIsDirty(false);
    },
  });

  const updateStatusMutation = useMutation({
    mutationFn: (status: number) =>
      PATCH(`/api/healthcare-companies/${clinicId}/team/members/${memberId}`, {
        status,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["teamMembers", clinicId],
      });
      queryClient.invalidateQueries({
        queryKey: ["member", clinicId, memberId],
      });
    },
  });

  const deleteMemberMutation = useMutation({
    mutationFn: () =>
      DELETE(`/api/healthcare-companies/${clinicId}/team/members/${memberId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["teamMembers", clinicId] });
      navigate(`/c/${clinicId}/settings/members-permissions`);
    },
  });

  const handleRoleChange = useCallback((value: string) => {
    setSelectedRole(value);
    setIsDirty(true);
  }, []);

  const handleActivateDeactivate = useCallback(() => {
    const newStatus = memberData?.status === 1 ? 0 : 1;
    updateStatusMutation.mutate(newStatus);
  }, [memberData, updateStatusMutation]);

  const handleSave = useCallback(() => {
    updateRoleMutation.mutate(selectedRole);
  }, [selectedRole, updateRoleMutation]);

  const handleRemove = useCallback(() => {
    deleteMemberMutation.mutate();
  }, [deleteMemberMutation]);

  const invitedBy =
    memberData?.inviter?.firstName || memberData?.inviter?.lastName
      ? `${memberData?.inviter?.firstName} ${memberData?.inviter?.lastName}`
      : undefined;
  const { formattedDate: invitedOn } = useFormattedDate({
    date: memberData?.createdAt ?? "",
  });
  const roleOptions = rolesData.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));

  return (
    <SettingsPageWrapper>
      {isDirty && (
        <ContextualSaveBar
          message={t("common.unsavedChanges")}
          saveAction={{
            onAction: handleSave,
            loading: updateRoleMutation.isPending,
          }}
          discardAction={{
            onAction: () => setIsDirty(false),
          }}
        />
      )}
      {isFetchingMember && <Loading />}
      {isLoadingMember || isLoadingRoles ? (
        <Page>
          <Loading />
          <SkeletonDisplayText />
          <br />
          <Card>
            <SkeletonBodyText lines={7} />
          </Card>
        </Page>
      ) : (
        <Page
          backAction={{
            onAction: () =>
              navigate(`/c/${clinicId}/settings/members-permissions`),
          }}
          title={`${memberData?.firstName} ${memberData?.lastName}`}
          titleMetadata={
            <Badge
              tone={getBadgeTone(
                memberData?.status === 1 ? "active" : "inactive"
              )}
            >
              {t(`common.${memberData?.status === 1 ? "active" : "inactive"}`)}
            </Badge>
          }
          subtitle={
            invitedBy
              ? t("membersAndPermissions.invitedBy", {
                  name: invitedBy,
                  date: invitedOn,
                })
              : t("membersAndPermissions.invitedOn", {
                  date: invitedOn,
                })
          }
          secondaryActions={[
            {
              content:
                memberData?.status === 1
                  ? t("common.deactivate")
                  : t("common.activate"),
              onAction: handleActivateDeactivate,
              loading: updateStatusMutation.isPending,
              icon: memberData?.status === 1 ? XCircleIcon : StatusActiveIcon,
            },
            {
              content: t("common.remove"),
              onAction: handleRemove,
              loading: deleteMemberMutation.isPending,
              destructive: true,
              icon: PersonRemoveIcon,
            },
          ]}
        >
          <Card>
            <FormLayout>
              <TextField
                label={t("common.email")}
                value={memberData?.email ?? ""}
                disabled
                autoComplete="off"
              />
              <Select
                label={t("common.role")}
                options={roleOptions}
                value={selectedRole}
                onChange={handleRoleChange}
                disabled={updateRoleMutation.isPending}
                helpText={t("membersAndPermissions.roleFooter")}
              />
            </FormLayout>
          </Card>
        </Page>
      )}
    </SettingsPageWrapper>
  );
};

export default EditMember;
