import useApiRequest from "./useApiRequest";
import { ErrorResponse } from "@/types/commonTypes";
import { PerksResponse } from "@/clinic/pages/perks/types";
import { useQuery } from "@tanstack/react-query";

const usePerks = () => {
  const { GET } = useApiRequest();

  const fetchPerks = async (): Promise<PerksResponse> => {
    return GET<PerksResponse>("/api/perks");
  };

  const { data, isLoading, isFetching, error } = useQuery<
    PerksResponse,
    ErrorResponse
  >({ queryKey: ["perks"], queryFn: fetchPerks, enabled: true });

  return {
    perks: data?.perks || [],
    categories: data?.categories || [],
    companies: data?.companies || [],
    isLoading,
    isFetching,
    error,
  };
};

export default usePerks;
