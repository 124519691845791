/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BlockStack,
  Box,
  Button,
  Card,
  IndexFilters,
  IndexFiltersMode,
  IndexTable,
  InlineStack,
  Loading,
  Page,
  Pagination,
  useIndexResourceState,
  useSetIndexFiltersMode,
} from "@shopify/polaris";
import type {
  TabProps,
  FilterInterface,
  AppliedFilterInterface,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@tanstack/react-query";
import { debounce } from "lodash";

import AppPageWrapper from "@/common/AppPageWrapper";
import TabsTableSkeleton from "@/common/Skeletons/TabsTableSkeleton";
import useApiRequest from "@/hooks/useApiRequest";
import usePatients from "@/hooks/usePatients";
import usePatientsExport from "@/hooks/usePatientsExport";
import AddPatientModal from "./AddPatientModal";
import { GroupData } from "../types";
import { useDispatch } from "react-redux";
import { clearFilters, setTab } from "../patientSlice";
import { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import { ROOTS } from "@/utils/Constants";
import { sortOptions } from "../utils";
import { PropertyTypeRenderer } from "@/common/PropertyTypeRenderer/PropertyTypeRenderer";

import type { PatientView } from "../types";
import EditColumns from "./EditColumns";
import PatientCellRenderer from "./PatientCellRenderer";

const systemFields = ["id", "core_private_note", "core_private_note_tone"];
interface Column {
  name: string;
  label: string;
  type: string;
  visible: boolean;
}

function PatientList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clinicId } = useParams<{ clinicId: string }>();

  const { mode, setMode } = useSetIndexFiltersMode();
  const [editedColumns, setEditedColumns] = useState<Column[]>([]);
  const [selectedViewIndex, setSelectedViewIndex] = useState(0);
  const [showAddPatient, setShowAddPatient] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: any }>(
    {}
  );
  const [localQuery, setLocalQuery] = useState(appliedFilters.searchQuery);

  const [newBViewTobeSelected, setNewBViewTobeSelected] = useState("");

  const { GET, POST, DELETE, PATCH, PUT } = useApiRequest();
  const { data: schemaData = [], isLoading: isLoadingSchema } = useQuery<
    GroupData[]
  >({
    queryKey: ["patient-schema-for-list", clinicId],
    queryFn: () => GET(`/api/healthcare-companies/${clinicId}/patients/schema`),
    enabled: Boolean(clinicId),
  });

  const {
    data: viewsResponse = [],
    isLoading: isLoadingViews,
    isSuccess: isViewsLoaded,
    refetch: refetchViews,
  } = useQuery<PatientView[]>({
    queryKey: ["patient-views", clinicId],
    queryFn: async () => {
      return GET(`/api/healthcare-companies/${clinicId}/patients/views`);
      // Suppose it returns an array of PatientView
    },
    enabled: Boolean(clinicId),
  });

  useEffect(() => {
    if (newBViewTobeSelected && isViewsLoaded && !isLoadingViews) {
      const newViewIndex = viewsResponse.findIndex(
        (vn) => vn.name === newBViewTobeSelected
      );
      if (newViewIndex > -1) {
        setSelectedViewIndex(newViewIndex + 1);
        setNewBViewTobeSelected("");
      }
    }
  }, [newBViewTobeSelected, isViewsLoaded, isLoadingViews, viewsResponse]);

  const viewsData = useMemo(() => {
    return [
      { name: t("common.all"), isLocked: true, id: "all" },
      ...viewsResponse,
    ];
  }, [t, viewsResponse]);

  useEffect(() => {
    if (viewsData[selectedViewIndex]) {
      setLocalQuery(viewsData[selectedViewIndex]?.filters?.searchQuery);
    }
  }, [viewsData, selectedViewIndex]);

  const dynamicSchemaFields = useMemo(() => {
    if (!schemaData.length) return [];
    const groups = schemaData[0]?.groups || [];
    const columns: Column[] = [];

    // Get the current view's fields
    const currentView =
      selectedViewIndex > 0 ? viewsData[selectedViewIndex] : null;

    const viewFields = currentView?.filters?.fields || [];

    groups.forEach((g) => {
      g.properties.forEach((prop) => {
        if (!systemFields.includes(prop.name)) {
          columns.push({
            name: prop.name,
            label: prop.label,
            type: prop.renderer?.type ?? "",
            visible: selectedViewIndex === 0 || viewFields.includes(prop.name),
          });
        }
      });
    });

    // Sort columns based on the order in viewFields
    return columns.sort((a, b) => {
      const indexA = viewFields.indexOf(a.name);
      const indexB = viewFields.indexOf(b.name);
      return indexA - indexB;
    });
  }, [schemaData, selectedViewIndex, viewsData]);

  /** 4) Query/pagination logic with usePatients */
  const {
    data: {
      patients = [],
      paginate = { total: 0, perPage: 50, currentPage: 1, lastPage: 1 },
      filter: responseFilters,
    } = {},
    isLoading: isPatientsLoading,
  } = usePatients(clinicId, {
    appliedFilters,
    columns:
      selectedViewIndex !== 0
        ? dynamicSchemaFields.map((prop) => prop.name)
        : [],
  });

  useEffect(() => {
    setEditedColumns(dynamicSchemaFields);
  }, [dynamicSchemaFields]);

  const visibleColumns = useMemo(() => {
    return dynamicSchemaFields.filter(
      (prop) => prop.visible && responseFilters?.fields.includes(prop.name)
    );
  }, [dynamicSchemaFields, responseFilters]);

  const tableHeadings: NonEmptyArray<IndexTableHeading> = useMemo(() => {
    if (visibleColumns.length === 0) {
      return [{ title: "" }];
    }
    return visibleColumns.map((prop) => ({
      title: prop.label,
    })) as NonEmptyArray<IndexTableHeading>;
  }, [visibleColumns]);

  const createViewMutation = useMutation({
    mutationFn: async (name: string) => {
      return await POST<PatientView>(
        `/api/healthcare-companies/${clinicId}/patients/views`,
        {
          name,
          filters: {
            ...appliedFilters,
            fields: editedColumns
              .filter((c) => c.visible)
              .map((col) => col.name),
          },
        }
      );
    },
    onSuccess: (data: PatientView) => {
      setNewBViewTobeSelected(data.name);
      refetchViews();
    },
  });

  /** Save (edit existing view) => PATCH /patients/views/{id} */
  const renameViewMutation = useMutation({
    mutationFn: async ({ id, newData }: { id: string; newData: any }) => {
      return PATCH(
        `/api/healthcare-companies/${clinicId}/patients/views/${id}`,
        { ...newData }
      );
    },
    onSuccess: () => {
      refetchViews();
    },
  });

  /** Save (edit existing view) => PATCH /patients/views/{id} */
  const updateViewMutation = useMutation({
    mutationFn: async ({ id, newData }: { id: string; newData: any }) => {
      return PUT(`/api/healthcare-companies/${clinicId}/patients/views/${id}`, {
        ...newData,
      });
    },
    onSuccess: () => {
      refetchViews();
    },
  });

  /** Duplicate => POST a new view with same config but different name */
  const duplicateViewMutation = useMutation({
    mutationFn: async ({ name, filters }: { name: string; filters: any }) => {
      return await POST<PatientView>(
        `/api/healthcare-companies/${clinicId}/patients/views`,
        {
          name,
          filters: { ...filters },
        }
      );
    },
    onSuccess: (data: PatientView) => {
      setNewBViewTobeSelected(data.name);
      refetchViews();
    },
  });

  /** Delete => DELETE /patients/views/{id} */
  const deleteViewMutation = useMutation({
    mutationFn: async (id: string) => {
      return DELETE(
        `/api/healthcare-companies/${clinicId}/patients/views/${id}`
      );
    },
    onSuccess: () => {
      refetchViews();
      setSelectedViewIndex(0);
    },
  });

  const handleRenameView = async (index: number, newName: string) => {
    const item = viewsData[index];
    if (!item) return false;
    await renameViewMutation.mutateAsync({
      id: item.id,
      newData: { name: newName },
    });
    return true;
  };

  const handleDuplicateView = async (
    _originalIndex: number,
    newName: string
  ) => {
    // Could read the original's columns/filters
    // For demonstration, we pass just { name: newName }
    const dataToDuplicate = viewsData[_originalIndex].filters;
    await duplicateViewMutation.mutateAsync({
      name: newName,
      filters: dataToDuplicate,
    });
    return true;
  };

  const handleDeleteView = async (index: number) => {
    const item = viewsData[index];
    if (!item) return false;
    await deleteViewMutation.mutateAsync(item.id);
    // after success => refetchViews
    return true;
  };

  // Create new or "Save as"
  const handleCreateNewView = async (value: string) => {
    await createViewMutation.mutateAsync(value);
    // e.g. setSelectedViewIndex(viewsData.length) after the new one is created
    return true;
  };

  // Save changes to existing => e.g. reorder columns
  const handleSaveExistingView = async () => {
    const item = viewsData[selectedViewIndex];
    if (!item) return false;
    // e.g. call updateViewMutation with new columns
    // For demonstration:
    await updateViewMutation.mutateAsync({
      id: item.id,
      newData: {
        name: item.name,
        filters: {
          ...appliedFilters,
          fields: editedColumns.filter((c) => c.visible).map((col) => col.name),
        },
      },
    });
    return true;
  };

  // Build polaris-style TabProps
  const tabs: TabProps[] = viewsData.map((view, index) => ({
    content: view.name,
    index,
    id: view.id,
    isLocked: view.isLocked,
    // onAction can be a function if needed
    onAction: () => {
      // maybe do something when user clicks the tab action
    },
    actions: view.isLocked
      ? []
      : [
          {
            type: "rename",
            onAction: () => {},
            onPrimaryAction: async (newVal: string): Promise<boolean> => {
              await handleRenameView(index, newVal);
              return true;
            },
          },
          {
            type: "duplicate",
            onPrimaryAction: async (newVal: string): Promise<boolean> => {
              await handleDuplicateView(index, newVal);
              return true;
            },
          },
          {
            type: "delete",
            onPrimaryAction: async () => {
              await handleDeleteView(index);
              return true;
            },
          },
        ],
  }));

  const [sortSelected, setSortSelected] = useState(["name asc"]);

  const primaryAction =
    selectedViewIndex === 0
      ? {
          type: "save-as" as "save-as" | "save" | "cancel",
          onAction: handleCreateNewView,
          disabled: false,
          loading: createViewMutation.isPending,
        }
      : {
          type: "save" as "save-as" | "save" | "cancel",
          onAction: handleSaveExistingView,
          disabled: false,
          loading: updateViewMutation.isPending,
        };

  const { refetch: exportPatients, isFetching: isExporting } =
    usePatientsExport(clinicId, {
      appliedFilters,
      columns:
        selectedViewIndex !== 0 ? visibleColumns.map((prop) => prop.name) : [],
    });

  const { total, perPage, currentPage, lastPage } = paginate;
  const handleNextPage = () => {
    if (currentPage < lastPage) {
      handleFiltersChange("page", currentPage + 1);
    }
  };
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handleFiltersChange("page", currentPage - 1);
    }
  };

  /** rowMarkup as before */
  const { selectedResources, allResourcesSelected, handleSelectionChange } =
    useIndexResourceState(
      patients.map((p) => ({
        id: p.id,
      }))
    );

  const rowMarkup = useMemo(() => {
    return patients.map((p, index) => (
      <IndexTable.Row
        id={p.id}
        key={p.id}
        position={index}
        selected={selectedResources.includes(p.id)}
        onClick={() => {
          navigate(`${ROOTS.CLINIC}/${clinicId}/patients/${p.id}`);
        }}
      >
        {visibleColumns.map((column) => (
          <IndexTable.Cell key={column.name}>
            <PatientCellRenderer column={column} patient={p} />
          </IndexTable.Cell>
        ))}
      </IndexTable.Row>
    ));
  }, [patients, selectedResources, visibleColumns, clinicId, navigate]);

  const handleFiltersChange = useCallback((key: string, value: any) => {
    setAppliedFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  }, []);

  const dynamicFilters = useMemo(() => {
    if (!schemaData.length) return [];
    const groups = schemaData[0]?.groups || [];
    const filters: FilterInterface[] = [];

    groups.forEach((g) => {
      g.properties.forEach((prop: any) => {
        // Create base filter object
        const baseFilter: FilterInterface = {
          key: prop.name,
          label: prop.label,
          filter: (
            <PropertyTypeRenderer
              key={prop.name}
              id={prop.renderer?.id ?? "textInput"}
              label={prop.label}
              value={appliedFilters[prop.name]}
              onChange={(val) => handleFiltersChange(prop.name, val)}
              disabled={false}
              helpText={prop.description}
              placeholder=""
              comboOptions={prop.options ?? []}
            />
          ),
        };

        filters.push(baseFilter);
      });
    });

    // Sort filters alphabetically by label
    return filters.sort((a, b) => a.label.localeCompare(b.label));
  }, [schemaData, appliedFilters, handleFiltersChange]);

  const handleFiltersClearAll = useCallback(() => {
    setAppliedFilters({});
    dispatch(clearFilters());
  }, [dispatch]);

  /** Query input */
  const debouncedSetQuery = useCallback(
    debounce((val: string) => {
      handleFiltersChange("searchQuery", val);
    }, 500),
    [handleFiltersChange]
  );
  const handleQueryChange = useCallback(
    (val: string) => {
      setLocalQuery(val);
      debouncedSetQuery(val);
    },
    [debouncedSetQuery]
  );

  const handleQueryClear = useCallback(() => {
    setLocalQuery("");
    debouncedSetQuery("");
  }, [dispatch]);

  const onHandleCancel = () => {};

  const appliedFiltersArray: AppliedFilterInterface[] = Object.entries(
    appliedFilters
  ).map(([key, value]) => ({
    key,
    label: `${
      dynamicFilters.find((f) => f.key === key)?.label ?? key
    }: ${String(value)}`,
    onRemove: () => {
      const newFilters = { ...appliedFilters };
      delete newFilters[key];
      setAppliedFilters(newFilters);
    },
  }));

  // Add effect to apply filters when tab/view changes
  useEffect(() => {
    if (selectedViewIndex > 0) {
      const selectedView = viewsData[selectedViewIndex];
      if (selectedView?.filters) {
        if (selectedView.filters.tab) {
          dispatch(setTab(selectedView.filters.tab));
        }

        if (selectedView.filters) {
          setAppliedFilters(selectedView.filters);
        }
      }
    } else {
      handleFiltersClearAll();
    }
  }, [selectedViewIndex, viewsData, dispatch, handleFiltersClearAll]);

  const handleExport = async () => {
    const dataUrl = await exportPatients();
    // Create an anchor element
    if (!dataUrl.data?.url) return;
    const link = document.createElement("a");
    link.href = dataUrl.data?.url;
    link.download = "patients-list.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <AppPageWrapper>
      <Page
        fullWidth
        title={t("patients.title")}
        primaryAction={
          <Button onClick={() => setShowAddPatient(true)}>
            {t("patients.addPatient")}
          </Button>
        }
        secondaryActions={[
          { content: t("patients.import"), onAction: () => {}, disabled: true },
          {
            content: t("patients.export"),
            onAction: () => handleExport(),
            disabled: patients.length === 0,
            loading: isExporting,
          },
        ]}
      >
        {isPatientsLoading || isLoadingViews || isLoadingSchema ? (
          <TabsTableSkeleton />
        ) : (
          <BlockStack align="center">
            <Card padding="0">
              <IndexFilters
                loading={
                  createViewMutation.isPending ||
                  updateViewMutation.isPending ||
                  isPatientsLoading
                }
                sortOptions={sortOptions(t)} // or define your own
                sortSelected={sortSelected}
                onSort={(val) => setSortSelected(val)}
                queryValue={localQuery}
                queryPlaceholder={
                  selectedViewIndex === 0
                    ? t("patients.searchPlaceholderAll")
                    : t("patients.searchPlaceholderView", {
                        view: viewsData[selectedViewIndex].name,
                      })
                }
                onQueryChange={handleQueryChange}
                onQueryClear={handleQueryClear}
                primaryAction={primaryAction}
                cancelAction={{
                  onAction: onHandleCancel,
                  disabled: false,
                  loading: false,
                }}
                tabs={tabs}
                selected={selectedViewIndex}
                onSelect={setSelectedViewIndex}
                canCreateNewView
                onCreateNewView={handleCreateNewView}
                filters={dynamicFilters}
                appliedFilters={appliedFiltersArray}
                onClearAll={handleFiltersClearAll}
                mode={mode}
                setMode={setMode}
                showEditColumnsButton
              />
              {mode === IndexFiltersMode.EditingColumns ? (
                <EditColumns
                  data={patients}
                  onColumnsChange={setEditedColumns}
                  columns={editedColumns}
                />
              ) : (
                <IndexTable
                  resourceName={{
                    singular: t("patients.patient"),
                    plural: t("patients.title"),
                  }}
                  itemCount={visibleColumns.length > 0 ? patients.length : 0}
                  selectedItemsCount={
                    allResourcesSelected ? "All" : selectedResources.length
                  }
                  onSelectionChange={handleSelectionChange}
                  headings={tableHeadings}
                >
                  {rowMarkup}
                </IndexTable>
              )}
            </Card>
            {/* Pagination */}
            <Box paddingBlockEnd="300" />
            <InlineStack align="center">
              <Pagination
                onPrevious={handlePreviousPage}
                onNext={handleNextPage}
                hasPrevious={currentPage > 1}
                hasNext={currentPage < lastPage}
                label={`${Math.min(
                  (currentPage - 1) * perPage + 1,
                  total
                )}-${Math.min(currentPage * perPage, total)} of ${total}`}
              />
            </InlineStack>
          </BlockStack>
        )}
      </Page>

      {(isPatientsLoading || isLoadingViews || isLoadingSchema) && <Loading />}

      {showAddPatient && (
        <AddPatientModal
          open={showAddPatient}
          onClose={() => setShowAddPatient(false)}
        />
      )}
    </AppPageWrapper>
  );
}

export default PatientList;
