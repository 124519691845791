import PasswordProtectedButton from "@/common/PasswordProtectedButton";
import React, { useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { CheckIcon } from "@shopify/polaris-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Select,
  Modal,
  BlockStack,
  InlineStack,
  Icon,
  InlineError,
  Box,
  Text,
} from "@shopify/polaris";

interface PhoneFieldProps {
  phoneNumber: string;
  phoneCountryCode: string;
  phoneVerified: boolean;
  countries: { phoneCountryCode: string; name: string }[];
}

const PhoneField: React.FC<PhoneFieldProps> = ({
  phoneNumber,
  phoneCountryCode,
  phoneVerified,
  countries,
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { PATCH } = useApiRequest();

  const [newPhone, setNewPhone] = useState<string>("");
  const [newCountryCode, setNewCountryCode] =
    useState<string>(phoneCountryCode);
  const [showPhoneChangeModal, setShowPhoneChangeModal] =
    useState<boolean>(false);

  const updatePhone = useMutation({
    mutationFn: (phoneData: Record<string, string | null | undefined>) =>
      PATCH("/api/user/me/phone", phoneData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      setShowPhoneChangeModal(false);
    },
  });

  return (
    <BlockStack gap={"100"}>
      <TextField
        label={t("userProfile.phone")}
        value={phoneNumber}
        disabled
        autoComplete="tel"
        prefix={phoneCountryCode}
        connectedLeft={
          <Select
            label={t("userProfile.countryCode")}
            value={phoneCountryCode}
            options={countries.map((c) => ({
              label: `${c.name}`,
              value: c.phoneCountryCode,
            }))}
            labelHidden
            disabled
          />
        }
        suffix={
          <PasswordProtectedButton
            onVerified={(isVerified) => {
              if (isVerified) {
                setNewPhone(phoneNumber);
                setNewCountryCode(phoneCountryCode);
                setShowPhoneChangeModal(true);
              }
            }}
          />
        }
      />
      {phoneNumber && (
        <InlineStack gap={"100"} align="start" blockAlign="start">
          {phoneVerified ? (
            <>
              <Box>
                <Icon source={CheckIcon} tone="success" />
              </Box>
              <Text as="p" tone="success">
                {t("userProfile.verified")}
              </Text>
            </>
          ) : (
            <InlineError
              message={t("userProfile.notVerified")}
              fieldID="phone"
            />
          )}
        </InlineStack>
      )}

      {/* Phone Change Modal */}
      <Modal
        open={showPhoneChangeModal}
        onClose={() => setShowPhoneChangeModal(false)}
        title={t("userProfile.changePhoneNumber")}
        primaryAction={{
          content: t("userProfile.changePhoneNumberCTA"),
          loading: updatePhone.isPending,
          onAction: () => {
            updatePhone.mutate({
              phoneNumber: newPhone,
              phoneCountryCode: newCountryCode,
            });
          },
        }}
        secondaryActions={[
          {
            content: t("common.cancel"),
            onAction: () => setShowPhoneChangeModal(false),
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap={"400"}>
            <Text as="p" tone="subdued">
              {t("userProfile.changePhoneNumberDescription")}
            </Text>
            <TextField
              autoFocus
              label={t("userProfile.phone")}
              value={newPhone}
              onChange={setNewPhone}
              autoComplete="tel"
              prefix={newCountryCode}
              connectedLeft={
                <Select
                  label={t("userProfile.countryCode")}
                  value={newCountryCode}
                  onChange={setNewCountryCode}
                  options={countries.map((c) => ({
                    label: `${c.name}`,
                    value: c.phoneCountryCode,
                  }))}
                  labelHidden
                />
              }
            />
          </BlockStack>
        </Modal.Section>
      </Modal>
    </BlockStack>
  );
};

export default PhoneField;
