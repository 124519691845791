import Cookies from "js-cookie";
import i18n from "@/i18n";
import useNavigateToClinic from "./useNavigateToClinic";
import { ROOTS, USER_ROUTES } from "@/utils/Constants";
import { setIsInitializingApp } from "@/reducers/appSlice";
import { setUserPermissions } from "@/user/userSlice";
import { useAfterLoginTask } from "./useAfterLoginTask";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useHealthcareCompanies } from "./useHealthcareCompanies";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "@/hooks/usePermission";
import { useUser } from "@/hooks/useUser";
import dayjs from "dayjs";
import { getLocalStorage } from "@/utils/localStorageUtils";
import { useSudoUser } from "./useSudoUser";
/* eslint-disable react-hooks/exhaustive-deps */

export const useInitializeApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { navigateToClinic } = useNavigateToClinic();

  // why /v/ott check? reason: in case of sudo with existing session don't call the APIs and enter into app initialization until we get new token from ott
  const hasToken =
    !!Cookies.get("access_token") && !location.pathname.includes("/v/ott");
  const hasInviteToken = !!getLocalStorage("invite_token");

  const {
    data: sudoUser,
    isLoading: isSudoLoading,
    isSuccess: isSudoUserLoaded,
  } = useSudoUser(hasToken);
  const {
    user,
    isLoading: isUserLoading,
    isError: isUserError,
  } = useUser(hasToken);

  // ignore afterLoginTask call if we have inviteToken in localstorage
  const afterLoginTask = useAfterLoginTask(
    hasToken && isSudoUserLoaded,
    !!sudoUser?.sudo
  );
  const {
    data: tdoTask,
    isFetching: isTDOLoading,
    isSuccess: isTDOLoaded,
  } = afterLoginTask;

  const {
    permissions,
    isFetching: isPermissionsLoading,
    isError: isPermissionError,
  } = usePermissions(
    hasToken && !tdoTask?.next && !isTDOLoading && isTDOLoaded
  );

  // changing isFetching to IsLoading so that we don;t get loading window on update company
  const { companies, isLoading: isCompaniesLoading } = useHealthcareCompanies(
    hasToken && !tdoTask?.next && !isTDOLoading && isTDOLoaded
  );

  const isLoading =
    isPermissionsLoading ||
    isUserLoading ||
    isCompaniesLoading ||
    isTDOLoading ||
    isSudoLoading;

  useEffect(() => {
    dispatch(setIsInitializingApp(isLoading));
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (isPermissionError || isUserError) {
      dispatch(setIsInitializingApp(false));
    }
  }, [dispatch, isPermissionError, isUserError, navigate]);

  useEffect(() => {
    if (user && !isUserLoading) {
      const savedLanguage = localStorage.getItem("selectedLanguage");

      if (!savedLanguage) {
        localStorage.setItem("selectedLanguage", user.language.iso);
        i18n.changeLanguage(user.language.iso);
        dayjs.locale(user.language.iso);
      }
    }
  }, [user, isUserLoading]);

  useEffect(() => {
    if (!isTDOLoading && isTDOLoaded) {
      if (tdoTask?.next === "questionnaire") {
        navigate(ROOTS.USER + "/" + USER_ROUTES.QUESTIONNAIRE);
      } else if (tdoTask?.next === "invites") {
        navigate(ROOTS.USER + "/" + USER_ROUTES.INVITES);
      } else if (tdoTask?.next === "autoJoin") {
        navigate(ROOTS.USER + "/" + USER_ROUTES.AUTOJOIN);
      }
    }
  }, [isTDOLoading, isTDOLoaded]);

  useEffect(() => {
    if (permissions && !isPermissionsLoading && !hasInviteToken) {
      dispatch(setUserPermissions(permissions));
      if (
        permissions.length === 0 &&
        permissions.includes("hasCompany") &&
        !isCompaniesLoading
      ) {
        navigate(ROOTS.CLINIC);
      } else if (
        permissions.length === 1 &&
        permissions.includes("hasCompany") &&
        !isCompaniesLoading
      ) {
        // clinic mode
        // get clinics and goto 1st clinic if its 1 else show clinics list
        navigateToClinic(companies);
      } else if (
        permissions.length === 1 &&
        (permissions.includes("hasRecords") ||
          permissions.includes("hasInvoices"))
      ) {
        dispatch(setIsInitializingApp(false));
        navigate(ROOTS.PATIENT);
      } else if (
        permissions.length > 1 &&
        permissions.includes("hasCompany") &&
        (permissions.includes("hasRecords") ||
          permissions.includes("hasInvoices"))
      ) {
        dispatch(setIsInitializingApp(false));
        navigate(ROOTS.USER + "/" + USER_ROUTES.APP_MODES);
      }
    }
  }, [permissions, isPermissionsLoading, isCompaniesLoading]);
};
