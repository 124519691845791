// src/clinic/pages/patient/components/PatientCellRenderer.tsx
import React from "react";
import {
  Avatar,
  Box,
  Icon,
  InlineStack,
  Tooltip,
  Badge,
} from "@shopify/polaris";
import { NoteIcon } from "@shopify/polaris-icons";
import RenderDate from "@/common/RenderDate";
import { getToneOrUndefined } from "../utils";
import { Patient } from "../types";
import { useTranslation } from "react-i18next";

interface PatientCellRendererProps {
  column: { name: string; type: string };
  patient: Patient;
}

const PatientCellRenderer: React.FC<PatientCellRendererProps> = ({
  column,
  patient,
}) => {
  const { t } = useTranslation();

  const cellValue = patient[column.name] ?? "";

  const renderCellContent = () => {
    if (column?.type === "date") {
      return <RenderDate date={String(cellValue)} />;
    }

    if (column?.name === "core_avatar") {
      const initials = [
        patient["core_firstname"]?.charAt(0),
        patient["core_lastname"]?.charAt(0),
      ]
        .filter(Boolean)
        .join("");
      return (
        <Avatar
          source={cellValue}
          initials={initials}
          name={patient["core_firstname"]}
        />
      );
    }

    if (column?.type === "bool") {
      return (
        <Badge progress={cellValue ? "complete" : "incomplete"}>
          {cellValue ? t("common.yes") : t("common.no")}
        </Badge>
      );
    }

    return String(cellValue);
  };

  return (
    <InlineStack gap={"200"} wrap={false} blockAlign="center">
      {renderCellContent()}
      {column?.name === "core_avatar" && patient["core_private_note"] && (
        <Box>
          <Tooltip content={patient["core_private_note"]}>
            <Icon
              source={NoteIcon}
              tone={getToneOrUndefined(patient["core_private_note_tone"])}
            />
          </Tooltip>
        </Box>
      )}
    </InlineStack>
  );
};

export default PatientCellRenderer;
