import React from "react";
import { getUserNavigationItems } from "../utils";
import { Navigation } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

const UserNavigation: React.FC = () => {
  const { t } = useTranslation();

  const userNavigationItems = getUserNavigationItems(t, location.pathname);

  return (
    <Navigation location={window.location.pathname}>
      <Navigation.Section items={userNavigationItems} />
    </Navigation>
  );
};

export default UserNavigation;
