/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useMemo, useCallback } from "react";
import { Modal, BlockStack, SkeletonBodyText, Box } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { Property, GroupData, Group } from "../types";
import useApiRequest from "@/hooks/useApiRequest";
import { PropertyTypeRenderer } from "@/common/PropertyTypeRenderer/PropertyTypeRenderer";

interface AddPatientModalProps {
  open: boolean;
  onClose: () => void;
}

const AddPatientModal: React.FC<AddPatientModalProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { GET, POST } = useApiRequest();
  const queryClient = useQueryClient();
  const params = useParams();
  const clinicId = params.clinicId;

  const { data: groupsData = [], isLoading: isSchemaLoading } = useQuery<
    GroupData[]
  >({
    queryKey: ["patient-schema"],
    queryFn: () => GET(`/api/healthcare-companies/${clinicId}/patients/schema`),
  });

  // Filter & sort
  const groups = useMemo(
    () => (groupsData.length ? groupsData[0]?.groups : []),
    [groupsData]
  );

  const groupedProperties = useMemo(() => {
    if (!groups || groups.length === 0) return [];
    return groups.map((g: Group) => {
      const filtered = g.properties
        .filter((p) => p.isInCreateForm)
        .sort((a, b) => (a.displayOrder ?? 999) - (b.displayOrder ?? 999));
      return {
        ...g,
        properties: filtered,
      };
    });
  }, [groups]);

  // Initialize form values with defaultValue or empty
  const initialFormValues = useMemo(() => {
    const init: Record<string, any> = {};
    groupedProperties.forEach((group) => {
      group.properties.forEach((p) => {
        if (p.defaultValue !== undefined) {
          init[p.name] = p.defaultValue;
        } else {
          init[p.name] = undefined;
        }
      });
    });
    return init;
  }, [groupedProperties]);

  const [formValues, setFormValues] =
    useState<Record<string, any>>(initialFormValues);

  // Errors: { [propName]: "Error message" }
  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleChange = useCallback(
    (propName: string, newValue: any) => {
      setFormValues((prev) => ({ ...prev, [propName]: newValue }));
      setErrors((prev) => ({ ...prev, [propName]: "" }));
    },
    [setFormValues, setErrors]
  );

  // Create mutation
  const createPatientMutation = useMutation({
    mutationFn: async (payload: Record<string, any>) => {
      return POST(`/api/healthcare-companies/${clinicId}/patients`, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["patients"] });
      onClose();
    },
    onError: (err: any) => {
      const error = err?.response?.data?.error;
      console.error(error);
      if (error.inline && error.display) {
        setErrors((prev) => ({
          ...prev,
          [error.inline]: error.display,
        }));
      }
    },
  });

  const validateForm = () => {
    const newErrors: Record<string, string> = {};

    groupedProperties.forEach((group) => {
      group.properties.forEach((p: Property) => {
        if (p.isRequired) {
          const value = formValues[p.name];
          // For text-like fields, check if empty string
          // For checkbox or dropZone, adapt as needed
          if (
            value === "" ||
            value === null ||
            (Array.isArray(value) && value.length === 0)
          ) {
            newErrors[p.name] = t("validation.requiredField", {
              defaultValue: "This field is required",
            });
          }
        }
      });
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return false;
    } else {
      return true;
    }
  };

  const handleSave = () => {
    if (validateForm()) {
      createPatientMutation.mutate(formValues);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("addPatientModal.title", "Add Patient")}
      primaryAction={{
        content: t("common.save", "Save"),
        onAction: handleSave,
        loading: createPatientMutation.isPending,
      }}
      size="large"
      secondaryActions={[
        {
          content: t("common.cancel", "Cancel"),
          onAction: onClose,
        },
      ]}
    >
      {isSchemaLoading ? (
        <Box padding={"400"}>
          <SkeletonBodyText />
        </Box>
      ) : (
        <Box paddingBlock={"400"} paddingInline={"400"}>
          <BlockStack gap="400">
            {groupedProperties.map((group) => {
              if (!group.properties.length) return null;

              return (
                <Box key={group.name}>
                  <BlockStack gap={"300"}>
                    {/* Render each property using PropertyTypeRenderer */}
                    <BlockStack gap="400">
                      {group.properties.map((prop: Property) => (
                        <PropertyTypeRenderer
                          isRequired={prop.isRequired}
                          key={prop.name}
                          id={prop.renderer?.id ?? "textInput"}
                          label={prop.label}
                          value={formValues[prop.name]}
                          onChange={(val) => handleChange(prop.name, val)}
                          disabled={false}
                          helpText={prop.description}
                          placeholder=""
                          comboOptions={prop.options ?? []}
                          error={errors[prop.name]} // Pass error message to the renderer
                        />
                      ))}
                    </BlockStack>
                  </BlockStack>
                </Box>
              );
            })}
          </BlockStack>
        </Box>
      )}
    </Modal>
  );
};

export default AddPatientModal;
