import { ROOTS, USER_ROUTES } from "@/utils/Constants";
import { TFunction } from "i18next";

import {
  CalendarIcon,
  LockFilledIcon,
  GlobeIcon,
  OrganizationFilledIcon,
  PersonFilledIcon,
} from "@shopify/polaris-icons";

interface ClinicNavigationItem {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  url: string;
  selected: boolean;
}

export const getUserNavigationItems = (
  t: TFunction,
  locationPathname: string
): ClinicNavigationItem[] => [
  {
    label: t("userNavigation.profile"),
    icon: PersonFilledIcon,
    url: `${ROOTS.USER}/${USER_ROUTES.PROFILE}`,
    selected: locationPathname.endsWith(USER_ROUTES.PROFILE),
  },
  {
    label: t("userNavigation.calender"),
    icon: CalendarIcon,
    url: `${ROOTS.USER}/${USER_ROUTES.CALENDER}`,
    selected: locationPathname.endsWith(USER_ROUTES.CALENDER),
  },
  {
    label: t("userNavigation.security"),
    icon: LockFilledIcon,
    url: `${ROOTS.USER}/${USER_ROUTES.SECURITY}`,
    selected: locationPathname.endsWith(USER_ROUTES.SECURITY),
  },
  {
    label: t("userNavigation.compliance"),
    icon: GlobeIcon,
    url: `${ROOTS.USER}/${USER_ROUTES.COMPLIANCE}`,
    selected: locationPathname.endsWith(USER_ROUTES.COMPLIANCE),
  },
  {
    label: t("userNavigation.teamAccess"),
    icon: OrganizationFilledIcon,
    url: `${ROOTS.USER}/${USER_ROUTES.TEAMACCESS}`,
    selected: locationPathname.endsWith(USER_ROUTES.TEAMACCESS),
  },
];
