import { Page } from "@shopify/polaris";
import { useEffect } from "react";
import AppFrame from "./AppFrame";
import { PageTypes } from "@/types/commonTypes";

export default function NotFoundPage() {
  useEffect(() => {
    console.log("NotFoundPage", { route: location.pathname });
  }, []);
  return (
    <AppFrame type={PageTypes.NOT_FOUND}>
      <Page title="Not Found."></Page>
    </AppFrame>
  );
}
