import { useState, useCallback, useEffect } from "react";
import {
  Modal,
  FormLayout,
  TextField,
  Select,
  Loading,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";
import { Role } from "../types";

interface AddUserModalProps {
  active: boolean;
  onClose: () => void;
  roles: Role[]; // Roles should be passed down from parent component
  clinicId: string;
}

const AddUserModal: React.FC<AddUserModalProps> = ({
  active,
  onClose,
  roles,
  clinicId,
}) => {
  const { t } = useTranslation();
  const { POST } = useApiRequest();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [selectedRole, setSelectedRole] = useState<number>(roles[0]?.id);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (active) {
      setEmail("");
      setMessage("");
      setSelectedRole(roles[0]?.id || 0);
      setIsDirty(false);
    }
  }, [active, roles]);

  const handleEmailChange = useCallback((value: string) => {
    setEmail(value);
    setIsDirty(true);
  }, []);

  const handleMessageChange = useCallback((value: string) => {
    setMessage(value);
    setIsDirty(true);
  }, []);

  const handleRoleChange = useCallback((value: string) => {
    setSelectedRole(parseInt(value, 10));
    setIsDirty(true);
  }, []);

  const addUserMutation = useMutation({
    mutationFn: (inviteData: {
      emails: string;
      message: string;
      roleId: number;
    }) =>
      POST(`/api/healthcare-companies/${clinicId}/team/invites`, inviteData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["invitedMembers", clinicId],
      });
      onClose(); // Close modal after successful invite
    },
    onError: (error) => {
      console.error("Failed to invite users:", error);
    },
  });

  const handleInvite = useCallback(() => {
    addUserMutation.mutate({
      emails: email,
      message,
      roleId: selectedRole,
    });
  }, [email, message, selectedRole, addUserMutation]);

  const roleOptions = roles.map((role) => ({
    label: role.name,
    value: role.id.toString(),
  }));

  return (
    <Modal
      open={active}
      onClose={onClose}
      title={t("membersAndPermissions.addUserModal.title")}
      primaryAction={{
        content: t("common.invite"),
        onAction: handleInvite,
        loading: addUserMutation.isPending,
        disabled: !isDirty,
      }}
      secondaryActions={[
        {
          content: t("common.cancel"),
          onAction: onClose,
        },
      ]}
    >
      {addUserMutation.isPending && <Loading />}
      <Modal.Section>
        <FormLayout>
          <TextField
            label={t("common.emailAddresses")}
            value={email}
            onChange={handleEmailChange}
            autoComplete="off"
            multiline
            helpText={t("membersAndPermissions.addUserModal.emailHelpText")}
          />
          <TextField
            label={t("common.messageToUser")}
            value={message}
            onChange={handleMessageChange}
            autoComplete="off"
            multiline={5}
            helpText={t("membersAndPermissions.addUserModal.messageHelpText")}
          />
          <Select
            label={t("common.role")}
            options={roleOptions}
            value={selectedRole + ""}
            onChange={handleRoleChange}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};

export default AddUserModal;
