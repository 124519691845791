import {
  browserTracingIntegration,
  replayIntegration,
  init,
} from "@sentry/react";

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || "";
const TRACE_PROPAGATION_TARGETS = import.meta.env.VITE_API_URL
  ? [import.meta.env.VITE_API_URL, "localhost"]
  : ["localhost"];
export const initSentry = () => {
  init({
    dsn: SENTRY_DSN, // Use the DSN from environment variables
    integrations: [browserTracingIntegration(), replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    tracePropagationTargets: TRACE_PROPAGATION_TARGETS, // Use the API URL and localhost for tracing
    // Session Replay
    replaysSessionSampleRate: 0.1, // Sample 10% of sessions
    replaysOnErrorSampleRate: 1.0, // Sample 100% of sessions with errors
  });
};
