import React from "react";
import { Page, Layout, Box } from "@shopify/polaris";
import AccessCard from "./AccessCard";
import InvitesCard from "./InvitesCard";
import JoinOrganizationsCard from "./JoinOrganizationsCard";
import AppPageWrapper from "@/common/AppPageWrapper";
import { useTranslation } from "react-i18next";

const TeamsAccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <AppPageWrapper>
      <Page title={t("teamsAccess.title")}>
        <Layout>
          <Layout.Section>
            <AccessCard />
          </Layout.Section>
          <Layout.Section>
            <InvitesCard />
          </Layout.Section>
          <Layout.Section>
            <JoinOrganizationsCard />
          </Layout.Section>
        </Layout>
        <Box paddingBlock={"200"}></Box>
      </Page>
    </AppPageWrapper>
  );
};

export default TeamsAccess;
