import CardWrapper from "@/auth/components/CardWrapper";
import useNavigateToClinic from "@/hooks/useNavigateToClinic";
import { Clinics } from "@/types/clinic";
import { PageTypes } from "@/types/commonTypes";
import { ROOTS } from "@/utils/Constants";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  ChevronRightIcon,
  CalendarTimeIcon,
  EmailIcon,
} from "@shopify/polaris-icons";
import {
  BlockStack,
  Box,
  Icon,
  InlineStack,
  ResourceItem,
  ResourceList,
  Text,
} from "@shopify/polaris";

function AppModeSelector() {
  const navigate = useNavigate();
  const { navigateToClinic } = useNavigateToClinic();
  const queryClient = useQueryClient();
  const clinics = queryClient.getQueryData(["healthcare-companies"]);

  // TODO: have to fix style
  return (
    <CardWrapper type={PageTypes.UNKNOW}>
      <ResourceList
        resourceName={{ singular: "customer", plural: "customers" }}
        items={[
          {
            id: ROOTS.CLINIC,
            title: "Clinic Mode",
            icon: CalendarTimeIcon,
          },
          {
            id: ROOTS.PATIENT,
            title: "Patient Mode",
            icon: EmailIcon,
          },
        ]}
        renderItem={(item) => {
          const { id, title, icon } = item;
          const media = <Icon source={icon} />;

          return (
            <ResourceItem
              onClick={(mode) => {
                if (mode === ROOTS.CLINIC) {
                  navigateToClinic(clinics as Clinics);
                } else {
                  navigate(ROOTS.PATIENT);
                }
              }}
              id={id}
              media={media}
              accessibilityLabel={`View details for ${title}`}
            >
              <InlineStack
                align="space-between"
                wrap={false}
                blockAlign="center"
              >
                <InlineStack align="space-between" gap={"100"}>
                  <BlockStack gap={"100"}>
                    <InlineStack align="start" gap={"200"}>
                      <Text variant="bodyMd" fontWeight="bold" as="h3">
                        {title}
                      </Text>
                    </InlineStack>
                  </BlockStack>
                </InlineStack>
                <Box>
                  <Icon source={ChevronRightIcon} />
                </Box>
              </InlineStack>
            </ResourceItem>
          );
        }}
      />
      <Box paddingBlockEnd={"500"} />
    </CardWrapper>
  );
}

export default AppModeSelector;
