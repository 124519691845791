import CardWrapper from "@/auth/components/CardWrapper";
import React, { useEffect, useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { PageTypes } from "@/types/commonTypes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  FormLayout,
  TextField,
  Select,
  InlineError,
  Button,
  Box,
  InlineStack,
} from "@shopify/polaris";
import { setLocalStorage } from "@/utils/localStorageUtils";
import { useNavigate } from "react-router-dom";
import { ROOTS } from "@/utils/Constants";

interface CompanyType {
  id: string;
  label: string;
  description: string;
}

interface Company {
  types: CompanyType[];
}

interface Country {
  iso3: string;
  name: string;
}

const AddClinic: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { GET, POST } = useApiRequest();

  const navigate = useNavigate();
  const [type, setType] = useState<string>("");
  const [country, setCountry] = useState<string>("BGR");
  const [business, setBusiness] = useState<string>("");
  const [taxId, setTaxId] = useState<string>("");

  const [typeError, setTypeError] = useState<string | null>(null);
  const [countryError, setCountryError] = useState<string | null>(null);
  const [businessError, setBusinessError] = useState<string | null>(null);
  const [taxIdError, setTaxIdError] = useState<string | null>(null);

  const {
    data,
    isLoading: isTypesLoading,
    isSuccess,
  } = useQuery<Company>({
    queryKey: ["company-types"],
    queryFn: () => GET("/api/healthcare-companies/meta"),
  });

  useEffect(() => {
    if (!isTypesLoading && isSuccess && data?.types?.length > 0) {
      setType(data?.types[0]?.id);
    }
  }, [isTypesLoading, isSuccess, data]);
  const { data: countryData = [], isLoading: isCountriesLoading } = useQuery<
    Country[]
  >({
    queryKey: ["countries"],
    queryFn: () => GET("/api/metadata/countries"),
  });

  const createClinic = useMutation({
    mutationFn: (newCompany: Record<string, string>) =>
      POST("/api/healthcare-companies", newCompany),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSuccess: (response: any) => {
      queryClient.invalidateQueries({ queryKey: ["healthcare-companies"] });
      setLocalStorage("__LAST_VISITED_C_ID", response?.id);
      navigate(`${ROOTS.CLINIC}/${response?.id}`);
    },
    onError: () => {
      setTypeError(t("addClinic.errorCreatingCompany"));
    },
  });

  const handleCreate = () => {
    let valid = true;

    if (!type) {
      setTypeError(t("addClinic.typeError"));
      valid = false;
    } else {
      setTypeError(null);
    }

    if (!country) {
      setCountryError(t("addClinic.countryError"));
      valid = false;
    } else {
      setCountryError(null);
    }

    if (!business) {
      setBusinessError(t("addClinic.businessNameError"));
      valid = false;
    } else {
      setBusinessError(null);
    }

    if (!taxId) {
      setTaxIdError(t("addClinic.taxIdError"));
      valid = false;
    } else {
      setTaxIdError(null);
    }

    if (valid) {
      createClinic.mutate({
        type,
        country,
        business,
        taxId,
      });
    }
  };

  return (
    <CardWrapper type={PageTypes.UNKNOW}>
      <FormLayout>
        <Select
          label={t("addClinic.typeLabel")}
          options={data?.types.map((companyType) => ({
            label: companyType.label,
            value: companyType.id,
          }))}
          value={type}
          onChange={(value) => setType(value)}
          disabled={isTypesLoading}
          requiredIndicator
        />
        {typeError && <InlineError message={typeError} fieldID="type" />}

        <Select
          label={t("addClinic.countryLabel")}
          options={countryData.map((country) => ({
            label: country.name,
            value: country.iso3,
          }))}
          value={country}
          onChange={(value) => setCountry(value)}
          disabled={isCountriesLoading}
          requiredIndicator
        />
        {countryError && (
          <InlineError message={countryError} fieldID="country" />
        )}

        <TextField
          label={t("addClinic.businessNameLabel")}
          value={business}
          onChange={(value) => setBusiness(value)}
          autoComplete="off"
          requiredIndicator
        />
        {businessError && (
          <InlineError message={businessError} fieldID="business" />
        )}

        <TextField
          label={t("addClinic.taxIdLabel")}
          value={taxId}
          onChange={(value) => setTaxId(value)}
          autoComplete="off"
          requiredIndicator
        />
        {taxIdError && <InlineError message={taxIdError} fieldID="taxId" />}
      </FormLayout>
      <Box paddingBlockStart="500">
        <InlineStack gap="400" align="end">
          <Button onClick={() => navigate(-1)}>
            {t("addClinic.cancelButton")}
          </Button>
          <Button
            variant="primary"
            onClick={handleCreate}
            loading={createClinic.isPending}
          >
            {t("addClinic.createButton")}
          </Button>
        </InlineStack>
      </Box>
    </CardWrapper>
  );
};

export default AddClinic;
