import i18n from "@/i18n";
import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";

const nonLocalizedQuery = [
  "permissions",
  "user",
  "activation-check",
  "healthcare-companies",
];
const useInvalidateQueriesOnLanguageChange = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const handleLanguageChange = () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          !nonLocalizedQuery.includes(query.queryKey[0] as string),
      });
    };

    i18n.on("languageChanged", handleLanguageChange);

    // Cleanup the event listener on component unmount
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [queryClient]);
};

export default useInvalidateQueriesOnLanguageChange;
