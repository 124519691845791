/* eslint-disable @typescript-eslint/no-explicit-any */
import { Select, BlockStack, Text, InlineStack } from "@shopify/polaris";
import { SchemaGroup } from "../types";

interface CoreFormProps {
  schema: SchemaGroup;
  data: Record<string, any>;
  onDataChange: (field: string, value: any) => void;
}

const CoreForm: React.FC<CoreFormProps> = ({ schema, data, onDataChange }) => {
  return (
    <BlockStack gap="200">
      <Text as="h2" variant="headingMd">
        {schema.label}
      </Text>
      <InlineStack gap="400">
        {schema.properties.map((property) => {
          if (property.renderer.id === "combobox") {
            return (
              <Select
                key={property.name}
                label={property.label}
                options={property?.options?.map((opt) => ({
                  label: opt.label,
                  value: opt.value,
                }))}
                value={data[property.name] || ""}
                onChange={(value) => onDataChange(property.name, value)}
                helpText={property.description}
                disabled={property.isReadOnlyValue}
              />
            );
          }
          return null;
        })}
      </InlineStack>
    </BlockStack>
  );
};

export default CoreForm;
