import LocaleSwitcher from "@/common/LocaleSwitcher";
import React, { useCallback, useState } from "react";
import useNavigateToClinic from "@/hooks/useNavigateToClinic";
import { Avatar, Box, Icon, InlineStack, TopBar } from "@shopify/polaris";
import { clearTokenData } from "@/auth/utils";
import { Clinic } from "@/types/clinic";
import { CheckSmallIcon, OrganizationIcon } from "@shopify/polaris-icons";
import { ROOTS, USER_ROUTES } from "@/utils/Constants";
import { setLocalStorage } from "@/utils/localStorageUtils";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "@/types/user";
import { useTranslation } from "react-i18next";

type ClinicTopBarProps = {
  onNavigationToggle: () => void;
};

const ClinicTopBar: React.FC<ClinicTopBarProps> = ({ onNavigationToggle }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const clinics =
    queryClient.getQueryData<Clinic[]>(["healthcare-companies"]) || [];
  const params = useParams();
  const selectedClinicId = params.clinicId;

  const { navigateToClinic } = useNavigateToClinic();

  const user = queryClient.getQueryData<User>(["user"]);

  const [userMenuActive, setUserMenuActive] = useState(false);

  const toggleUserMenuActive = useCallback(() => {
    setUserMenuActive((userMenuActive) => !userMenuActive);
  }, []);

  const handleLogout = () => {
    clearTokenData();
  };

  const selectedClinic =
    clinics.find((c) => c.id === selectedClinicId) || clinics[0];

  const sortedClinics = clinics
    .filter((clinic) => clinic.id !== selectedClinicId)
    .sort((a, b) => a.name.localeCompare(b.name));

  const clinicsToList = [selectedClinic, ...sortedClinics].filter((c) => !!c);

  const clinicMenuActions = [
    {
      items: [
        ...clinicsToList.slice(0, 4).map((c) => ({
          content: c.name,
          icon: () => (
            <Avatar
              initials={c.avatar.initials}
              name={c.name}
              source={c.avatar.image}
            />
          ),
          onAction: () => {
            setLocalStorage("__LAST_VISITED_C_ID", c.id);
            navigateToClinic(clinics);
          },
          active: c.id === selectedClinicId,
          suffix: c.id === selectedClinicId && <Icon source={CheckSmallIcon} />,
        })),
        {
          content: t("topbar.clinics"),
          onAction: () => navigate(ROOTS.CLINIC),
          icon: OrganizationIcon,
        },
      ],
    },
    {
      items: [
        {
          content: t("common.helpCenter"),
          onAction: () =>
            window.open(
              `https://support.medentic.app/${i18n.language}`,
              "_blank"
            ),
        },
        {
          content: t("common.changelog"),
          onAction: () =>
            window.open("https://www.medentic.app/changelog", "_blank"),
        },
      ],
    },
    {
      items: [
        {
          content: `${user?.firstName} ${user?.lastName}`,
          onAction: () => navigate(`${ROOTS.USER}/${USER_ROUTES.PROFILE}`),
          helpText: user?.email,
        },
        { content: t("common.logout"), onAction: handleLogout },
      ],
    },
  ];

  const searchFieldMarkup = (
    <TopBar.SearchField
      onChange={() => {}}
      value={""}
      placeholder={t("common.search")}
      showFocusBorder
    />
  );

  return (
    <TopBar
      showNavigationToggle
      onNavigationToggle={onNavigationToggle}
      secondaryMenu={
        <InlineStack align="center" blockAlign="center">
          <Box>
            <LocaleSwitcher topbarCase={true} />
          </Box>
        </InlineStack>
      }
      searchField={searchFieldMarkup}
      userMenu={
        <TopBar.UserMenu
          actions={clinicMenuActions}
          name={selectedClinic?.name ?? ""}
          initials={selectedClinic?.avatar.initials}
          avatar={selectedClinic?.avatar.image}
          open={userMenuActive}
          onToggle={toggleUserMenuActive}
          customWidth="350px"
        />
      }
    />
  );
};

export default ClinicTopBar;
