import AppPageWrapper from "@/common/AppPageWrapper";
import EmailField from "./EmailField";
import LoginServices from "./LoginServices";
import PhoneField from "./PhoneField";
import ProfilePicture from "./ProfilePicture";
import React, { useEffect, useState } from "react";
import RegionalSettings from "./RegionalSettings";
import useApiRequest from "@/hooks/useApiRequest";
import { removeLocalStorage } from "@/utils/localStorageUtils";
import { User } from "@/types/user";
import { useTranslation } from "react-i18next";
import {
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  BlockStack,
  Box,
  Card,
  ContextualSaveBar,
  FormLayout,
  Layout,
  Loading,
  Page,
  Text,
  TextField,
} from "@shopify/polaris";

interface Country {
  phoneCountryCode: string;
  name: string;
}

const UserProfile: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { GET, PATCH } = useApiRequest();
  const user = queryClient.getQueryData<User>(["user"]);
  const isUserLoading = useIsFetching({ queryKey: ["user"] });

  const [firstName, setFirstName] = useState<string>(user?.firstName ?? "");
  const [lastName, setLastName] = useState<string>(user?.lastName ?? "");
  const [timezone, setTimezone] = useState<string>(user?.timezone.id ?? "");
  const [language, setLanguage] = useState<string>(user?.language.iso ?? "");
  const [format, setFormat] = useState<string>(user?.format.locale ?? "");
  const [countries, setCountries] = useState<Country[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const { data: countryData = [] } = useQuery<Country[]>({
    queryKey: ["countries"],
    queryFn: () => GET("/api/metadata/countries"),
  });

  useEffect(() => {
    if (countryData.length > 0) {
      setCountries(countryData);
    }
  }, [countryData]);

  const handleInputChange = (
    setter: React.Dispatch<React.SetStateAction<string>>,
    value: string
  ) => {
    setter(value);
    setHasUnsavedChanges(true);
  };

  const updateProfile = useMutation({
    mutationFn: (profileData: Record<string, string | null | undefined>) =>
      PATCH("/api/user/me", profileData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      setHasUnsavedChanges(false);
    },
  });

  const handleDiscard = () => {
    setHasUnsavedChanges(false);
    setFirstName(user?.firstName ?? "");
    setLastName(user?.lastName ?? "");
    setTimezone(user?.timezone.id ?? "");
    setLanguage(user?.language.iso ?? "");
    setFormat(user?.format.locale ?? "");
  };

  const handleProfileSubmit = () => {
    if (hasUnsavedChanges) {
      updateProfile.mutate({
        firstName,
        lastName,
        timezone,
        language,
        format,
      });
    }
  };

  return (
    <AppPageWrapper>
      {isUserLoading ? <Loading /> : null}
      {hasUnsavedChanges && (
        <ContextualSaveBar
          message={t("userProfile.unsavedChanges")}
          saveAction={{
            onAction: handleProfileSubmit,
            loading: updateProfile.isPending,
            disabled: false,
          }}
          discardAction={{
            onAction: handleDiscard,
          }}
        />
      )}
      <Page title={t("userProfile.profile")} narrowWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap="400">
                <BlockStack gap="200">
                  <Text as="h1" variant="headingMd">
                    {t("userProfile.profilePicture")}
                  </Text>
                  <ProfilePicture avatarUrl={user?.avatar?.image} />
                </BlockStack>
                <FormLayout>
                  <Text as="h1" variant="headingMd">
                    {t("userProfile.details")}
                  </Text>
                  <FormLayout.Group helpText={t("userProfile.nameHelpText")}>
                    <TextField
                      label={t("userProfile.firstName")}
                      value={firstName}
                      onChange={(value) =>
                        handleInputChange(setFirstName, value)
                      }
                      autoComplete="given-name"
                    />
                    <TextField
                      label={t("userProfile.lastName")}
                      value={lastName}
                      onChange={(value) =>
                        handleInputChange(setLastName, value)
                      }
                      autoComplete="family-name"
                    />
                  </FormLayout.Group>

                  <EmailField
                    email={user?.email ?? ""}
                    emailVerified={user?.emailVerified ?? false}
                  />

                  <PhoneField
                    phoneNumber={user?.phoneNumber ?? ""}
                    phoneCountryCode={user?.phoneCountryCode ?? ""}
                    phoneVerified={user?.phoneVerified ?? false}
                    countries={countries}
                  />
                </FormLayout>
              </BlockStack>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <LoginServices />
          </Layout.Section>

          <Layout.Section>
            <RegionalSettings
              timezone={timezone}
              language={language}
              format={format}
              setFormat={setFormat}
              setTimezone={setTimezone}
              setLanguage={(l) => {
                removeLocalStorage("selectedLanguage");
                setLanguage(l);
              }}
              handleInputChange={handleInputChange}
            />
          </Layout.Section>
        </Layout>
        <Box paddingBlock="200" />
      </Page>
    </AppPageWrapper>
  );
};

export default UserProfile;
