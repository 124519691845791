import axios from "axios";
import Cookies from "js-cookie";
import i18n from "../i18n";

export const API_URL = import.meta.env.VITE_API_URL;

const defaultHeaders = {
  "Content-Type": "application/json",
};

const getTimezone = (): string => {
  return `${new Date().getTimezoneOffset()}`;
};

export const getFullUrl = (endpoint: string) => `${API_URL}${endpoint}`;

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: defaultHeaders,
});

const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParams: Record<string, string> = {};

  urlParams.forEach((value, key) => {
    utmParams[key] = value;
  });

  return utmParams;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const timezone = getTimezone();
    const token = Cookies.get("access_token");
    const language = i18n.language;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers["accept-language"] = language;

    if (config.headers.addUTMParams) {
      const utmParams = getUTMParams();
      if (config.data) {
        config.data = { ...config.data, params: { ...utmParams }, timezone };
      } else {
        config.data = { params: { ...utmParams }, timezone };
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

const GETRequest = async <T>(
  endpoint: string,
  addUTMParams = false
): Promise<T> => {
  const response = await axiosInstance.get(getFullUrl(endpoint), {
    headers: { addUTMParams },
  });
  if (response.status === 204) {
    return response.data;
  }
  return response.data?.data;
};

const POSTRequest = async <T>(
  endpoint: string,
  data: object | FormData,
  addUTMParams = false
): Promise<T> => {
  const headers: Record<string, string | boolean> = {};

  if (data instanceof FormData) {
    headers["Content-Type"] = "multipart/form-data";
    headers["addUTMParams"] = addUTMParams;
  }

  const response = await axiosInstance.post(getFullUrl(endpoint), data, {
    headers,
  });

  return response.data?.data;
};

const PUTRequest = async <T>(
  endpoint: string,
  data: object,
  addUTMParams = false
): Promise<T> => {
  const response = await axiosInstance.put(getFullUrl(endpoint), data, {
    headers: { addUTMParams },
  });
  return response.data?.data;
};

const PATCHRequest = async <T>(
  endpoint: string,
  data: object,
  addUTMParams = false
): Promise<T> => {
  const response = await axiosInstance.patch(getFullUrl(endpoint), data, {
    headers: { addUTMParams },
  });
  return response.data?.data;
};

// Add DELETE Request
const DELETERequest = async <T>(
  endpoint: string,
  addUTMParams = false,
  data: object = {}
): Promise<T> => {
  const response = await axiosInstance.delete(getFullUrl(endpoint), {
    headers: { addUTMParams },
    data,
  });
  return response.data?.data;
};

export {
  GETRequest,
  POSTRequest,
  PUTRequest,
  PATCHRequest,
  DELETERequest,
  axiosInstance,
};
