import React, { useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { BlockStack, Form, Modal, Text, TextField } from "@shopify/polaris";
import { useTranslation } from "react-i18next";

interface PasswordVerificationModalProps {
  onClose: (result: boolean) => void;
}

const PasswordVerificationModal: React.FC<PasswordVerificationModalProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { POST } = useApiRequest();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleVerify = async () => {
    setLoading(true);
    setError("");

    try {
      await POST("/api/user/me/password/verify", { password });
      setLoading(false);
      onClose(true);
    } catch (error) {
      setLoading(false);
      setError(t("passwordVerificationModal.invalidPassword"));
    }
  };

  return (
    <Modal
      open
      onClose={() => onClose(false)}
      title={t("passwordVerificationModal.verifyYourPassword")}
      primaryAction={{
        content: t("passwordVerificationModal.next"),
        onAction: handleVerify,
        loading,
      }}
      secondaryActions={[
        {
          content: t("passwordVerificationModal.cancel"),
          onAction: () => onClose(false),
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap={"200"}>
          <Text as="p" tone="subdued">
            {t("passwordVerificationModal.description")}
          </Text>
          <Form onSubmit={handleVerify}>
            <TextField
              label={t("passwordVerificationModal.passwordLabel")}
              value={password}
              onChange={setPassword}
              type="password"
              error={error}
              autoComplete="current-password"
              autoFocus
            />
          </Form>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default PasswordVerificationModal;
