import appSlice from "./reducers/appSlice";
import authSlice from "./auth/authSlice";
import patientSlice from "./clinic/pages/patient/patientSlice";
import toastSlice from "./reducers/toastSlice";
import topMessagesSlice from "./common/TopMessages/topMessagesSlice";
import userSlice from "./user/userSlice";
import { combineReducers, configureStore } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  patient: patientSlice,
  app: appSlice,
  toast: toastSlice,
  topMessages: topMessagesSlice,
});
const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
