import Cookies from "js-cookie";
import { removeLocalStorage } from "@/utils/localStorageUtils";
import { ROUTES } from "@/utils/Constants";
import { TokenResponse } from "./types";

export const saveTokenData = (data: TokenResponse) => {
  Cookies.set("token_type", data.tokenType, {
    expires: new Date(data.expiresAt),
  });
  Cookies.set("access_token", data.accessToken, {
    expires: new Date(data.expiresAt),
  });
};

export const clearTokenData = (navigateToSite = true) => {
  Cookies.remove("token_type");
  Cookies.remove("access_token");
  removeLocalStorage("__REDIRECT_PATH");
  removeLocalStorage("invite_token");
  navigateToSite
    ? window.open(import.meta.env.VITE_SITE_URL, "_self")
    : window.open(ROUTES.LOGIN, "_self");
};

export const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@.]+$/;

  return re.test(String(email).toLowerCase());
};
