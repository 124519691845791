import React from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { LanguageOption, Timezone } from "@/types/user";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Card,
  FormLayout,
  Select,
  BlockStack,
  Text,
  Box,
  SkeletonBodyText,
  SkeletonDisplayText,
} from "@shopify/polaris";

interface RegionalSettingsProps {
  timezone: string;
  language: string;
  format: string;
  setTimezone: React.Dispatch<React.SetStateAction<string>>;
  setFormat: React.Dispatch<React.SetStateAction<string>>;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  handleInputChange: (
    setter: React.Dispatch<React.SetStateAction<string>>,
    value: string
  ) => void;
}

const RegionalSettings: React.FC<RegionalSettingsProps> = ({
  timezone,
  language,
  format,
  setFormat,
  setTimezone,
  setLanguage,
  handleInputChange,
}) => {
  const { t } = useTranslation();
  const { GET } = useApiRequest();

  // Fetch Metadata: Timezones and Languages
  const { data: timezones = [], isLoading: isTimezonesLoading } = useQuery<
    Timezone[]
  >({
    queryKey: ["timezones"],
    queryFn: () => GET("/api/metadata/timezones"),
  });

  const { data: languages = [], isLoading: isLanguagesLoading } = useQuery<
    LanguageOption[]
  >({
    queryKey: ["languages"],
    queryFn: () => GET("/api/metadata/languages"),
  });

  return (
    <Card>
      <BlockStack>
        {isTimezonesLoading || isLanguagesLoading ? (
          <BlockStack gap={"400"}>
            <BlockStack gap={"200"}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
            <BlockStack gap={"200"}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
            <BlockStack gap={"200"}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
          </BlockStack>
        ) : (
          <>
            <Text as="h1" variant="headingMd">
              {t("regionalSettings.title")}
            </Text>
            <Box paddingBlock="200">
              <Text as="p" tone="subdued">
                {t("regionalSettings.description")}
              </Text>
            </Box>
            <FormLayout>
              <FormLayout.Group>
                <Select
                  label={t("regionalSettings.timeZone")}
                  options={timezones.map((t) => ({
                    value: t.id,
                    label: t.label,
                  }))}
                  value={timezone}
                  onChange={(value) => handleInputChange(setTimezone, value)}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("regionalSettings.preferredLanguage")}
                  options={languages.map((lang) => ({
                    label: lang.localLabel,
                    value: lang.iso,
                  }))}
                  value={language}
                  onChange={(value) => handleInputChange(setLanguage, value)}
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <Select
                  label={t("regionalSettings.format")}
                  options={languages.map((lang) => ({
                    label: lang.englishLabel,
                    value: lang.iso,
                  }))}
                  value={format}
                  onChange={(value) => handleInputChange(setFormat, value)}
                />
              </FormLayout.Group>
            </FormLayout>
          </>
        )}
      </BlockStack>
    </Card>
  );
};

export default RegionalSettings;
