import Cookies from "js-cookie";
import { clearTokenData } from "@/auth/utils";
import { ROOTS } from "@/utils/Constants";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const INTERVAL_MS =
  (Number(import.meta.env.VITE_LOGOUT_IDLE_TAB_TIME) || 5) * 60 * 1000;

const useCheckToken = () => {
  const location = useLocation();
  useEffect(() => {
    const checkTokenInterval = setInterval(() => {
      const hasToken = !!Cookies.get("access_token");
      if (!hasToken && !location.pathname.startsWith(ROOTS.AUTH)) {
        clearTokenData();
      }
    }, INTERVAL_MS);

    return () => clearInterval(checkTokenInterval);
  }, [location]);
};

export default useCheckToken;
