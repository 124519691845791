import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import useApiRequest from "@/hooks/useApiRequest";

interface Country {
  name: string;
  phoneCountryCode: string;
}

interface CountryContextValue {
  countries: { label: string; value: string }[];
  isLoading: boolean;
}

const CountryContext = createContext<CountryContextValue>({
  countries: [],
  isLoading: true,
});

export const CountryProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { GET } = useApiRequest();
  const { data = [], isLoading } = useQuery<Country[]>({
    queryKey: ["countries"],
    queryFn: () => GET("/api/metadata/countries"),
  });

  // Convert each Country to { label, value } object
  const countries = useMemo(
    () =>
      data.map((c) => ({
        label: c.name,
        value: c.phoneCountryCode,
      })),
    [data]
  );

  return (
    <CountryContext.Provider value={{ countries, isLoading }}>
      {children}
    </CountryContext.Provider>
  );
};

export function useCountries() {
  return useContext(CountryContext);
}
