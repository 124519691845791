import React, { useCallback, useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BlockStack,
  Box,
  Button,
  DropZone,
  List,
  Loading,
} from "@shopify/polaris";

interface ProfilePictureProps {
  avatarUrl: string | undefined;
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({ avatarUrl }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { POST, DELETE } = useApiRequest();
  const [files, setFiles] = useState<File[]>([]);
  const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
  const hasError = rejectedFiles.length > 0;

  const uploadAvatar = useMutation({
    mutationFn: (avatarFile: File) => {
      const logoFile = new FormData();
      logoFile.append("file", avatarFile);

      return POST("/api/user/me/avatar", logoFile);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });

  const deleteAvatar = useMutation({
    mutationFn: () => DELETE("/api/user/me/avatar"),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
  });

  const handleDrop = useCallback(
    (_droppedFiles: File[], acceptedFiles: File[], rejectedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        uploadAvatar.mutate(acceptedFiles[0]);
      }
      setFiles(acceptedFiles);
      setRejectedFiles(rejectedFiles);
    },
    [uploadAvatar]
  );

  const handleLogoRemove = () => {
    deleteAvatar.mutate();
    setFiles([]);
  };

  const fileUpload = !files.length && !avatarUrl && (
    <DropZone.FileUpload actionTitle={t("profilePicture.uploadAction")} />
  );

  const uploadedFiles =
    files.length > 0 || avatarUrl ? (
      <BlockStack>
        <BlockStack align="center" inlineAlign="start">
          <img
            style={{ borderRadius: "4px" }}
            width="100px"
            height="100px"
            alt={avatarUrl ?? files[0]?.name}
            src={avatarUrl ?? window.URL.createObjectURL(files[0])}
          />
          <Box paddingBlockStart="100">
            <Button onClick={handleLogoRemove} variant="plain" tone="critical">
              {t("profilePicture.removePhoto")}
            </Button>
          </Box>
        </BlockStack>
      </BlockStack>
    ) : null;

  const errorMessage = hasError && (
    <Banner title={t("profilePicture.uploadErrorTitle")} tone="critical">
      <List type="bullet">
        {rejectedFiles.map((file, index) => (
          <List.Item key={file.name + index}>
            {t("profilePicture.uploadErrorItem", { fileName: file.name })}
          </List.Item>
        ))}
      </List>
    </Banner>
  );

  return (
    <BlockStack gap="400">
      {errorMessage}
      {(uploadAvatar.isPending || deleteAvatar.isPending) && <Loading />}
      <Box width="40%">
        {files.length === 0 && !avatarUrl ? (
          <DropZone
            accept="image/png, image/jpeg, image/jpg, image/gif, image/webp, image/avif, image/heic, image/tiff"
            type="image"
            allowMultiple={false}
            onDrop={handleDrop}
          >
            {fileUpload}
          </DropZone>
        ) : (
          uploadedFiles
        )}
      </Box>
    </BlockStack>
  );
};

export default ProfilePicture;
