import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppState {
  gtmEventSent: boolean;
  isInitializingApp: boolean;
  showSudoBanner: boolean;
}

const initialState: AppState = {
  gtmEventSent: false,
  isInitializingApp: true,
  showSudoBanner: true,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setGtmEventSent: (state, action: PayloadAction<boolean>) => {
      state.gtmEventSent = action.payload;
    },
    setIsInitializingApp: (state, action: PayloadAction<boolean>) => {
      state.isInitializingApp = action.payload;
    },
    setShowSudoBanner: (state, action: PayloadAction<boolean>) => {
      state.showSudoBanner = action.payload;
    },
  },
});

export const { setGtmEventSent, setIsInitializingApp, setShowSudoBanner } =
  appSlice.actions;
export default appSlice.reducer;
