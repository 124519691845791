import AppPageWrapper from "@/common/AppPageWrapper";
import ChangePassword from "./ChangePassword";
import ManageAlternativeEmails from "./ManageAlternativeEmails";
import React, { useState } from "react";
import TwoStepAuthentication from "./TwoStepAuthentication";
import { useTranslation } from "react-i18next";
import {
  BlockStack,
  Box,
  Button,
  Card,
  Layout,
  Page,
  Text,
} from "@shopify/polaris";

const SecurityPage: React.FC = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleToggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <AppPageWrapper>
      <Page title={t("security.title")} narrowWidth>
        <Layout>
          <Layout.Section>
            <Card>
              <BlockStack gap="300">
                <Text as="h2" variant="headingMd">
                  {t("security.changePasswordTitle")}
                </Text>
                <Text as="p" variant="bodyMd" tone="subdued">
                  {t("security.changePasswordDescription")}
                </Text>
                <Box>
                  <Button onClick={handleToggleModal} variant="primary">
                    {t("security.changePasswordButton")}
                  </Button>
                </Box>
              </BlockStack>
            </Card>
          </Layout.Section>

          <Layout.Section>
            <ManageAlternativeEmails />
          </Layout.Section>
          <Layout.Section>
            <TwoStepAuthentication />
          </Layout.Section>

          {isModalOpen && (
            <ChangePassword open={isModalOpen} onClose={handleToggleModal} />
          )}
        </Layout>
        <br />
      </Page>
    </AppPageWrapper>
  );
};

export default SecurityPage;
