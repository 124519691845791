import React, { useState } from "react";
import {
  TextField,
  Button,
  Text,
  Box,
  Avatar,
  Form,
  FormLayout,
  InlineStack,
  BlockStack,
  Spinner,
} from "@shopify/polaris";
import { useMutation } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useApiRequest from "@/hooks/useApiRequest";
import { InviteData, OTTResponse } from "@/auth/types";
import Cookies from "js-cookie";
import { useUser } from "@/hooks/useUser";
import TranslateLink from "@/common/TranslateLink";
import useOTT from "@/hooks/useOTT";
import CardWrapper from "../CardWrapper";
import { PageTypes } from "@/types/commonTypes";
import { removeLocalStorage } from "@/utils/localStorageUtils";

type RegisterToJoinProps = {
  acceptWithRegisteredUser: () => void;
  inviteData: InviteData | undefined;
  isLoading: boolean;
};

const RegisterToJoin: React.FC<RegisterToJoinProps> = ({
  acceptWithRegisteredUser,
  inviteData,
  isLoading,
}) => {
  const { t, i18n } = useTranslation();

  const locale = i18n.language;
  const termsUrl = `https://www.medentic.app/${locale}/legal/terms-of-service`;
  const privacyUrl = `https://www.medentic.app/${locale}/legal/privacy`;
  const { POST } = useApiRequest();
  const isLoggedIn = !!Cookies.get("access_token");
  const { user } = useUser(isLoggedIn);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const { handleOTT, setReloadOnOttExchange } = useOTT();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [formErrors, setFormErrors] = useState<{
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    confirmPassword?: string;
  }>({});

  // Mutation to register and join with invite token
  const registerWithInviteMutation = useMutation<OTTResponse>({
    mutationFn: () =>
      POST(
        "/api/auth/register/with/invite",
        { email: inviteData?.email, firstName, lastName, password, token },
        true
      ),

    onSuccess: async (data: OTTResponse) => {
      if (data.ott) {
        removeLocalStorage("invite_token");
        setReloadOnOttExchange(true);
        await handleOTT(data.ott);
      }
    },
  });

  const acceptInviteMutation = useMutation({
    mutationFn: () => POST("/api/user/me/invites/accept", { token }, true),
    onSuccess: () => {
      removeLocalStorage("invite_token");
      window.location.href = "/";
    },
  });

  const handleFormSubmit = () => {
    const errors = {
      firstName: firstName
        ? undefined
        : t("auth.registration.email.error.firstNameRequired"),
      lastName: lastName
        ? undefined
        : t("auth.registration.email.error.lastNameRequired"),
      password:
        password.length >= 6
          ? undefined
          : t("auth.registration.email.error.passwordLength"),
      confirmPassword:
        password !== confirmPassword
          ? t("auth.registration.email.error.passNoMatch")
          : "",
    };
    setFormErrors(errors);

    if (!Object.values(errors).some(Boolean)) {
      registerWithInviteMutation.mutate();
    }
  };

  const handleAcceptInvite = () => {
    acceptInviteMutation.mutate();
  };

  const { company, inviter } = inviteData || {};

  return (
    <CardWrapper
      type={PageTypes.UNKNOW}
      wrapperClass="white-card-wrapper"
      footerClass="relative-auth-footer"
      footer={
        !isLoggedIn ? (
          <Box
            paddingBlockStart={"500"}
            paddingBlockEnd={"500"}
            maxWidth="460px"
          >
            <Text as={"p"} alignment="start" tone="subdued">
              <TranslateLink
                text={t("auth.common.footer.byProceeding", {
                  termsUrl,
                  privacyUrl,
                })}
              />
            </Text>
          </Box>
        ) : (
          ""
        )
      }
    >
      {isLoading ? (
        <InlineStack align="center">
          <Box padding={"1600"}>
            <Spinner />
          </Box>
        </InlineStack>
      ) : (
        <BlockStack gap="400">
          <InlineStack gap="200" blockAlign="center">
            <Box>
              <Avatar
                size="lg"
                initials={company?.avatar.initials ?? ""}
                source={company?.avatar.colorSchema.background}
                name={company?.name ?? ""}
              />
            </Box>
            <BlockStack>
              <Text as="h3" variant="headingLg">
                {company?.name}
              </Text>
              <Text as="p" tone="subdued">
                {company?.website}
              </Text>
            </BlockStack>
          </InlineStack>

          <Text variant="headingMd" as="h2">
            {t("joinForm.invitationTitle", { companyName: company?.name })}
          </Text>
          <TranslateLink
            text={t("joinForm.invitationDescription", {
              inviterName: `${inviter?.firstName} ${inviter?.lastName}`,
              inviterEmail: inviter?.email,
              companyName: company?.name,
            })}
          />

          {isLoggedIn && (
            <Text as="p" variant="bodyMd">
              {t("joinForm.registeredSubHeading")}
            </Text>
          )}

          <Form onSubmit={handleFormSubmit}>
            <FormLayout>
              <TextField
                label={t("common.email")}
                type="email"
                value={inviteData?.email}
                autoComplete="email"
                error={formErrors.email}
                autoFocus
                disabled
              />
              <FormLayout.Group>
                <TextField
                  label={t("common.firstName")}
                  value={firstName}
                  onChange={setFirstName}
                  autoComplete="given-name"
                  error={formErrors.firstName}
                />
                <TextField
                  label={t("common.lastName")}
                  value={lastName}
                  onChange={setLastName}
                  autoComplete="family-name"
                  error={formErrors.lastName}
                  helpText={t("common.namesHelp")}
                />
              </FormLayout.Group>
              <TextField
                label={t("common.password")}
                type="password"
                value={password}
                onChange={setPassword}
                autoComplete="new-password"
                error={formErrors.password}
              />
              <TextField
                label={t("common.confirmPassword")}
                type="password"
                value={confirmPassword}
                onChange={setConfirmPassword}
                autoComplete="new-password"
                error={formErrors.confirmPassword}
              />
              <Button
                variant="primary"
                onClick={handleFormSubmit}
                fullWidth
                size="large"
                loading={registerWithInviteMutation.isPending}
                disabled={
                  !firstName || !lastName || !password || !confirmPassword
                }
              >
                {t("joinForm.joinButton", { companyName: company?.name })}
              </Button>
              <Button
                size="large"
                variant="secondary"
                onClick={
                  isLoggedIn ? handleAcceptInvite : acceptWithRegisteredUser
                }
                fullWidth
                loading={acceptInviteMutation.isPending}
              >
                {t("joinForm.acceptWith", {
                  user: isLoggedIn
                    ? user?.firstName + " " + user?.lastName
                    : t("joinForm.registeredUser"),
                })}
              </Button>
            </FormLayout>
          </Form>
        </BlockStack>
      )}
    </CardWrapper>
  );
};

export default RegisterToJoin;
