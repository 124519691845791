import AppFrame from "@/common/AppFrame";
import Cookies from "js-cookie";
import LocaleSwitcher from "../../common/LocaleSwitcher";
import { PageTypes } from "@/types/commonTypes";
import { ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../styles.scss";
import {
  Page,
  Card,
  Link,
  Box,
  InlineStack,
  Text,
  BlockStack,
} from "@shopify/polaris";

function CardWrapper({
  children,
  footer,
  footerClass = "",
  type,
  wrapperClass = "",
  showLocaleSwitcher = true,
}: {
  children: ReactNode;
  type: PageTypes;
  footer?: ReactNode;
  footerClass?: string;
  wrapperClass?: string;
  showLocaleSwitcher?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;

  const termsUrl = `https://www.medentic.app/${locale}/legal/terms-of-service`;
  const privacyUrl = `https://www.medentic.app/${locale}/legal/privacy`;
  const helpUrl = `https://support.medentic.app/${locale}`;
  const isLoggedIn = !!Cookies.get("access_token");

  useEffect(() => {
    document.documentElement.classList.add("dark-background");
    return () => document.documentElement.classList.remove("dark-background");
  }, []);

  return (
    <AppFrame type={type}>
      <div className={`auth-page-main ${wrapperClass}`}>
        <Page fullWidth>
          <Card roundedAbove="sm" padding={{ xs: "800", md: "1000" }}>
            <BlockStack align="space-between">
              <BlockStack>
                <Box paddingBlockEnd={"800"}>
                  <InlineStack
                    align="space-between"
                    blockAlign="center"
                    gap={"2000"}
                    wrap={false}
                  >
                    <img
                      height={30}
                      alt="Logo"
                      src="https://assets.medentic.app/logos/logotype-black.svg"
                    />
                    {showLocaleSwitcher && <LocaleSwitcher />}
                  </InlineStack>
                </Box>
                {children}
              </BlockStack>
              <div className={`auth-footer ${footerClass}`}>
                {footer}
                {!isLoggedIn && (
                  <InlineStack gap={"400"}>
                    <Link external url={helpUrl} monochrome removeUnderline>
                      <Text as="p" tone="subdued">
                        {t("auth.common.footer.help")}
                      </Text>
                    </Link>
                    <Link external url={privacyUrl} monochrome removeUnderline>
                      <Text as="p" tone="subdued">
                        {t("auth.common.footer.privacy")}
                      </Text>
                    </Link>
                    <Link external url={termsUrl} monochrome removeUnderline>
                      <Text as="p" tone="subdued">
                        {t("auth.common.footer.terms")}
                      </Text>
                    </Link>
                  </InlineStack>
                )}
              </div>
            </BlockStack>
          </Card>
        </Page>
      </div>
    </AppFrame>
  );
}

export default CardWrapper;
