import React from "react";
import { Tooltip, Text, TextProps } from "@shopify/polaris";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/bg";
import useFormattedDate from "@/hooks/useFormattedDate";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

interface RenderDateProps {
  date: string;
  variant?: TextProps["variant"];
}

const RenderDate: React.FC<RenderDateProps> = ({ date, variant }) => {
  const { formattedDate, fromNow } = useFormattedDate({ date });
  return date ? (
    <Tooltip content={formattedDate} hasUnderline>
      <p className="tooltip_container">
        <Text as="span" variant={variant ?? "bodyMd"}>
          {fromNow}
        </Text>
      </p>
    </Tooltip>
  ) : (
    ""
  );
};

export default RenderDate;
