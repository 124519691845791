import React, { useEffect } from "react";
import {
  getClinicNavigationItems,
  getSettingsNavigationItems,
} from "@/clinic/utils";
import { getUserNavigationItems } from "@/user/utils";
import { ROOTS } from "@/utils/Constants";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// src/components/RouteMetaHandler.tsx

const RouteMetaHandler: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = useParams();
  const selectedClinicId = params.clinicId;

  useEffect(() => {
    const clinicMode = location.pathname.startsWith(ROOTS.CLINIC);
    if (clinicMode && !selectedClinicId) {
      document.title = "Medentic";
      return;
    } else {
      const navigationItems =
        clinicMode && selectedClinicId
          ? [
              ...getClinicNavigationItems(
                t,
                selectedClinicId,
                location.pathname
              ),
              ...getSettingsNavigationItems(
                t,
                selectedClinicId,
                location.pathname
              ),
            ]
          : getUserNavigationItems(t, location.pathname);

      const currentRoute = navigationItems.find((item) =>
        location.pathname.endsWith(item.url)
      );

      if (currentRoute) {
        document.title = `${t(currentRoute.label)} — Medentic`;
      } else {
        document.title = "Medentic";
      }
    }
  }, [location.pathname, selectedClinicId, t]);

  return null;
};

export default RouteMetaHandler;
