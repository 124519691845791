import { useQuery } from "@tanstack/react-query";
import {
  BlockStack,
  Checkbox,
  TextField,
  Badge,
  Text,
  SkeletonBodyText,
  Card,
  Box,
  InlineStack,
} from "@shopify/polaris";
import useApiRequest from "@/hooks/useApiRequest";
import { SchemaGroup, ComplianceData, PracticeInfo } from "../types";

interface ComplianceFormProps {
  schema: SchemaGroup;
  data: ComplianceData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDataChange: (field: string, value: any) => void;
  validationErrors: Record<string, string>;
}

const ComplianceForm: React.FC<ComplianceFormProps> = ({
  schema,
  data,
  onDataChange,
  validationErrors,
}) => {
  const { GET } = useApiRequest();

  const { data: practiceInfo, isLoading: isPracticeInfoLoading } =
    useQuery<PracticeInfo | null>({
      queryKey: ["bg-practice-info", data.bg_his_unique_id_number],
      queryFn: () =>
        GET<PracticeInfo>(
          `/api/compliance/bg/medical-practices?bg_his_unique_id_number=${data.bg_his_unique_id_number}`
        ),
      enabled:
        !!data.bg_his_unique_id_number &&
        data.bg_his_unique_id_number?.length >= 10,
    });

  return (
    <BlockStack gap="200">
      <Text as="h2" variant="headingMd">
        {schema.label}
      </Text>
      {schema.properties?.map((property) => {
        if (property.renderer.id === "checkbox") {
          return (
            <Checkbox
              key={property.name}
              label={property.label}
              checked={data[property.name] || false}
              onChange={(checked) => onDataChange(property.name, checked)}
              helpText={property.description}
              error={validationErrors[property.name]}
              disabled={property.isReadOnlyValue}
            />
          );
        }

        if (property.renderer.id === "number") {
          return (
            <TextField
              autoComplete="off"
              key={property.name}
              label={property.label}
              value={data[property.name] || ""}
              onChange={(value) => onDataChange(property.name, value)}
              helpText={property.description}
              error={validationErrors[property.name]}
              disabled={property.isReadOnlyValue}
            />
          );
        }

        if (property.renderer.id === "combobox") {
          return (
            <TextField
              autoComplete="off"
              key={property.name}
              label={property.label}
              value={data[property.name] || ""}
              onChange={(value) => onDataChange(property.name, value)}
              helpText={property.description}
              error={validationErrors[property.name]}
            />
          );
        }

        return null;
      })}

      {isPracticeInfoLoading ? <SkeletonBodyText lines={2} /> : ""}
      {/* Display additional practice information */}
      {practiceInfo && schema.name === "his_bg" && (
        <Card>
          <BlockStack gap={"025"}>
            <InlineStack gap={"100"}>
              <p>{practiceInfo.name}</p>

              <Box>
                <Badge tone={practiceInfo.isActive ? "success" : "info"}>
                  {practiceInfo.activeLabel}
                </Badge>
              </Box>
            </InlineStack>
            <p>{practiceInfo.address}</p>
            <p>{practiceInfo.medicalTypeLabel}</p>
          </BlockStack>
        </Card>
      )}
    </BlockStack>
  );
};

export default ComplianceForm;
