import AuthCardWrapper from "./AuthCardWrapper";
import React, { useState } from "react";
import useApiRequest from "../../hooks/useApiRequest";
import useOTT from "../../hooks/useOTT";
import { OTTResponse } from "../types";
import { RootState } from "../../store";
import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form,
  FormLayout,
  TextField,
  Box,
  Text,
  BlockStack,
} from "@shopify/polaris";

const TwoFactor: React.FC = () => {
  const { t } = useTranslation();
  const { handleOTT } = useOTT();
  const { POST } = useApiRequest();

  const [code, setCode] = useState<string>("");
  const [useRecovery, setUseRecovery] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<string | null>(null);

  const email = useSelector((state: RootState) => state.auth.email_for_2fa);

  const password = useSelector(
    (state: RootState) => state.auth.password_for_2fa
  );

  const handleCodeChange = (value: string) => {
    setCode(value);
    if (codeError) setCodeError(null);
  };

  const verifyMutation = useMutation({
    mutationFn: async () => {
      return POST<OTTResponse>("/api/auth/two-factor/challenge", {
        email,
        password,
        code,
        rememberDevice: true,
        isRecoveryCode: useRecovery,
      });
    },
    onSuccess: async (data: OTTResponse) => {
      if (data.ott) {
        await handleOTT(data.ott);
      }
    },
  });

  const handleSubmit = async () => {
    if (!code) {
      setCodeError(t("auth.passwordReset.error.passwordRequired"));
      return;
    }

    verifyMutation.mutate();
  };

  const toggleRecovery = () => {
    setUseRecovery(!useRecovery);
    setCode("");
  };

  return (
    <AuthCardWrapper>
      <BlockStack gap="500" inlineAlign="center">
        <Box paddingBlock="200">
          <Text variant="heading2xl" as="h1">
            {t(
              useRecovery
                ? "auth.twoFactor.recovery.resetTitle"
                : "auth.twoFactor.authentication.resetTitle"
            )}
          </Text>
          <Text as="p" variant="bodyMd" tone="subdued">
            {t(
              useRecovery
                ? "auth.twoFactor.recovery.description"
                : "auth.twoFactor.authentication.description"
            )}
          </Text>
        </Box>
      </BlockStack>
      <Box padding="400">
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              label={t("auth.twoFactor.code")}
              type="text"
              value={code}
              onChange={handleCodeChange}
              autoComplete="off"
              error={codeError ?? undefined}
              maxLength={useRecovery ? 12 : 6}
              autoFocus
            />
            <Button
              size="large"
              onClick={handleSubmit}
              fullWidth
              variant="primary"
              loading={verifyMutation.isPending}
            >
              {t("auth.twoFactor.loginCTA")}
            </Button>
            <Button onClick={toggleRecovery} fullWidth>
              {t(
                useRecovery
                  ? "auth.twoFactor.recovery.useRecoveryCodeCTA"
                  : "auth.twoFactor.authentication.useRecoveryCodeCTA"
              )}
            </Button>
          </FormLayout>
        </Form>
      </Box>
    </AuthCardWrapper>
  );
};

export default TwoFactor;
