import PasswordProtectedButton from "@/common/PasswordProtectedButton";
import React, { useState } from "react";
import TranslateLink from "@/common/TranslateLink";
import TwoStepAuthModal from "./TwoStepAuthModal";
import useApiRequest from "@/hooks/useApiRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Text,
  Loading,
  Divider,
  SkeletonDisplayText,
  SkeletonBodyText,
} from "@shopify/polaris";

interface TwoFactorStatusResponse {
  active: boolean;
}

const TwoStepAuthentication: React.FC = () => {
  const { t } = useTranslation();
  const { GET, DELETE } = useApiRequest();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const {
    data: twoFactorStatus,
    isFetching: isFetchingStatus,
    refetch,
  } = useQuery<TwoFactorStatusResponse>({
    queryKey: ["two-factor-status"],
    queryFn: () => GET("/api/user/me/two-factor"),
  });

  const isEnabled = twoFactorStatus?.active || false;

  const handleEnableTwoStep = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (refetchTwoFactor = false) => {
    setIsModalOpen(false);
    refetchTwoFactor && refetch();
  };

  const removeTwoFactorMutation = useMutation({
    mutationFn: () => DELETE("/api/user/me/two-factor"),
    onSuccess: () => {
      setIsFetching(false);
      refetch();
    },
  });

  const downloadRecoveryCodesMutation = useMutation({
    mutationFn: () => GET("/api/user/me/two-factor/recovery-codes"),
    onSuccess: (data) => {
      const blob = new Blob([data as string], {
        type: "text/plain;charset=utf-8",
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "recovery-codes.txt";
      a.click();
      window.URL.revokeObjectURL(url);
    },
  });

  const handleRemoveTwoFactor = () => {
    setIsFetching(true);
    removeTwoFactorMutation.mutate();
  };

  const handleDownloadCodes = (isVerified: boolean) => {
    if (isVerified) {
      downloadRecoveryCodesMutation.mutate();
    }
  };

  return (
    <Card padding={"0"}>
      {isFetchingStatus && <Loading />}
      {isFetchingStatus ? (
        <Box padding={"400"}>
          <BlockStack gap={"500"}>
            <BlockStack gap={"200"}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
            <BlockStack gap={"200"}>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />
            </BlockStack>
            <BlockStack gap={"200"}>
              <SkeletonBodyText lines={2} />
              <SkeletonDisplayText size="small" />
            </BlockStack>
          </BlockStack>
        </Box>
      ) : (
        <>
          <BlockStack gap="200">
            <Box padding="400">
              <BlockStack gap="400">
                <InlineStack align="space-between" blockAlign="center">
                  <InlineStack gap={"200"}>
                    <Text as="h2" variant="headingMd">
                      {t("twoStepAuth.title")}
                    </Text>
                    {isEnabled ? (
                      <Badge tone="success">{t("twoStepAuth.on")}</Badge>
                    ) : (
                      <Badge>{t("twoStepAuth.off")}</Badge>
                    )}
                  </InlineStack>
                  {isEnabled && (
                    <Button
                      variant="plain"
                      tone="critical"
                      onClick={handleRemoveTwoFactor}
                      loading={isFetching}
                    >
                      {t("twoStepAuth.removeButton")}
                    </Button>
                  )}
                </InlineStack>

                <TranslateLink text={t("twoStepAuth.description")} />
              </BlockStack>
            </Box>
            {isEnabled && <Divider />}
            <Box padding={"400"} paddingBlockStart={"0"}>
              {isEnabled ? (
                <>
                  <Box paddingBlock={"100"}></Box>
                  <BlockStack gap={"200"}>
                    <Text as="h2" variant="headingMd">
                      {t("twoStepAuth.recoveryCodes")}
                    </Text>
                    <TranslateLink
                      text={t("twoStepAuth.recoveryCodesDescription")}
                    />
                    <InlineStack align="end">
                      <Box paddingBlockStart="300">
                        <PasswordProtectedButton
                          label={t("twoStepAuth.downloadCodes")}
                          onVerified={handleDownloadCodes}
                          variant="secondary"
                          isLoading={downloadRecoveryCodesMutation.isPending}
                        />
                      </Box>
                    </InlineStack>
                  </BlockStack>
                </>
              ) : (
                <BlockStack gap={"300"}>
                  <Text as="h2" variant="headingMd">
                    {t("twoStepAuth.howItWorks")}
                  </Text>
                  <TranslateLink
                    text={t("twoStepAuth.howItWorksDescription")}
                  ></TranslateLink>
                  <Box>
                    <Button variant="primary" onClick={handleEnableTwoStep}>
                      {t("twoStepAuth.enableButton")}
                    </Button>
                  </Box>
                </BlockStack>
              )}
            </Box>
          </BlockStack>
          <TwoStepAuthModal
            open={isModalOpen}
            onClose={handleCloseModal}
            onCloseRecoveryModal={() => {
              handleCloseModal(true);
            }}
          />
        </>
      )}
    </Card>
  );
};

export default TwoStepAuthentication;
