/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Select, TextField } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { parsePhoneNumberWithError } from "libphonenumber-js";
import { useCountries } from "@/hooks/useCountries"; // context that provides the full list of countries
import { useClinicCountry } from "@/hooks/useClinicCountry";
/**
 * Each `countries` entry is { label: string; value: string } => e.g. { label: 'Pakistan', value: '+92' }
 */

interface PhoneNumberFieldProps {
  /**
   * The single string in E.164 format, e.g. "+923119574073"
   * If empty or invalid, we do our best to parse or fallback
   */
  value: string;

  /**
   * Called whenever user changes either the country code or local number,
   * passing the new combined E.164 string, e.g. "+923119574073"
   */
  onChange: (fullE164: string) => void;

  /**
   * Optional label, error, help text, etc.
   */
  phoneLabel?: string;
  countryLabel?: string;
  phoneError?: string;
  phoneHelpText?: string;
  disabled?: boolean;
  isRequired?: boolean;
}

export const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({
  value,
  onChange,
  phoneLabel,
  countryLabel,
  phoneError,
  phoneHelpText,
  disabled = false,
  isRequired = false,
}) => {
  const { t } = useTranslation();
  const { countries, isLoading } = useCountries();
  const clinicCountry = useClinicCountry();
  const defaultCountryPhoneCode =
    countries.find((c) => c.label === clinicCountry?.country)?.value ?? "+1";

  // 1) "Show more" logic
  const [selectedCode, setSelectedCode] = useState<string>(
    defaultCountryPhoneCode
  );

  useEffect(() => {
    setSelectedCode(defaultCountryPhoneCode);
  }, [defaultCountryPhoneCode]);

  const [localNumber, setLocalNumber] = useState<string>("");

  // 2) Parse the incoming `value` from E.164 to { code, number }
  //    If parsing fails, fallback to +1 / empty
  useMemo(() => {
    if (!value) {
      setSelectedCode(defaultCountryPhoneCode);
      setLocalNumber("");
      return;
    }
    try {
      const phoneObj = parsePhoneNumberWithError(value);
      if (phoneObj) {
        const cc = `+${phoneObj.countryCallingCode}`; // e.g. +92
        const ln = phoneObj.nationalNumber; // e.g. 3119574073
        setSelectedCode(cc);
        setLocalNumber(ln);
      } else {
        // could not parse
        setSelectedCode(defaultCountryPhoneCode);
        setLocalNumber("");
      }
    } catch (err) {
      setSelectedCode(value);
      setLocalNumber("");
    }
    // We only want to parse once when `value` changes
  }, [value]);

  // 3) "Show more" inside the list
  const displayedCountries = useMemo(() => {
    // Take first `visibleCount` from the array
    return countries.map((c) => ({
      label: c.label,
      value: c.value,
    }));
  }, [countries]);

  // 4) Final list with the "Show more" at the end
  const countryOptions = useMemo(() => {
    const baseList = displayedCountries.map((c) => ({
      label: c.label,
      value: c.value,
    }));

    return baseList;
  }, [displayedCountries, countries, t]);

  // 5) Rebuild E.164 whenever user changes either code or number
  const rebuildE164 = useCallback((code: string, num: string) => {
    // remove any spaces from the local number
    const trimmedNum = num.replace(/\s+/g, "");
    try {
      // Attempt to build a valid E.164 string
      // e.g. +92 + '3119574073'
      const full = code + trimmedNum;
      const phoneObj = parsePhoneNumberWithError(full);
      if (phoneObj) {
        return phoneObj.number; // E.164 format, e.g. +923119574073
      }
      // If parse fails, just return the raw code+number
      return full;
    } catch (err) {
      return code + trimmedNum;
    }
  }, []);

  // 6) When user picks a new code
  const handleCodeChange = useCallback(
    (newCode: string) => {
      setSelectedCode(newCode);
      const newE164 = rebuildE164(newCode, localNumber);
      onChange(newE164);
    },
    [localNumber, onChange, rebuildE164]
  );

  // 7) When user types a new local number
  const handleLocalNumberChange = useCallback(
    (val: string) => {
      setLocalNumber(val);
      if (val.length > 3) {
        const newE164 = rebuildE164(selectedCode, val);
        onChange(newE164);
      }
    },
    [rebuildE164, selectedCode, onChange]
  );

  // 8) On blur, remove spaces from local number
  const handleLocalBlur = useCallback(() => {
    const trimmed = localNumber.replace(/\s+/g, "");
    if (trimmed !== localNumber) {
      setLocalNumber(trimmed);
      const newE164 = rebuildE164(selectedCode, trimmed);
      onChange(newE164);
    }
  }, [localNumber, selectedCode, rebuildE164, onChange]);

  const CountryList = useMemo(() => {
    return (
      <Select
        label={
          countryLabel ?? t("phoneNumberField.countryLabel", "Country Code")
        }
        labelHidden
        value={selectedCode}
        onChange={handleCodeChange}
        options={countryOptions}
        disabled={disabled || isLoading}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryOptions, selectedCode, countryLabel, disabled, isLoading]);

  return (
    <TextField
      label={phoneLabel || t("phoneNumberField.phoneLabel", "Phone Number")}
      value={localNumber}
      onChange={handleLocalNumberChange}
      autoComplete="tel"
      disabled={disabled || isLoading}
      prefix={selectedCode}
      helpText={phoneHelpText}
      error={phoneError}
      onBlur={handleLocalBlur}
      connectedLeft={CountryList}
      requiredIndicator={isRequired}
    />
  );
};
