import AuthCardWrapper from "./AuthCardWrapper";
import React, { useEffect, useState } from "react";
import TranslateLink from "../../common/TranslateLink";
import useApiRequest from "../../hooks/useApiRequest";
import { ErrorResponse } from "../../types/commonTypes";
import { RegisterResponse } from "../types";
import { ROUTES } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validateEmail } from "../utils";
import {
  setEmailFor2FA,
  setPasswordFor2FA,
  setTobeVerifiedEmail,
  setRegisterData,
} from "../authSlice";
import {
  Button,
  Form,
  FormLayout,
  TextField,
  Box,
  Text,
  BlockStack,
  InlineStack,
  Link,
} from "@shopify/polaris";
import { RootState } from "@/store";

const RegisterForm: React.FC = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language;
  const termsUrl = `https://www.medentic.app/${locale}/legal/terms-of-service`;
  const privacyUrl = `https://www.medentic.app/${locale}/legal/privacy`;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { POST } = useApiRequest();

  const storedRegisterData = useSelector((state: RootState) => ({
    email: state.auth.register_email,
    firstName: state.auth.register_firstName,
    lastName: state.auth.register_lastName,
  }));

  const [email, setEmail] = useState<string>(storedRegisterData.email);
  const [firstName, setFirstName] = useState<string>(
    storedRegisterData.firstName
  );
  const [lastName, setLastName] = useState<string>(storedRegisterData.lastName);
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  useEffect(() => {
    const isFormFilled =
      email && firstName && lastName && password && confirmPassword;
    const isNoError =
      !emailError && !firstNameError && !lastNameError && !passwordError;
    setIsFormValid(!!isFormFilled && isNoError);
  }, [
    email,
    firstName,
    lastName,
    password,
    confirmPassword,
    emailError,
    firstNameError,
    lastNameError,
    passwordError,
  ]);

  const handleEmailChange = (value: string) => {
    setEmail(value);
    if (emailError) setEmailError(null);
    dispatch(setRegisterData({ email: value, firstName, lastName }));
  };

  const handleFirstNameChange = (value: string) => {
    setFirstName(value);
    if (firstNameError) setFirstNameError(null);
    dispatch(setRegisterData({ email, firstName: value, lastName }));
  };

  const handleLastNameChange = (value: string) => {
    setLastName(value);
    if (lastNameError) setLastNameError(null);
    dispatch(setRegisterData({ email, firstName, lastName: value }));
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    if (passwordError) setPasswordError(null);
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    if (passwordError) setPasswordError(null);
  };

  const validatePassword = (password: string) => {
    return password.length >= 6;
  };

  const registerMutation = useMutation<RegisterResponse, ErrorResponse, void>({
    mutationFn: () =>
      POST(
        "/api/auth/register",
        {
          email,
          password,
          firstName,
          lastName,
        },
        true
      ),
    onSuccess: (data) => {
      if (data.status === "success") {
        dispatch(setTobeVerifiedEmail(email));
        dispatch(setEmailFor2FA(email));
        dispatch(setPasswordFor2FA(password));

        navigate(ROUTES.REGISTER_CHECK_EMAIL);
      } else {
        setEmailError(
          data.message ?? t("auth.registration.email.error.emailIsNotCorrect")
        );
      }
    },
    onError: (error: ErrorResponse) => {
      setEmailError(
        error?.response?.data?.error?.display ||
          t("auth.registration.email.error.emailIsNotCorrect")
      );
    },
  });

  const handleSubmit = () => {
    let valid = true;

    if (!validateEmail(email)) {
      setEmailError(t("auth.registration.email.error.emailIsNotCorrect"));
      valid = false;
    }

    if (!firstName) {
      setFirstNameError(t("auth.registration.email.error.firstNameRequired"));
      valid = false;
    }

    if (!lastName) {
      setLastNameError(t("auth.registration.email.error.lastNameRequired"));
      valid = false;
    }

    if (!validatePassword(password)) {
      setPasswordError(t("auth.registration.email.error.passwordLength"));
      valid = false;
    }

    if (password !== confirmPassword) {
      setPasswordError(t("auth.registration.email.error.passNoMatch"));
      valid = false;
    }

    if (valid) {
      registerMutation.mutate();
    }
  };

  return (
    <AuthCardWrapper
      footerClass="relative-auth-footer"
      footer={
        <Box paddingBlockStart={"500"} paddingBlockEnd={"500"} maxWidth="460px">
          <Text as={"p"} alignment="start" tone="subdued">
            <TranslateLink
              text={t("auth.common.footer.byProceeding", {
                termsUrl,
                privacyUrl,
              })}
            />
          </Text>
        </Box>
      }
    >
      <BlockStack gap="500" inlineAlign="start">
        <Box paddingBlock="200">
          <Text variant="heading2xl" as="h1">
            {t("auth.registration.common.createAccount")}
          </Text>
        </Box>
      </BlockStack>
      <Box paddingBlock={"400"}>
        <Form onSubmit={handleSubmit}>
          <FormLayout>
            <TextField
              autoFocus
              label={t("auth.registration.email.email")}
              type="email"
              value={email}
              onChange={handleEmailChange}
              autoComplete="email"
              error={emailError ?? undefined}
            />
            <FormLayout.Group helpText={t("auth.registration.email.nameNote")}>
              <TextField
                label={t("auth.registration.email.firstName")}
                value={firstName}
                onChange={handleFirstNameChange}
                autoComplete="off"
                error={firstNameError ?? undefined}
              />
              <TextField
                label={t("auth.registration.email.lastName")}
                value={lastName}
                onChange={handleLastNameChange}
                autoComplete="off"
                error={lastNameError ?? undefined}
              />
            </FormLayout.Group>
            <TextField
              label={t("auth.registration.email.password")}
              type="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
              error={passwordError ?? undefined}
            />
            <TextField
              label={t("auth.registration.email.confirmPassword")}
              type="password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              autoComplete="new-password"
              error={passwordError ?? undefined}
            />
            <Button
              variant="primary"
              onClick={handleSubmit}
              fullWidth
              size="large"
              loading={registerMutation.isPending}
              disabled={!isFormValid}
            >
              {t("auth.registration.common.createAccount")}
            </Button>
          </FormLayout>
        </Form>
      </Box>
      <InlineStack gap={"200"} align="center">
        <Text as="p">{t("auth.registration.common.alreadyHaveAccount")}</Text>
        <Link url={ROUTES.LOGIN} removeUnderline>
          {t("auth.registration.common.logIn")}
        </Link>
      </InlineStack>
    </AuthCardWrapper>
  );
};

export default RegisterForm;
