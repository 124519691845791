import DividerWithText from "@/common/DividerWithText";
import React, { useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import useOTT from "@/hooks/useOTT";
import { EmailIcon } from "@shopify/polaris-icons";
import { ErrorResponse, PageTypes } from "@/types/commonTypes";
import { ROUTES } from "@/utils/Constants";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { usePasswordVerification } from "@/common/PasswordVerificationContext";
import { useTranslation } from "react-i18next";
import {
  setEmailFor2FA,
  setPasswordFor2FA,
  setTobeVerifiedEmail,
} from "@/auth/authSlice";

import {
  Button,
  Form,
  FormLayout,
  TextField,
  Box,
  Text,
  BlockStack,
  Link,
  InlineStack,
  Avatar,
} from "@shopify/polaris";
import { removeLocalStorage } from "@/utils/localStorageUtils";
import { validateEmail } from "@/auth/utils";
import { InviteData, VerifyResponse } from "@/auth/types";
import TranslateLink from "@/common/TranslateLink";
import CardWrapper from "../CardWrapper";

type RegisterToJoinProps = {
  acceptWithEmail: () => void;
  inviteData: InviteData | undefined;
};

const LoginToJoin: React.FC<RegisterToJoinProps> = ({
  acceptWithEmail,
  inviteData,
}) => {
  const { t } = useTranslation();
  const { handleOTT } = useOTT();
  const { POST } = useApiRequest();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [email, setEmail] = useState<string>(inviteData?.email ?? "");
  const [password, setPassword] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [error, setError] = useState<string>("");
  const [step, setStep] = useState<1 | 2>(1);
  const { setVerifiedTimeOnLogin } = usePasswordVerification();

  const handleEmailChange = (value: string) => {
    setEmail(value);
    if (emailError) setEmailError(null);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    if (passwordError) setPasswordError(null);
    if (error) setError("");
  };

  const loginMutation = useMutation({
    mutationFn: async () => {
      return POST<VerifyResponse>("/api/auth/ott/login", { email, password });
    },
    onSuccess: async (data: VerifyResponse) => {
      setVerifiedTimeOnLogin();
      removeLocalStorage("selectedLanguage");

      if ("ott" in data && data.ott) {
        await handleOTT(data.ott);
      } else if ("next" in data && data.next === "validate_2fa") {
        dispatch(setEmailFor2FA(email));
        dispatch(setPasswordFor2FA(password));
        navigate(ROUTES.TWO_FACTOR);
      } else if ("next" in data && data.next === "validate_email") {
        dispatch(setTobeVerifiedEmail(email));
        dispatch(setEmailFor2FA(email));
        dispatch(setPasswordFor2FA(password));
        navigate(ROUTES.REGISTER_CHECK_EMAIL);
      }
    },
    onError: (error: ErrorResponse) => {
      setError(
        error?.response?.data?.error?.display ||
          t("auth.registration.email.error.emailIsNotCorrect")
      );
    },
  });

  const handleSubmitEmail = async () => {
    if (!validateEmail(email)) {
      setEmailError(t("auth.registration.email.error.emailIsNotCorrect"));
      return;
    }
    setStep(2);
  };

  const handleAcceptInvite = async () => {
    if (!password) {
      setPasswordError(t("auth.registration.email.error.passNoMatch"));
      return;
    }

    loginMutation.mutate();
  };

  const { company, inviter } = inviteData || {};

  return (
    <CardWrapper type={PageTypes.UNKNOW} wrapperClass="white-card-wrapper">
      <Box width="800">
        <BlockStack gap="300" inlineAlign="start">
          <InlineStack gap="200" blockAlign="center">
            <Box>
              <Avatar
                size="lg"
                initials={company?.avatar.initials ?? ""}
                source={company?.avatar.colorSchema.background}
                name={company?.name ?? ""}
              />
            </Box>
            <BlockStack>
              <Text as="h3" variant="headingLg">
                {company?.name}
              </Text>
              <Text as="p" tone="subdued">
                {company?.website}
              </Text>
            </BlockStack>
          </InlineStack>

          <Text variant="headingMd" as="h2">
            {t("joinForm.invitationTitle", { companyName: company?.name })}
          </Text>
          <TranslateLink
            text={t("joinForm.invitationDescription", {
              inviterName: `${inviter?.firstName} ${inviter?.lastName}`,
              inviterEmail: inviter?.email,
              companyName: company?.name,
            })}
          />
        </BlockStack>
        <Box paddingBlock="400" minWidth="800">
          <Form onSubmit={step === 1 ? handleSubmitEmail : handleAcceptInvite}>
            {step === 1 && (
              <FormLayout>
                <TextField
                  autoFocus
                  label={t("auth.login.email")}
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  autoComplete="email"
                  error={emailError ?? undefined}
                />
                <Button
                  disabled={!validateEmail(email)}
                  size="large"
                  onClick={handleSubmitEmail}
                  fullWidth
                  icon={EmailIcon}
                  variant="primary"
                >
                  {t("auth.login.continueWithEmailCTA")}
                </Button>
                <InlineStack align="center" blockAlign="center">
                  <DividerWithText text={t("auth.login.or")} />
                </InlineStack>
                <Button
                  size="large"
                  variant="secondary"
                  onClick={acceptWithEmail}
                  fullWidth
                  loading={loginMutation.isPending}
                >
                  {t("joinForm.acceptWith", {
                    user: t("joinForm.newEmail"),
                  })}
                </Button>
              </FormLayout>
            )}
            {step === 2 && (
              <FormLayout>
                <TextField
                  label={t("auth.login.email")}
                  type="email"
                  value={email}
                  disabled
                  autoComplete="email"
                />
                <Link onClick={() => setStep(1)}>
                  {t("auth.passwordResetRequest.changeEmail")}
                </Link>
                <TextField
                  label={t("auth.login.password")}
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  autoComplete="current-password"
                  error={passwordError ?? undefined}
                  autoFocus
                />
                <Button
                  size="large"
                  onClick={handleAcceptInvite}
                  fullWidth
                  loading={
                    loginMutation.isPending || !!queryClient.isMutating()
                  }
                  variant="primary"
                  disabled={!password}
                >
                  {t("joinForm.loginToAccept")}
                </Button>
              </FormLayout>
            )}
          </Form>
        </Box>
      </Box>
    </CardWrapper>
  );
};

export default LoginToJoin;
