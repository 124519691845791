import { ROUTES } from "@/utils/Constants";
import { setLocalStorage } from "@/utils/localStorageUtils";
import { useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

const useCheckInviteToken = () => {
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (token && location.pathname?.startsWith(ROUTES.JOIN)) {
      setLocalStorage("invite_token", token);
    }
  }, [location, token]);
};

export default useCheckInviteToken;
