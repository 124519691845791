import CardWrapper from "@/auth/components/CardWrapper";
import Cookies from "js-cookie";
import React, { useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { PageTypes } from "@/types/commonTypes";
import { ROOTS, ROUTES, USER_ROUTES } from "@/utils/Constants";
import { setIsInitializingApp } from "@/reducers/appSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { VerifyResponse } from "../types";
import {
  Banner,
  BlockStack,
  Box,
  Button,
  Loading,
  Text,
} from "@shopify/polaris";

const VerifyAlternativeEmail: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { POST } = useApiRequest();
  const [isVerified, setIsVerified] = useState(false);

  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const {
    mutate: verifyEmailMutation,
    data = { message: "" },
    isPending,
  } = useMutation({
    mutationFn: (token: string) =>
      POST<VerifyResponse>("/api/auth/alternative-emails/verify", { token }),
    onSuccess: () => {
      setIsVerified(true);
    },
    onError: () => {
      setIsVerified(false);
    },
  });

  const handleVerifyEmail = () => {
    if (token) {
      verifyEmailMutation(token);
    }
  };

  const handleClose = () => {
    const isLoggedIn = !!Cookies.get("access_token");
    if (!isLoggedIn) {
      dispatch(setIsInitializingApp(true));
      navigate(ROUTES.LOGIN);
    } else {
      navigate(`${ROOTS.USER}/${USER_ROUTES.SECURITY}`);
    }
  };

  return (
    <>
      <CardWrapper type={PageTypes.AUTH}>
        {isPending && <Loading />}{" "}
        <BlockStack gap="400" inlineAlign="center" align="center">
          {!isVerified ? (
            <>
              <Box maxWidth="300px">
                <BlockStack gap="400" inlineAlign="start" align="center">
                  <Text as="h2" variant="headingLg">
                    {t("verifyAlternativeEmail.confirmTitle")}
                  </Text>
                  <Text as="p" tone="subdued">
                    {t("verifyAlternativeEmail.confirmDescription")}
                  </Text>

                  <Button
                    fullWidth
                    variant="primary"
                    loading={isPending}
                    onClick={handleVerifyEmail}
                    disabled={!token}
                  >
                    {t("verifyAlternativeEmail.acceptButton")}
                  </Button>
                </BlockStack>
              </Box>
            </>
          ) : (
            <>
              <Box maxWidth="300px">
                <BlockStack gap="400" inlineAlign="center" align="center">
                  <Banner title={data?.message} tone="success" />

                  <Button onClick={handleClose} fullWidth>
                    {t("verifyAlternativeEmail.closeButton")}
                  </Button>
                </BlockStack>
              </Box>
            </>
          )}
        </BlockStack>
      </CardWrapper>
    </>
  );
};

export default VerifyAlternativeEmail;
