import { FooterHelp } from "@shopify/polaris";
import TranslateLink from "./TranslateLink";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { CLINIC_ROUTES, ROOTS } from "../utils/Constants";

function PageFooter() {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  const getTranslationKey = () => {
    // Remove trailing slash if exists
    const path = pathname.replace(/\/$/, "");

    // Check clinic routes
    if (path.startsWith(ROOTS.CLINIC)) {
      // Remove clinic root and extract the path after clinic ID
      const clinicPathWithId = path.replace(ROOTS.CLINIC + "/", "");
      const afterClinicId = clinicPathWithId.replace(/^[^/]+\//, "");

      // Handle specific clinic routes
      if (afterClinicId.startsWith("patients/")) {
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_DATA_MANAGEMENT)
          // return "help_footer.patients_data_management";
          return "help_footer.default";
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_TAGS)
          // return "help_footer.patients_tags";
          return "help_footer.default";
        if (afterClinicId === CLINIC_ROUTES.PATIENTS_PROCEDURES)
          // return "help_footer.patients_procedures";
          return "help_footer.default";

        if (afterClinicId === CLINIC_ROUTES.PATIENTS_TREATMENT_PLANS)
          // return "help_footer.patients_treatment_plans";
          return "help_footer.default";

        if (afterClinicId === CLINIC_ROUTES.PATIENTS_MEDICATIONS)
          // return "help_footer.patients_medications";
          return "help_footer.default";
        if (afterClinicId.match(/^patients\/[\w-]+$/))
          return "help_footer.patient_details";
        return "help_footer.patients";
      }
    }

    return "help_footer.default";
  };

  return (
    <FooterHelp>
      <TranslateLink text={t(getTranslationKey())} />
    </FooterHelp>
  );
}

export default PageFooter;
