import Cookies from "js-cookie";
import React from "react";
import TranslateLink from "@/common/TranslateLink";
import useApiRequest from "@/hooks/useApiRequest";
import { getFullUrl } from "@/api/baseAPI";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  BlockStack,
  Box,
  Button,
  Card,
  InlineStack,
  Link,
  Loading,
  SkeletonBodyText,
  SkeletonDisplayText,
  Text,
} from "@shopify/polaris";

interface Service {
  platform: string;
  status: string;
  email: string;
  id: string;
}

const LoginServices: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { GET, DELETE } = useApiRequest();
  const token = Cookies.get("access_token");

  // Fetch Login Services
  const { data: loginServices = [], isFetching: isLoadingLoginServices } =
    useQuery<Service[]>({
      queryKey: ["login-services"],
      queryFn: () => GET<Service[]>("/api/user/me/login-services"),
    });

  // Handle Login Service Disconnection
  const disconnectService = useMutation({
    mutationFn: (platform: string) =>
      DELETE(`/api/user/me/login-services/${platform}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["login-services"] });
    },
  });

  return (
    <>
      {isLoadingLoginServices ? (
        <>
          <Loading />
          <Card>
            <BlockStack gap={"300"}>
              {/* Skeleton for the title and description */}
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={2} />

              {/* Skeleton for service items */}
              {[...Array(2)].map((_, index) => (
                <BlockStack key={index} gap={"300"}>
                  <SkeletonBodyText lines={1} />
                  <SkeletonBodyText lines={1} />
                </BlockStack>
              ))}
            </BlockStack>
          </Card>
        </>
      ) : (
        <Card>
          <BlockStack>
            {loginServices.filter((s) => s.status === "connected").length ===
            0 ? (
              <BlockStack>
                <Text as="h1" variant="headingMd">
                  {t("loginServices.title")}
                </Text>
                <Box paddingBlock="200">
                  <Text as="p" tone="subdued">
                    {t("loginServices.description")}
                  </Text>
                </Box>
                <Box padding="600">
                  <Text as="p" alignment="center" tone="subdued">
                    {t("loginServices.noServiceConnected")}
                  </Text>
                </Box>
              </BlockStack>
            ) : null}
            {loginServices.map((service) =>
              service.status === "connected" ? (
                <BlockStack gap={"300"}>
                  <Text as="h1" variant="headingMd">
                    {t("loginServices.enabledLogin", {
                      platform: `${service.platform
                        .charAt(0)
                        .toUpperCase()}${service.platform.slice(1)}`,
                    })}
                  </Text>
                  <TranslateLink
                    text={t("loginServices.loginUsing", {
                      platform: `${service.platform
                        .charAt(0)
                        .toUpperCase()}${service.platform.slice(1)}`,
                      email: service.email,
                    })}
                  />

                  <InlineStack align="end">
                    <Button
                      loading={disconnectService.isPending}
                      variant="plain"
                      tone="critical"
                      onClick={() => disconnectService.mutate(service.platform)}
                    >
                      {t("loginServices.disconnect")}
                    </Button>
                  </InlineStack>
                </BlockStack>
              ) : (
                <Box key={service.platform} paddingBlockEnd="100">
                  <Link
                    removeUnderline
                    onClick={() => {
                      window.location.href = getFullUrl(
                        `/api/user/me/login-services/${service.platform}/redirect?access_token=${token}`
                      );
                    }}
                  >
                    {t("loginServices.connectTo", {
                      platform: service.platform,
                    })}
                  </Link>
                </Box>
              )
            )}
          </BlockStack>
        </Card>
      )}
    </>
  );
};

export default LoginServices;
