import React, { useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { BlockStack, InlineError, Modal, TextField } from "@shopify/polaris";
import { GeneralResponse } from "@/types/commonTypes";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

interface ChangePasswordProps {
  open: boolean;
  onClose: () => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { POST } = useApiRequest();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState<string | null>(null);

  const changePasswordMutation = useMutation({
    mutationFn: () =>
      POST<GeneralResponse>("/api/user/me/password", {
        oldPassword,
        newPassword,
      }),
    onSuccess: () => {
      onClose();
    },
    onError: () => {
      setError(t("security.passwordChangeError"));
    },
  });

  const handleChangePassword = () => {
    if (newPassword !== confirmPassword) {
      setError(t("security.passwordMismatchError"));
      return;
    }
    changePasswordMutation.mutate();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("security.changePasswordModalTitle")}
      primaryAction={{
        content: t("security.changePasswordButton"),
        onAction: handleChangePassword,
        loading: changePasswordMutation.isPending,
      }}
      secondaryActions={[
        {
          content: t("common.cancel"),
          onAction: onClose,
        },
      ]}
    >
      <Modal.Section>
        <BlockStack gap="200">
          <TextField
            label={t("security.currentPassword")}
            value={oldPassword}
            onChange={setOldPassword}
            type="password"
            autoComplete="current-password"
            autoFocus
          />
          <TextField
            label={t("security.newPassword")}
            value={newPassword}
            onChange={setNewPassword}
            type="password"
            autoComplete="new-password"
          />
          <TextField
            label={t("security.confirmPassword")}
            value={confirmPassword}
            onChange={setConfirmPassword}
            type="password"
            autoComplete="new-password"
          />
          {error && <InlineError message={error} fieldID="password_error" />}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default ChangePassword;
