import { useQuery } from "@tanstack/react-query";
import {
  BlockStack,
  TextField,
  Select,
  Checkbox,
  Text,
  SkeletonBodyText,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import {
  BGRComplianceData,
  Country,
  MedicalSpecialistCode,
  RegionCode,
} from "../../types";
import { useEffect, useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";

interface BGRFormProps {
  formState: BGRComplianceData;
  setFormState: React.Dispatch<React.SetStateAction<BGRComplianceData>>;
  setShowSaveBar: (show: boolean) => void;
  setValidationErrors: React.Dispatch<
    React.SetStateAction<Record<string, string>>
  >;
  showValidationError: boolean; // Indicates whether the form has been submitted
}

const BGRForm: React.FC<BGRFormProps> = ({
  formState,
  setFormState,
  setShowSaveBar,
  setValidationErrors,
  showValidationError,
}) => {
  const { t } = useTranslation();
  const { GET } = useApiRequest();

  const [uniqueIdError, setUniqueIdError] = useState<string>("");

  // Fetch country data
  const { data: countries = [] } = useQuery<Country[]>({
    queryKey: ["countries"],
    queryFn: () => GET("/api/metadata/countries"),
  });

  // Fetch compliance data
  const { data: complianceData, isLoading: isComplianceLoading } = useQuery({
    queryKey: ["compliance-data", "BGR"],
    queryFn: () =>
      GET<BGRComplianceData>("/api/compliance/bg/medical-specialist"),
  });

  // Fetch medical specialist codes
  const {
    data: medicalSpecialistCodes = [],
    isLoading: isMedicalSpecialistLoading,
  } = useQuery<MedicalSpecialistCode[]>({
    queryKey: ["medical-specialist-codes"],
    queryFn: () =>
      GET<MedicalSpecialistCode[]>("/api/metadata/bg/medical-specialist-codes"),
  });

  // Fetch region codes
  const { data: regionCodes = [], isLoading: isRegionCodesLoading } = useQuery<
    RegionCode[]
  >({
    queryKey: ["medical-region-codes"],
    queryFn: () =>
      GET<RegionCode[]>("/api/metadata/bg/address/rhif-area-codes"),
  });

  // Sync compliance data with form state
  useEffect(() => {
    if (complianceData) {
      setFormState(complianceData);
    }
  }, [complianceData, setFormState]);

  // Set default medical specialist code
  useEffect(() => {
    if (
      medicalSpecialistCodes.length > 0 &&
      !isComplianceLoading &&
      !complianceData?.medicalSpecialistCode
    ) {
      setFormState((prev) => ({
        ...prev,
        medicalSpecialistCode: medicalSpecialistCodes[0].value,
      }));
      setShowSaveBar(true);
    }
  }, [
    medicalSpecialistCodes,
    complianceData,
    setFormState,
    isComplianceLoading,
    setShowSaveBar,
  ]);

  // Set default region code
  useEffect(() => {
    if (
      regionCodes.length > 0 &&
      !isComplianceLoading &&
      !complianceData?.rhifAreaCode
    ) {
      setFormState((prev) => ({
        ...prev,
        rhifAreaCode: regionCodes[0].value,
      }));
      setShowSaveBar(true);
    }
  }, [
    regionCodes,
    complianceData,
    isComplianceLoading,
    setFormState,
    setShowSaveBar,
  ]);

  // Validate unique ID number after submission
  useEffect(() => {
    if (!formState.uniqueIdNumber) {
      const errorMessage = t(
        "userCompliance.validation.uniqueIdNumberRequired"
      );
      setUniqueIdError(errorMessage);
      setValidationErrors((prev) => ({
        ...prev,
        uniqueIdNumber: errorMessage,
      }));
    } else {
      setUniqueIdError("");
      setValidationErrors((prev) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { uniqueIdNumber, ...rest } = prev;
        return rest;
      });
    }
  }, [formState.uniqueIdNumber, setValidationErrors, t]);

  const handleInputChange = (
    field: keyof BGRComplianceData,
    value: string | boolean
  ) => {
    setFormState((prev: BGRComplianceData) => ({ ...prev, [field]: value }));
    setShowSaveBar(true);
  };

  return (
    <BlockStack gap="200">
      <Text as="h2" variant="headingMd">
        {t("userCompliance.formTitle")}
      </Text>
      <Text as="p">{t("userCompliance.formDescription")}</Text>
      {isComplianceLoading ? (
        <SkeletonBodyText lines={8} />
      ) : (
        <>
          <Checkbox
            label={t("userCompliance.activateCompliance")}
            checked={formState.active}
            onChange={(checked) => handleInputChange("active", checked)}
            helpText={t("userCompliance.checkboxDescription")}
          />
          <TextField
            label={t("userCompliance.formLabels.medicalSpecialistId")}
            value={formState.uniqueIdNumber || ""}
            onChange={(value) => handleInputChange("uniqueIdNumber", value)}
            error={showValidationError ? uniqueIdError : undefined}
            autoComplete="off"
            requiredIndicator
          />
          {isMedicalSpecialistLoading ? (
            <SkeletonBodyText />
          ) : (
            <Select
              label={t("userCompliance.formLabels.medicalSpecialtyCode")}
              options={medicalSpecialistCodes.map((code) => ({
                label: code.label,
                value: code.value,
              }))}
              value={formState.medicalSpecialistCode || ""}
              onChange={(value) =>
                handleInputChange("medicalSpecialistCode", value)
              }
            />
          )}
          {isRegionCodesLoading ? (
            <SkeletonBodyText />
          ) : (
            <Select
              label={t("userCompliance.formLabels.regionCode")}
              options={regionCodes.map((code) => ({
                label: code.label,
                value: code.value,
              }))}
              value={formState.rhifAreaCode || ""}
              onChange={(value) => handleInputChange("rhifAreaCode", value)}
              helpText={t("userCompliance.formLabels.regionCodeHelp")}
            />
          )}
          <TextField
            label={t("userCompliance.formLabels.email")}
            value={formState.email || ""}
            onChange={(value) => handleInputChange("email", value)}
            autoComplete="email"
            helpText={t("userCompliance.formLabels.emailHelp")}
          />
          <TextField
            label={t("userCompliance.formLabels.phone")}
            value={formState.phone || ""}
            onChange={(value) => handleInputChange("phone", value)}
            autoComplete="tel"
            helpText={t("userCompliance.formLabels.phoneHelp")}
            connectedLeft={
              <Select
                label={t("userProfile.countryCode")}
                value={formState.phoneCountryCode}
                onChange={(value) =>
                  handleInputChange("phoneCountryCode", value)
                }
                options={countries.map((c) => ({
                  label: `${c.name}`,
                  value: c.phoneCountryCode,
                }))}
                labelHidden
              />
            }
          />
        </>
      )}
    </BlockStack>
  );
};

export default BGRForm;
