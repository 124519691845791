import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AuthState {
  email_tobe_verified: string;
  email_for_forgot_password: string;
  email_for_2fa: string;
  password_for_2fa: string;
  skip_activation_check: string;
  register_email: string;
  register_firstName: string;
  register_lastName: string;
}

const initialState: AuthState = {
  email_tobe_verified: "",
  email_for_forgot_password: "",
  email_for_2fa: "",
  password_for_2fa: "",
  skip_activation_check: "",
  register_email: "",
  register_firstName: "",
  register_lastName: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setTobeVerifiedEmail(state, action: PayloadAction<string>) {
      state.email_tobe_verified = action.payload;
    },
    setEmailForForgotPassword(state, action: PayloadAction<string>) {
      state.email_for_forgot_password = action.payload;
    },
    setEmailFor2FA(state, action: PayloadAction<string>) {
      state.email_for_2fa = action.payload;
    },
    setPasswordFor2FA(state, action: PayloadAction<string>) {
      state.password_for_2fa = action.payload;
    },
    setSkipActivationCheck(state, action: PayloadAction<string>) {
      state.skip_activation_check = action.payload;
    },
    setRegisterData(
      state,
      action: PayloadAction<{
        email: string;
        firstName: string;
        lastName: string;
      }>
    ) {
      state.register_email = action.payload.email;
      state.register_firstName = action.payload.firstName;
      state.register_lastName = action.payload.lastName;
    },
    clearAuthState(state) {
      state.email_tobe_verified = "";
      state.email_for_forgot_password = "";
      state.email_for_2fa = "";
      state.password_for_2fa = "";
      state.skip_activation_check = "";
      state.register_email = "";
      state.register_firstName = "";
      state.register_lastName = "";
    },
  },
});

export const {
  setTobeVerifiedEmail,
  setEmailForForgotPassword,
  setEmailFor2FA,
  setPasswordFor2FA,
  clearAuthState,
  setSkipActivationCheck,
  setRegisterData,
} = authSlice.actions;
export default authSlice.reducer;
