import React, { useState, useCallback, useMemo } from "react";
import {
  Combobox,
  Listbox,
  Icon,
  Tag,
  InlineStack,
  BlockStack,
} from "@shopify/polaris";
import { SearchIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";

export interface MultiComboOption {
  label: string;
  value: string;
}

interface MultiSelectComboboxProps {
  /** All available options for the user to pick from */
  options: MultiComboOption[];

  /** Currently selected values (array of strings) */
  selected: string[];

  /** Called when the user toggles an option or removes a tag */
  onChange: (newSelected: string[]) => void;

  /** Localized label for the TextField (defaults to 'Search tags') */
  label?: string;

  /** Localized placeholder text for the TextField (defaults to 'Search tags') */
  placeholder?: string;
}

export const MultiSelectCombobox: React.FC<MultiSelectComboboxProps> = ({
  options,
  selected,
  onChange,
  label,
  placeholder,
}) => {
  /**
   * 1) Use the i18n hook
   */
  const { t } = useTranslation();

  /**
   * 2) Provide default localizable text if none is passed via props
   */
  const computedLabel = label ?? t("MultiSelectCombobox.label", "Search tags");
  const computedPlaceholder =
    placeholder ?? t("MultiSelectCombobox.placeholder", "Search tags");
  const noResultsText = t("MultiSelectCombobox.noResults", "No results");

  /**
   * 3) The rest of your existing multi-select logic
   */
  const [inputValue, setInputValue] = useState("");

  const deselectedOptions = useMemo(() => options, [options]);
  const [filteredOptions, setFilteredOptions] = useState(deselectedOptions);

  const escapeRegex = useCallback(
    (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
    []
  );

  const updateText = useCallback(
    (value: string) => {
      setInputValue(value);

      if (!value) {
        setFilteredOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(escapeRegex(value), "i");
      const resultOptions = deselectedOptions.filter((option) =>
        option.label.match(filterRegex)
      );
      setFilteredOptions(resultOptions);
    },
    [deselectedOptions, escapeRegex]
  );

  const updateSelection = useCallback(
    (itemValue: string) => {
      let newSelected: string[];
      if (selected.includes(itemValue)) {
        // If it's already selected, remove it
        newSelected = selected.filter((option) => option !== itemValue);
      } else {
        // Otherwise, add it
        newSelected = [...selected, itemValue];
      }
      onChange(newSelected);
      // Clear input, reset filtered list
      setInputValue("");
      setFilteredOptions(deselectedOptions);
    },
    [selected, onChange, deselectedOptions]
  );

  const removeTag = useCallback(
    (tagValue: string) => {
      const newSelected = selected.filter((option) => option !== tagValue);
      onChange(newSelected);
    },
    [selected, onChange]
  );

  /**
   * Generate Tags using the 'label' from your options array.
   */
  const tagsMarkup = selected.map((value) => {
    // Find the matching label in your original `options`
    const foundOption = options.find((opt) => opt.value === value);
    const tagLabel = foundOption?.label ?? value; // fallback to `value` if not found

    return (
      <Tag key={value} onRemove={() => removeTag(value)}>
        {tagLabel}
      </Tag>
    );
  });

  /**
   * Generate Polaris <Listbox.Option> elements for each filtered option
   */
  const optionsMarkup =
    filteredOptions.length > 0 ? (
      filteredOptions.map((option) => (
        <Listbox.Option
          key={option.value}
          value={option.value}
          selected={selected.includes(option.value)}
          accessibilityLabel={option.label}
        >
          {option.label}
        </Listbox.Option>
      ))
    ) : (
      <Listbox.Option value="no-results" disabled>
        {noResultsText}
      </Listbox.Option>
    );

  return (
    <BlockStack gap="200">
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            prefix={<Icon source={SearchIcon} />}
            onChange={updateText}
            label={computedLabel}
            labelHidden
            value={inputValue}
            placeholder={computedPlaceholder}
            autoComplete="off"
          />
        }
      >
        <Listbox onSelect={updateSelection}>{optionsMarkup}</Listbox>
      </Combobox>

      <InlineStack gap="200">{tagsMarkup}</InlineStack>
    </BlockStack>
  );
};
