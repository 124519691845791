import AppPageWrapper from "@/common/AppPageWrapper";
import PerksSkeleton from "./PerksSkeleton";
import styled from "styled-components";
import TranslateLink from "@/common/TranslateLink";
import usePerks from "@/hooks/usePerks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Badge,
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  InlineStack,
  Loading,
  Page,
  Text,
  Popover,
  OptionList,
} from "@shopify/polaris";

const PerksGrid = styled.div`
  display: grid;
  gap: 16px;

  /* Mobile view: 1 column */
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    /* Tablet view: 2 columns */
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1024px) {
    /* Desktop view: 3 columns */
    grid-template-columns: 1fr;
  }
`;

const Perks = () => {
  const { t } = useTranslation();
  const { perks, categories, companies, isLoading, isFetching } = usePerks();
  const [selectedCategory, setSelectedCategory] = useState<string[]>([""]);
  const [selectedCompany, setSelectedCompany] = useState<string[]>([""]);
  const [filteredPerks, setFilteredPerks] = useState(perks);
  const [open, setOpen] = useState<string | null>(null);
  const [popoverActiveCategory, setPopoverActiveCategory] = useState(false);
  const [popoverActiveCompany, setPopoverActiveCompany] = useState(false);

  useEffect(() => {
    let perksFiltered = perks;

    if (selectedCategory.length > 0 && selectedCategory[0] !== "") {
      perksFiltered = perksFiltered.filter(
        (perk) => perk.categoryId === selectedCategory[0]
      );
    }

    if (selectedCompany.length > 0 && selectedCompany[0] !== "") {
      perksFiltered = perksFiltered.filter(
        (perk) => perk.companyId === selectedCompany[0]
      );
    }

    setFilteredPerks(perksFiltered);
  }, [perks, selectedCategory, selectedCompany]);

  const handleCategoryChange = (selected: string[]) => {
    setSelectedCategory(selected);
    setPopoverActiveCategory(false);
  };

  const handleCompanyChange = (selected: string[]) => {
    setSelectedCompany(selected);
    setPopoverActiveCompany(false);
  };

  const toggleCollapsible = (perkId: string) => {
    setOpen((prevOpen) => (prevOpen === perkId ? null : perkId));
  };

  const togglePopoverActiveCategory = useCallback(
    () => setPopoverActiveCategory((popoverActive) => !popoverActive),
    []
  );

  const togglePopoverActiveCompany = useCallback(
    () => setPopoverActiveCompany((popoverActive) => !popoverActive),
    []
  );

  const getCategoryTitle = (categoryId: string) => {
    const category = categories.find((category) => category.id === categoryId);
    return category ? category.title : "";
  };

  const getCompanyLogo = (companyId: string) => {
    const company = companies.find((company) => company.id === companyId);
    return company ? company.logoSquare : "";
  };

  return (
    <AppPageWrapper>
      {isLoading ? (
        <PerksSkeleton />
      ) : (
        <Page title={t("perks.title")} subtitle={t("perks.description")}>
          <BlockStack gap={"400"}>
            <InlineStack gap={"200"}>
              <Popover
                active={popoverActiveCategory}
                activator={
                  <Button onClick={togglePopoverActiveCategory} disclosure>
                    {t("perks.categories")}
                  </Button>
                }
                onClose={togglePopoverActiveCategory}
              >
                <OptionList
                  onChange={handleCategoryChange}
                  options={[
                    { value: "", label: t("perks.all_categories") },
                    ...categories.map((category) => ({
                      value: category.id,
                      label: category.title,
                      active: selectedCategory.includes(category.id),
                    })),
                  ]}
                  selected={selectedCategory}
                />
              </Popover>

              <Popover
                active={popoverActiveCompany}
                activator={
                  <Button onClick={togglePopoverActiveCompany} disclosure>
                    {t("perks.companies")}
                  </Button>
                }
                onClose={togglePopoverActiveCompany}
              >
                <OptionList
                  onChange={handleCompanyChange}
                  options={[
                    { value: "", label: t("perks.all_companies") },
                    ...companies.map((company) => ({
                      value: company.id,
                      label: company.name,
                      active: selectedCompany.includes(company.id),
                    })),
                  ]}
                  selected={selectedCompany}
                />
              </Popover>
            </InlineStack>

            <PerksGrid>
              {filteredPerks?.map((perk) => (
                <Card
                  roundedAbove="sm"
                  key={perk.link}
                  background={
                    perk.featured ? "bg-surface-info-active" : undefined
                  }
                >
                  <InlineStack
                    align="space-between"
                    blockAlign="start"
                    wrap={false}
                  >
                    <InlineStack gap={"400"} wrap={false}>
                      <img
                        alt="logo"
                        src={getCompanyLogo(perk.companyId)}
                        width="40px"
                        height="40px"
                        style={{ borderRadius: "7px" }}
                      />
                      <BlockStack gap="100">
                        <InlineStack gap="200">
                          {perk.showAsNew && <Badge tone="success">New</Badge>}
                          <Text
                            variant="bodyMd"
                            as="span"
                            fontWeight="semibold"
                          >
                            {perk.title}
                          </Text>
                        </InlineStack>
                        <Text variant="bodyMd" as="span" tone="subdued">
                          {getCategoryTitle(perk.categoryId)}
                        </Text>
                      </BlockStack>
                    </InlineStack>
                  </InlineStack>
                  <Box paddingBlockEnd="400" paddingBlockStart="500">
                    <TranslateLink text={perk.description}></TranslateLink>
                  </Box>
                  {perk.howTo && (
                    <Button
                      variant="plain"
                      disclosure={open === perk.link ? "up" : "down"}
                      onClick={() => toggleCollapsible(perk.link)}
                    >
                      {t("perks.how_to_use")}
                    </Button>
                  )}
                  <Box paddingBlockStart="200">
                    <Collapsible
                      open={open === perk.link}
                      id={`collapsible-${perk.link}`}
                      transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                      }}
                      expandOnPrint
                    >
                      <TranslateLink text={perk.howTo}></TranslateLink>

                      <Box paddingBlockStart={"400"} width="100%">
                        <InlineStack align="end">
                          <Button
                            external
                            variant="primary"
                            target="_blank"
                            url={perk.link}
                          >
                            {t("perks.action")}
                          </Button>
                        </InlineStack>
                      </Box>
                    </Collapsible>
                  </Box>
                </Card>
              ))}
            </PerksGrid>
          </BlockStack>
          <br />
        </Page>
      )}
      {isFetching && <Loading />}
    </AppPageWrapper>
  );
};

export default Perks;
