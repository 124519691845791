import { PageTypes } from "@/types/commonTypes";
import { ROOTS } from "@/utils/Constants";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const usePageType = (): PageTypes => {
  const location = useLocation();

  return useMemo(() => {
    if (location.pathname.startsWith(ROOTS.CLINIC)) {
      return PageTypes.CLINIC;
    } else if (location.pathname.startsWith(ROOTS.PATIENT)) {
      return PageTypes.PATIENT;
    } else if (location.pathname.startsWith(ROOTS.USER)) {
      return PageTypes.USER;
    } else {
      return PageTypes.UNKNOW;
    }
  }, [location.pathname]);
};

export default usePageType;
