import React, { useCallback, useState } from "react";
import { clearTokenData } from "@/auth/utils";
import { OrganizationIcon } from "@shopify/polaris-icons";
import { ROOTS } from "@/utils/Constants";
import { Avatar, TopBar } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { User } from "@/types/user";
import { useTranslation } from "react-i18next";
import { Clinic } from "@/types/clinic";
import { getLocalStorage, setLocalStorage } from "@/utils/localStorageUtils";

type UserTopBarProps = {
  onNavigationToggle: () => void;
};
const UserTopBar: React.FC<UserTopBarProps> = ({ onNavigationToggle }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<User>(["user"]);
  const [userMenuActive, setUserMenuActive] = useState(false);
  const clinics =
    queryClient.getQueryData<Clinic[]>(["healthcare-companies"]) || [];

  const toggleUserMenuActive = useCallback(() => {
    setUserMenuActive((userMenuActive) => !userMenuActive);
  }, []);

  const handleLogout = () => {
    clearTokenData();
  };
  const lastVisitedOrgId = getLocalStorage("__LAST_VISITED_C_ID");
  // Sort clinics alphabetically by name
  const sortedClinics = [...clinics].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Separate the last visited organization and the remaining sorted clinics
  const lastVisitedOrg = sortedClinics.find(
    (clinic) => clinic.id === lastVisitedOrgId
  );
  const clinicItems = (lastVisitedOrg ? [lastVisitedOrg] : []).concat(
    sortedClinics.filter((clinic) => clinic.id !== lastVisitedOrgId).slice(0, 5)
  );

  // Map the selected clinics into menu items
  const clinicMenuItems = clinicItems.map((c) => ({
    content: c.name,
    icon: () => (
      <Avatar
        initials={c.avatar.initials}
        name={c.name}
        source={c.avatar.image}
      />
    ),
    onAction: () => {
      setLocalStorage("__LAST_VISITED_C_ID", c.id);
      navigate(ROOTS.CLINIC + "/" + c.id);
    },
  }));
  const userMenuActions = [
    {
      items: [
        ...clinicMenuItems,
        {
          content: t("topbar.clinics"),
          onAction: () => navigate(ROOTS.CLINIC),
          icon: OrganizationIcon,
        },
      ],
    },
    {
      items: [
        {
          content: t("common.helpCenter"),
          onAction: () =>
            window.open(
              `https://support.medentic.app/${i18n.language}`,
              "_blank"
            ),
        },
        {
          content: t("common.changelog"),
          onAction: () =>
            window.open("https://www.medentic.app/changelog", "_blank"),
        },
      ],
    },
    {
      items: [{ content: t("common.logout"), onAction: handleLogout }],
    },
  ];

  return (
    <TopBar
      showNavigationToggle
      onNavigationToggle={onNavigationToggle}
      userMenu={
        <TopBar.UserMenu
          actions={userMenuActions}
          name={`${user?.firstName} ${user?.lastName}`}
          detail={user?.email}
          avatar={user?.avatar?.image}
          initials={user?.avatar?.initials}
          open={userMenuActive}
          onToggle={toggleUserMenuActive}
          customWidth="350px"
        />
      }
    />
  );
};

export default UserTopBar;
