import { BlockStack, Filters, InlineStack, Tag } from "@shopify/polaris";
import { useCallback, useState } from "react";

function TagsExample() {
  const [selectedTags, setSelectedTags] = useState([
    "ВИП",
    "ВИП Силна тревожност",
  ]);
  const [queryValue, setQueryValue] = useState("");

  const removeTag = useCallback(
    (tag: string) => () => {
      setSelectedTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag)
      );
    },
    []
  );

  const handleQueryChange = useCallback(
    (newValue: string) => setQueryValue(newValue),
    []
  );
  const handleQueryClear = useCallback(() => setQueryValue(""), []);

  const filteredTags = selectedTags.filter((tag) =>
    tag.toLowerCase().includes(queryValue.toLowerCase())
  );

  const tagMarkup = filteredTags.map((option) => (
    <Tag key={option} onRemove={removeTag(option)}>
      {option}
    </Tag>
  ));

  return (
    <BlockStack gap={"200"}>
      <div className="details-filters-tags">
        <Filters
          queryValue={queryValue}
          filters={[]}
          appliedFilters={[]}
          onQueryChange={handleQueryChange}
          onQueryClear={handleQueryClear}
          onClearAll={() => {}}
        />
      </div>
      <InlineStack gap={"200"}>{tagMarkup}</InlineStack>
    </BlockStack>
  );
}

export default TagsExample;
