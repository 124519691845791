import DownloadRecoveryCodesModal from "./DownloadRecoveryCodesModal";
import React, { useEffect, useState } from "react";
import TranslateLink from "@/common/TranslateLink";
import useApiRequest from "@/hooks/useApiRequest";
import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  BlockStack,
  Box,
  Modal,
  Text,
  TextField,
  InlineError,
  InlineStack,
  Loading,
  Spinner,
  Form,
} from "@shopify/polaris";
// New recovery codes modal

interface TwoStepAuthModalProps {
  open: boolean;
  onClose: () => void;
  onCloseRecoveryModal: () => void;
}

interface TwoFactorResponse {
  qr_svg_code: string;
  str_code: string;
}

const TwoStepAuthModal: React.FC<TwoStepAuthModalProps> = ({
  open,
  onClose,
  onCloseRecoveryModal,
}) => {
  const { t } = useTranslation();
  const { POST } = useApiRequest();
  const [authCode, setAuthCode] = useState("");
  const [qrCodeSvg, setQrCodeSvg] = useState<string | null>(null);
  const [isFetchingQr, setIsFetchingQr] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [showRecoveryModal, setShowRecoveryModal] = useState(false);

  useEffect(() => {
    if (open) {
      setQrCodeSvg("");
      setAuthCode("");
      setError(null);
      fetchQrCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const fetchQrCode = async () => {
    setIsFetchingQr(true);
    try {
      const response = await POST<TwoFactorResponse>(
        "/api/user/me/two-factor/request",
        {}
      );
      setQrCodeSvg(response.qr_svg_code);
      setIsFetchingQr(false);
    } catch (err) {
      setError(t("twoStepAuth.errorFetchingQR"));
      setIsFetchingQr(false);
    }
  };

  const verifyMutation = useMutation({
    mutationFn: () =>
      POST("/api/user/me/two-factor/verify", {
        code: authCode,
      }),
    onSuccess: () => {
      onClose();
      setShowRecoveryModal(true);
    },
    onError: () => {
      setError(t("twoStepAuth.errorVerificationFailed"));
    },
  });

  const handleAuthCodeChange = (value: string) => {
    setAuthCode(value);
    setError(null);
  };

  const handleSubmit = () => {
    if (authCode.length !== 6) {
      setError(t("twoStepAuth.errorInvalidCode"));
      return;
    }

    verifyMutation.mutate();
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        title={t("twoStepAuth.modalTitle")}
        primaryAction={{
          content: t("twoStepAuth.modalConfirm"),
          onAction: handleSubmit,
          loading: verifyMutation.isPending,
        }}
        secondaryActions={[
          {
            content: t("common.cancel"),
            onAction: onClose,
          },
        ]}
      >
        {verifyMutation.isPending && <Loading />}
        <Modal.Section>
          <BlockStack gap="300">
            <TranslateLink text={t("twoStepAuth.modalDescription")} />

            <InlineStack align="space-between" wrap={false}>
              <BlockStack gap={"200"}>
                <Text as="h3" variant="headingSm">
                  {t("twoStepAuth.scanQrCode")}
                </Text>
                <TranslateLink text={t("twoStepAuth.enterCodeDescription")} />
              </BlockStack>
              {isFetchingQr ? (
                <InlineStack align="center">
                  <Box padding="1200">
                    <Spinner size="large" />
                  </Box>
                </InlineStack>
              ) : (
                <Box paddingBlock="200">
                  {qrCodeSvg ? (
                    <div dangerouslySetInnerHTML={{ __html: qrCodeSvg }} />
                  ) : null}
                </Box>
              )}
            </InlineStack>
            <Form onSubmit={handleSubmit}>
              <TextField
                label={t("twoStepAuth.enterAuthCode")}
                value={authCode}
                onChange={handleAuthCodeChange}
                maxLength={6}
                type="text"
                autoComplete="off"
                autoFocus
                placeholder="000000"
              />
            </Form>

            {error && <InlineError message={error} fieldID="auth_code_error" />}
          </BlockStack>
        </Modal.Section>
      </Modal>

      {showRecoveryModal && (
        <DownloadRecoveryCodesModal
          open={showRecoveryModal}
          onClose={() => {
            onCloseRecoveryModal();
            setShowRecoveryModal(false);
          }}
        />
      )}
    </>
  );
};

export default TwoStepAuthModal;
