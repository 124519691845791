import CheckAuthForEmailUpdate from "./CheckAuthForEmailUpdate";
import PasswordProtectedButton from "@/common/PasswordProtectedButton";
import React, { useState } from "react";
import useApiRequest from "@/hooks/useApiRequest";
import { CheckIcon } from "@shopify/polaris-icons";
import { setTobeVerifiedEmail } from "@/auth/authSlice";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import {
  TextField,
  Modal,
  BlockStack,
  InlineStack,
  Icon,
  InlineError,
  Box,
  Text,
} from "@shopify/polaris";

interface EmailFieldProps {
  email: string;
  emailVerified: boolean;
}

const EmailField: React.FC<EmailFieldProps> = ({ email, emailVerified }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { PATCH } = useApiRequest();
  const dispatch = useDispatch();

  const [newEmail, setNewEmail] = useState<string>("");
  const [showEmailChangeModal, setShowEmailChangeModal] =
    useState<boolean>(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState<boolean>(false);

  const updateEmail = useMutation({
    mutationFn: (newEmail: string) =>
      PATCH("/api/user/me/email", { email: newEmail }),
    onSuccess: () => {
      dispatch(setTobeVerifiedEmail(newEmail));
      setShowEmailVerificationModal(true);
      setShowEmailChangeModal(false);
    },
  });

  return (
    <BlockStack gap={"100"}>
      <TextField
        label={t("userProfile.emailAddress")}
        value={email}
        disabled
        autoComplete="email"
        suffix={
          <PasswordProtectedButton
            onVerified={(isVerified) => {
              if (isVerified) {
                setNewEmail("");
                setShowEmailChangeModal(true);
              }
            }}
          />
        }
      />
      <InlineStack gap={"100"} align="start" blockAlign="start">
        {emailVerified ? (
          <>
            <Box>
              <Icon source={CheckIcon} tone="success" />
            </Box>
            <Text as="p" tone="success">
              {t("userProfile.verified")}
            </Text>
          </>
        ) : (
          <InlineError message={t("userProfile.notVerified")} fieldID="email" />
        )}
      </InlineStack>

      {/* Email Change Modal */}
      <Modal
        open={showEmailChangeModal}
        onClose={() => setShowEmailChangeModal(false)}
        title={t("userProfile.changeEmailAddress")}
        primaryAction={{
          content: t("userProfile.changeEmailAddressCTA"),
          loading: updateEmail.isPending,
          onAction: () => {
            updateEmail.mutate(newEmail);
          },
        }}
        secondaryActions={[
          {
            content: t("common.cancel"),
            onAction: () => setShowEmailChangeModal(false),
          },
        ]}
      >
        <Modal.Section>
          <BlockStack gap={"400"}>
            <Text as="p" tone="subdued">
              {t("userProfile.changeEmailAddressDescription")}
            </Text>
            <TextField
              label={t("userProfile.newEmailAddress")}
              value={newEmail}
              onChange={setNewEmail}
              autoComplete="email"
              autoFocus
            />
          </BlockStack>
        </Modal.Section>
      </Modal>

      {showEmailVerificationModal && (
        <CheckAuthForEmailUpdate
          onDone={() => {
            queryClient.invalidateQueries({ queryKey: ["user"] });
            setShowEmailVerificationModal(false);
          }}
          onClose={() => setShowEmailVerificationModal(false)}
        />
      )}
    </BlockStack>
  );
};

export default EmailField;
